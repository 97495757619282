import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux';

import * as Alert from '@common/services/alert';
import { Button } from '@common/components/button';
import Confirm from '@common/components/confirm-button';
import Container from '@common/components/container';
import CourseForm from '../../forms/course';
import Permission from '@common/components/permission';
import { TopNavigationBar } from '@common/components/navigation-bar';
import StatisticsContainer from './statistics';
import CourseSettings from '@modules/learning/components/course-settings';
import CourseContent from '@modules/learning/components/course-content';

import getStatus from '@modules/learning/utils/get-status';
import { useAppSelector } from '@common/hooks/redux/use-app-selector';
import * as coursesSelector from '../../selectors/courses';
import * as userSelector from '@modules/core/selectors/logged-user';
import fetchCourse from '@modules/learning/actions/fetch-course';
import updateCourse from '@modules/learning/actions/update-course';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import { ECourseTypes } from '@modules/learning/definitions';
import { EPermissions } from '@common/definitions';
import Spinner from '@common/components/spinner';

function getBackUrlParam() {
  if (window.URLSearchParams) {
    const params = new URLSearchParams(window?.location?.search);
    return params.get('backUrl');
  }
  return null;
}

const LearningCourseContainer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<{ courseId: string, type: ECourseTypes }>();
  const course = useAppSelector((state) => coursesSelector.item(state, params.courseId));
  const permissions = useAppSelector((state) => userSelector.permissions(state));

  const [loading, setLoading] = useState<boolean>(false);

  const canUpdateCourse = permissions.includes(ECourseTypes.ONBOARDING ?
    EPermissions.ORGANISATION_ONBOARDING_COURSES_UPDATE :
    EPermissions.ORGANISATION_ACADEMY_COURSES_UPDATE);

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true);
        await dispatch(fetchCourse(params.courseId));
      } catch (err: any) {
        if (err.status_code === 404) {
          Alert.warning(t('learning:course_not_found'));
        } else {
          Alert.error(t('learning:course_error_while_fetching'));
        }
        history.push('/admin/learning');
      } finally {
        setLoading(false);
      }
    };
    if (params.courseId !== 'create') load();
  }, [params.courseId, dispatch]);

  const handleStatusChange = async (publish: boolean, archive?: boolean) => {
    if (course.archived || archive) {
      await dispatch(updateCourse(course.id, { archived: archive || false }, true));
      Alert.success(course.archived ? t('learning:course_unarchived') : t('learning:course_archived'));
    } else if (publish) {
      history.push(`/admin/learning/courses/${course.id}/publish/${course.type}`);
    } else {
      await dispatch(updateCourse(course.id, { published: false }));
      Alert.success(t('learning:course_unpublished'));
    }
  };

  return (
    <Container name="Category" className="LearningNewSection">
      <TopNavigationBar
        className="LearningNewSection__TopNavigation"
        breadcrumbs={[
          { name: t('learning:breadcrumb_learning_environment') },
          course?.type === ECourseTypes.ONBOARDING && {
            name: t('learning:breadcrumb_onboarding'), path: '/admin/learning/onboarding',
          },
          course?.type === ECourseTypes.ACADEMY && {
            name: t('learning:breadcrumb_academy'), path: '/admin/learning/academy',
          },
          course && {
            name: params.courseId === 'create' ? t('learning:course_new_course_title') : course?.name,
          },
        ]}
        form={CourseForm}
        formProps={{
          course,
          history,
        }}
        badge={getStatus(course)}
        image={(course?.header_image_url) || null}
        title={params.courseId === 'create' ? t('learning:course_new_course_title') : course?.name}
        description={course?.description}
        tabs={[{
          name: t('learning:course_tabs_content'),
          to: `/admin/learning/courses/${params.courseId}`,
          exact: true,
        }, canUpdateCourse && {
          name: t('forms:tab_settings'),
          to: `/admin/learning/courses/${params.courseId}/settings`,
        }, course?.published && {
          name: t('learning:course_tabs_statistics'),
          to: `/admin/learning/courses/${params.courseId}/statistics`,
        }]}
        action={(
          <div className="Align" style={{ flexShrink: 0 }}>
            <Permission
              name={
                course?.type === ECourseTypes.ONBOARDING ?
                  EPermissions.ORGANISATION_ONBOARDING_COURSES_REMOVE :
                  EPermissions.ORGANISATION_ACADEMY_COURSES_REMOVE
              }
            >
              <Confirm
                title={t('learning:course_confirm_remove_title')}
                description={t('learning:course_confirm_remove_description')}
                onConfirm={() => { }}
              >
                <Button size="large" icon="delete" />
              </Confirm>
            </Permission>
            <Permission
              name={
                course?.type === ECourseTypes.ONBOARDING ?
                  EPermissions.ORGANISATION_ONBOARDING_COURSES_UPDATE :
                  EPermissions.ORGANISATION_ACADEMY_COURSES_UPDATE
              }
            >
              <>
                {!course?.archived && (
                  <Button
                    type="primary"
                    size="large"
                    icon="inventory"
                    onClick={() => handleStatusChange(false, true)}
                  >
                    {t('common:archive')}
                  </Button>
                )}
                <Button
                  type="primary"
                  size="large"
                  onClick={() => handleStatusChange(!course?.published)}
                >
                  {(course?.archived && 'Unarchive') || (course?.published && 'Unpublish') || 'Publish'}
                </Button>
              </>
            </Permission>
            <Button
              size="large"
              onClick={() => {
                const backUrl = getBackUrlParam();
                history.push(backUrl || `/admin/learning/${course ? course.type : ''}`);
              }}
            >
              {t('learning:course_back_button')}
            </Button>
          </div>
        )}
      />
      {params.courseId === 'create' && (
        // @ts-expect-error
        <CourseForm form="create-course" history={history} type={params.type} show />
      )}
      <Container.Content>
        {loading ? (
          <Spinner centered />
        ) : (
          <Switch>
            <Route
              exact
              path="/admin/learning/courses/:courseId"
              render={() => <CourseContent course={course} />}
            />
            <Route
              path="/admin/learning/courses/:courseId/statistics"
              component={StatisticsContainer}
            />
            {canUpdateCourse && course && (
              <Route
                path="/admin/learning/courses/:courseId/settings"
                component={() => <CourseSettings course={course} />}
              />
            )}
          </Switch>
        )}


      </Container.Content>
    </Container>
  );
};

export default pageWrapper(EEventNames.VISITED_COURSE_PAGE)(LearningCourseContainer);
