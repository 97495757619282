import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';
import * as R from 'ramda';

import { ACADEMY_BULK_UPDATE_SCREENS } from '@modules/learning/actions';
import TextInput from '@common/components/form/inputs/text';
import { SelectInput } from '@common/components/form/inputs/select';
import * as Alert from '../../../../common/services/alert';
import { Button } from '../../../../common/components/button';
import Tooltip from '../../../../common/components/tooltip';
import Confirm from '../../../../common/components/confirm-button';
import Icon from '../../../../common/components/icon';
import ModuleStatus from '../../components/status';
import updateModule from '../../actions/update-module';
import removeModule from '../../actions/remove-module';
import validate from '../validators/module';
import * as validation from '../../utils/validation';
import { EModuleTypes } from '../../definitions';

class ModuleForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      removing: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForeignSubmit = this.handleForeignSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    const { module: moduleItem } = this.props;

    if (moduleItem) this.initialize(moduleItem);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!this.props.module && nextProps.module) this.initialize(nextProps.module);
  }

  static props;

  initialize(moduleItem) {
    this.props.initialize(R.pick([
      'name', 'published', 'duration', 'pass_rate', 'max_points', 'show_correct_answers',
    ], moduleItem));

    if (!moduleItem.name) {
      const input = document.getElementsByClassName('Form__control')[0];

      if (input) input.focus();
    }
  }

  handleForeignSubmit() {
    this.props.handleSubmit(this.handleSubmit)();
  }

  handleBack() {
    const { history, course } = this.props;
    history.push(`/admin/learning/courses/${course.id}`);
  }

  async handleSubmit(values, dispatch, _, publishScreens = false) {
    const { module: moduleItem, t, course, reset } = this.props;

    if (!moduleItem) return;

    try {
      if (values.published) {
        await validation.moduleValidator({
          ...moduleItem,
          values,
          publishScreens
        });
      }

      await dispatch(
        updateModule(moduleItem.id, values, course.id, publishScreens)
      );

      if (publishScreens && moduleItem.screens) {
        const screens = moduleItem.screens.map((screen) => {
          return { ...screen, published: true };
        });
        // ACADEMY_BULK_UPDATE_SCREENS does not trigger a recomputation of
        // the selectors in for quiz modules, solved with workaround, see
        // https://flex-appeal.atlassian.net/browse/PD-8611
        dispatch({
          type: ACADEMY_BULK_UPDATE_SCREENS,
          screens,
        });
      }

      return Alert.success(t('learning:form_module_saved'));
    } catch (err) {
      reset();

      // Validation error
      const customError = err.detail || (err.text && t(err.text));
      if (customError) return Alert.warning(customError);

      return Alert.forStatus(err.status_code, {
        warning: t('learning:form_module_warning_saving'),
        error: t('learning:form_module_error_saving'),
      });
    }
  }

  async handleDelete() {
    const {
      dispatch, module: lecture, course, history, t,
    } = this.props;

    if (!lecture) return;

    this.setState({ removing: true });

    await dispatch(removeModule(lecture.id));

    Alert.success(t('learning:form_module_removed'));
    history.replace(`/admin/learning/courses/${course.id}`);
  }

  renderDurationValue({ label }) {
    return (
      <>
        <Icon type="access_time" />
        {label}
      </>
    );
  }

  renderPublishedValue({ label, value }) {
    return (
      <>
        <ModuleStatus small published={!!value} />
        {label}
      </>
    );
  }

  render() {
    const { removing } = this.state;
    const {
      module: moduleItem, formValues, invalid, submitting, handleSubmit, t,
    } = this.props;

    const hasUnpublishedScreens = moduleItem && moduleItem.screens
      && moduleItem.screens.some(R.propEq('published', false));

    const createButton = (shouldSubmit = true) => (
      <Button
        size="large"
        type="primary"
        iconRight="check"
        buttonType={shouldSubmit ? 'submit' : 'button'}
        disabled={invalid}
        isLoading={submitting}
      >
        <Trans i18nKey="learning:form_module_save" />
      </Button>
    );

    return (
      <form className="Form TopNavigationBar__Row" onSubmit={handleSubmit(this.handleSubmit)}>
        <div className="TopNavigationBar__Row__Info TopNavigationBar__Row__Info--form">
          <TextInput
            autoGrow={24}
            name="name"
            placeholder={t('learning:form_module_name_placeholder')}
            maxLength={30}
            data-lpignore="true"
          />
        </div>
        <div className="pull-right Align">
          {moduleItem && moduleItem.type === EModuleTypes.CONTENT && (
            <>
              <Tooltip title={t('learning:form_module_points_tooltip')}>
                <SelectInput
                  className="Select--size-small"
                  name="max_points"
                  clearable={false}
                  options={[
                    { label: t('learning:form_module_points_label', { count: 1 }), value: 1 },
                    { label: t('learning:form_module_points_label', { count: 2 }), value: 2 },
                    { label: t('learning:form_module_points_label', { count: 4 }), value: 4 },
                    { label: t('learning:form_module_points_label', { count: 6 }), value: 6 },
                    { label: t('learning:form_module_points_label', { count: 8 }), value: 8 },
                    { label: t('learning:form_module_points_label', { count: 10 }), value: 10 },
                  ]}
                  searchable={false}
                />
              </Tooltip>
              <Tooltip title={t('learning:form_module_duration_tooltip')}>
                <SelectInput
                  className="Select--size-small"
                  name="duration"
                  clearable={false}
                  options={[
                    { label: t('learning:form_module_duration_label', { count: 1 }), value: 1 },
                    { label: t('learning:form_module_duration_label', { count: 2 }), value: 2 },
                    { label: t('learning:form_module_duration_label', { count: 4 }), value: 4 },
                    { label: t('learning:form_module_duration_label', { count: 6 }), value: 6 },
                    { label: t('learning:form_module_duration_label', { count: 8 }), value: 8 },
                    { label: t('learning:form_module_duration_label', { count: 10 }), value: 10 },
                    { label: t('learning:form_module_duration_label', { count: 15 }), value: 15 },
                    { label: t('learning:form_module_duration_label', { count: 20 }), value: 20 },
                    { label: t('learning:form_module_duration_label', { count: 25 }), value: 25 },
                    { label: t('learning:form_module_duration_label', { count: 30 }), value: 30 },
                  ]}
                  valueRenderer={this.renderDurationValue}
                  searchable={false}
                />
              </Tooltip>
            </>
          )}
          {moduleItem && moduleItem.type === EModuleTypes.QUIZ && (
            <>
              <Tooltip title={t('learning:form_module_percentage_tooltip')}>
                <SelectInput
                  className="Select--size-small"
                  name="pass_rate"
                  clearable={false}
                  options={[
                    { label: '100%', value: 1 },
                    { label: '90%', value: 0.9 },
                    { label: '80%', value: 0.8 },
                    { label: '70%', value: 0.7 },
                    { label: '60%', value: 0.6 },
                    { label: '50%', value: 0.5 },
                    { label: '40%', value: 0.4 },
                    { label: '30%', value: 0.3 },
                    { label: '20%', value: 0.2 },
                    { label: '10%', value: 0.1 },
                    { label: '0%', value: 0 },
                  ]}
                  searchable={false}
                />
              </Tooltip>
              <Tooltip title={t('learning:form_module_show_correct_answers_tooltip')}>
                <SelectInput
                  className="Select--size-small"
                  name="show_correct_answers"
                  clearable={false}
                  options={[
                    { label: t('learning:form_module_show_correct_answers_true'), value: true },
                    { label: t('learning:form_module_show_correct_answers_false'), value: false },
                  ]}
                  searchable={false}
                />
              </Tooltip>
            </>
          )}
          <SelectInput
            name="published"
            className="StatusSelector"
            clearable={false}
            searchable={false}
            options={[
              { label: t('common:status_live'), value: true },
              { label: t('common:status_draft'), value: false },
            ]}
            valueRenderer={this.renderPublishedValue}
          />
          <Confirm
            title={t('learning:form_module_confirm_remove_title')}
            description={(
              <Trans
                i18nKey="learning:form_module_confirm_remove_description"
                tOptions={{ context: moduleItem && moduleItem.type }}
                components={[<b>a</b>]}
              />
            )}
            onConfirm={this.handleDelete}
          >
            <Button size="large" icon="delete" isLoading={removing} />
          </Confirm>
          <Button size="large" onClick={this.handleBack}><Trans i18nKey="learning:form_module_back" /></Button>
          {moduleItem && formValues && moduleItem.published === false && formValues.published ? (
            <Confirm
              title={t('learning:form_module_confirm_publish_title')}
              description={t('learning:form_module_confirm_publish_description')}
              check={
                hasUnpublishedScreens ? t('learning:form_module_confirm_publish_check') : undefined
              }
              onConfirm={(publishScreens) => {
                return handleSubmit((values, dispatch, props) => {
                  return this.handleSubmit(values, dispatch, props, publishScreens);
                })();
              }}
            >
              {createButton(false)}
            </Confirm>
          ) : createButton()}
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  formValues: (state.form.module || { values: {} }).values,
});

export default withTranslation()(connect(mapStateToProps, null, null)(reduxForm({
  form: 'module',
  initialValues: {
    name: '',
    published: false,
    show_correct_answers: false,
  },
  validate,
})(ModuleForm)));
