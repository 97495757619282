import React from 'react';
import * as R from 'ramda';
import Icon, { iconTypes } from '../icon';
import Spinner from '../spinner';
import { combineClassNames } from '@utils/combineClassNames';

import type { IconTypes } from '../icon';

export type ButtonType =
  | 'default'
  | 'primary'
  | 'soft'
  | 'success'
  | 'warning'
  | 'white'
  | 'orange'
  | 'purple'
  | 'blue'
  | 'grey'
  | 'dark'
  | 'transparent'
  | 'inverted-primary'
  | 'inverted'
  | 'premium'
  | 'toolbar';

type Props = Omit<JSX.IntrinsicElements['button'], 'type'> & {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void; // Optional to fix error when onClick is added by wrapper (ConfirmButton for example)
  type?: ButtonType;
  size?: 'small' | 'default' | 'large' | 'fill';
  buttonType?: 'button' | 'submit';
  children?: React.ReactNode;
  icon?: IconTypes;
  iconRight?: IconTypes;
  className?: string;
  disabled?: boolean;
  active?: boolean;
  center?: boolean;
  isLoading?: boolean;
  noFilledIcon?: boolean;
};

const createClassName = ({
  type = 'default',
  size = 'default',
  className,
  active,
  center,
  icon,
  iconRight,
  disabled,
  isLoading,
  children,
}: Props) => combineClassNames('Button', `Button--${type}`, `Button--size-${size}`, className, {
  'Button--center': center,
  'Button--active': active,
  'Button--disabled': disabled || isLoading,
  'Button--icon': !!(icon || iconRight) && !!children,
  'Button--only-icon': !!(icon || iconRight) && !children && type !== 'toolbar',
});

export const Button = (props: Props) => {
  const {
    children,
    onClick,
    disabled,
    isLoading,
    icon,
    noFilledIcon = false,
    iconRight,
    buttonType = 'button',
    ...otherProps
  } = props;
  const fillIcon = (i?: IconTypes): IconTypes => {
    if (i?.includes('__filled')) return i;

    // If icon isn't filled then replace by filled icon
    const filledName = `${i}__filled`;
    const hasFilledState = iconTypes.includes(filledName);

    return (hasFilledState ? filledName : i) as IconTypes;
  };

  return (
    <button
      {...R.omit(['active', 'center'], otherProps)}
      type={disabled ? 'button' : buttonType}
      className={createClassName(props)}
      onClick={!disabled && !isLoading ? onClick : undefined}
      disabled={disabled}
    >
      {isLoading ? (
        <Spinner centered />
      ) : (
        <>
          {icon && (
            <Icon
              type={noFilledIcon ? icon : fillIcon(icon)}
              className="Button__Icon"
            />
          )}
          {children && <div>{children}</div>}
          {iconRight && (
            <Icon
              type={noFilledIcon ? iconRight : fillIcon(iconRight)}
              className="Button__Icon"
            />
          )}
        </>
      )}
    </button>
  );
};
