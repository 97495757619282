import React, { memo, useCallback, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import ToggleContent from '@common/components/toggle-content';
import Icon from '@common/components/icon';
import Dropdown from '@common/components/dropdown';
import ConfirmButton from '@common/components/confirm-button';
import LearningPathStatusSelector from './learning-path-status-selector';
import Api from '@common/services/api';
import { AlertService } from '@common/services/alert';
import { ApiLearningPathListItem } from '@modules/learning/types/learning-paths';
import './learning-path-list-item.scss';

type Source = {
  id: string;
};

const LearningPathListItem = memo(({
  item,
  onChangeOrder,
  onDrop,
  orgId,
  resetOrder,
  setLearningPaths
}: any) => {
  const { t } = useTranslation();

  const [deleting, setDeleting] = useState<boolean>(false);

  const [, dropRef] = useDrop({
    accept: ['learning-path'],
    hover: (source: Source) => {
      if (source.id === item.id) return;

      onChangeOrder(source.id, item.id);
    },
    canDrop() {
      return !deleting;
    }
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'learning-path',
    item: () => ({ id: item.id }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end(_, monitor) {
      const dropped = monitor.didDrop();
      if (dropped) {
        return onDrop();
      }
      resetOrder();
    },
    canDrag() {
      return !deleting;
    }
  });

  const { url } = useRouteMatch();

  const pathId = item.id;

  const onDelete = useCallback(async () => {
    try {
      setDeleting(true);
      await Api.delete(`/v2/organisations/${orgId}/learning-paths/${pathId}`);
      setLearningPaths((prevLearningPaths: ApiLearningPathListItem[]) => {
        return prevLearningPaths.filter((path) => path.id !== pathId);
      });
    } catch (error) {
      AlertService.error(t('common:something_went_wrong'));
      throw error;
    } finally {
      setDeleting(false);
    }
  }, [orgId, pathId, setLearningPaths, setDeleting, t]);

  const opacity = (() => {
    if (deleting) return 0.5;
    if (isDragging) return 0;
    return 1;
  })();

  const onStatusChange = useCallback((status: 'live' | 'draft') => {
    setLearningPaths((prevLearningPaths: ApiLearningPathListItem[]) => {
      return prevLearningPaths.map((learningPath) => {
        if (learningPath.id === pathId) {
          return { ...learningPath, status };
        }
        return learningPath;
      });
    });
  }, [setLearningPaths, pathId]);

  return (
    <div
      className="LearningPathListItem"
      ref={dropRef}
      style={{ opacity }}
    >
      <ToggleContent
        disabled
        title={(
          <Link to={`${url}/${item.id}`}>{item.name}</Link>
        )}
        handleRef={dragRef}
        previewRef={previewRef}
        className="OnboardingToggle"
        action={(
          <>
            <Icon type="folder" size={20} />
            <span className="coursesCount">
              { item.courses_count }{' '}
              {
                t(
                  item.courses_count === 1 ? 'learning:course' : 'learning:courses'
                ).toLowerCase()
              }
            </span>
            <LearningPathStatusSelector
              orgId={orgId}
              pathId={pathId}
              onChange={onStatusChange}
              value={item.status}
            />
            <Dropdown
              alignRight
              toggle={<Icon type="more_vert" />}
            >
              <ConfirmButton
                danger
                title={t('learning:delete_learning_path_confirm')}
                onConfirm={onDelete}
                confirmText={t('common:delete')}
              >
                <Dropdown.Item danger>
                  { t('common:delete') }
                </Dropdown.Item>
              </ConfirmButton>
            </Dropdown>
          </>
        )}
      />
    </div>
  );
});

export default LearningPathListItem;
