import React, { memo, useCallback } from 'react';
import { Action, Location } from 'history';
import { useRouteMatch } from 'react-router';
import PageLeaveCheckPopup from '@common/components/confirm-button/page-leave-check-popup';

import { Actions } from '@common/utils/history';

type EditorPageLeavePopupProps = {
  message: string;
};

const EditorPageLeavePopup = memo(({ message }: EditorPageLeavePopupProps) => {
  const { url } = useRouteMatch();
  const shouldRunOnRoute = useCallback((newLocation: Location<unknown>, action: Action) => {
    const baseUrl = url.substring(0, url.lastIndexOf('/'));
    // if the navigation is about going back and forth in the editor steps
    // let's ask if the user is sure about leaving the editor
    // POP is back button action, it's impossible to override that action as
    // it immediately updates the url the URL, see
    // https://linear.app/oneteam/issue/ONE-493/as-a-user-i-want-close-the-survey-that-im-taking#comment-e65b117d
    return !newLocation.pathname.startsWith(baseUrl) && action !== Actions.POP;
  }, [url]);
  return (
    <PageLeaveCheckPopup message={message} shouldRunOnRoute={shouldRunOnRoute} />
  );
});

export default EditorPageLeavePopup;
