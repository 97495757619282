import React, {
  useRef, useEffect, useCallback, useState, memo
} from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider, useDrag, useDrop } from 'react-dnd';

import { Modal } from '@common/components/modal';
import { Button } from '@common/components/button';
import { Icon } from '@common/components/icon';

import createTempId from '@common/utils/create-temp-id';
import { Api } from '@common/services/api';
import { AlertService } from '@common/services/alert';
import { useDispatch } from 'react-redux';
import { formatFormWithScreens } from '@modules/forms/actions';
import { ColorInput } from '@common/components/form/inputs/color';
import dndManager from '@common/utils/dnd-manager';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { EPlanPackageConfig } from '@common/definitions';

const dragType = 'status';

const StatusRow = memo(({
  id, text, index, color, canAddOrChangeStatus,
  moveCard, showUpgradeModal, setCards,
}: any) => {
  const { t } = useTranslation();

  const ref = useRef<any>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: dragType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor: any) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: dragType,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const update = useCallback((key: 'color' | 'name', value: string) => {
    if (canAddOrChangeStatus) {
      setCards((prevCards: any) => {
        const newCards = prevCards.map((card: any) => {
          if (card.id === id) {
            return { ...card, [key]: value };
          }
          return card;
        });
        return newCards;
      });
    } else {
      showUpgradeModal();
    }
  }, [id, canAddOrChangeStatus, showUpgradeModal, setCards]);

  return (
    <div
      ref={ref}
      className="EditForm__Status"
      style={{ opacity }}
      data-handler-id={handlerId}
      onDragStart={(event: any) => {
        const { target } = event;
        const pickerOpen = target.querySelector('.ColorInput__Picker__Inner');
        if (pickerOpen) {
          event.preventDefault();
        }
      }}
    >
      <div>
        <Icon type="drag_handle" className="EditForm__StatusDragHandle" />
      </div>
      <ColorInput
        hideValue
        input={{
          value: color,
          onChange: (newColor: string) => update('color', newColor),
        }}
      />
      <input
        className="Form__control"
        placeholder={t('forms:form_status_placeholder')}
        onChange={(event: any) => update('name', event.target.value)}
        value={text}
      />
      <Button
        icon="delete"
        size="large"
        onClick={() => {
          setCards((prevCards: any) => {
            return prevCards.filter((card: any) => {
              return card.id !== id;
            });
          });
        }}
      />
    </div>
  );
});

type Props = {
  rows: any[];
  organisationId: string;
  formId: string;
};

function Content({
  cards, setCards, rows, canAddOrChangeStatus, showUpgradeModal,
}: any) {
  const { t } = useTranslation();

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: any) => {
      const newCards = [...prevCards];
      newCards[hoverIndex] = prevCards[dragIndex];
      newCards[dragIndex] = prevCards[hoverIndex];
      return newCards;
    });
  }, [setCards]);
  const renderCard = useCallback((card: any, index: number) => {
    return (
      <StatusRow
        text={card.name}
        color={card.color}
        handlerId={card.id}
        id={card.id}
        key={card.id}
        setCards={setCards}
        index={index}
        canAddOrChangeStatus={canAddOrChangeStatus}
        showUpgradeModal={showUpgradeModal}
        moveCard={moveCard}
      />
    );
  }, [moveCard]);

  // workaround to implement "cancel hit" behaior, when unmounting we always
  // override the rows with the initial values, so if the user opens the modal
  // again he will see the values without the previous ephemeral updates.
  // If the user instead saves, we are listening for new rows in the parent
  // component, dispatching to redux (when saving) will provide new rows
  // and the local state will be updated correctly. Clunky. We need a new
  // modal component that does not force us to this kind of creativity.
  useEffect(() => {
    return () => {
      setCards(rows);
    };
  }, [setCards, rows]);

  return (
    <div>
      <div className="EditForm__Statuses">
        <DndProvider manager={dndManager}>
          {
            cards.map((card: any, i: number) => renderCard(card, i))
          }
        </DndProvider>
      </div>
      <Button
        iconRight="add"
        onClick={() => {
          if (canAddOrChangeStatus) {
            setCards([
              ...cards,
              { id: createTempId(), name: '', color: '#FFFFFF' }
            ]);
          } else {
            showUpgradeModal();
          }
        }}
      >
        {t('forms:add_status')}
      </Button>
    </div>
  );
}

export const StatusesModal = ({ rows, formId, organisationId }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [cards, setCards] = useState<any>(rows);
  const {
    isAvailable: canAddOrChangeStatus, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.FORMS_SUBMISSION_STATUS);

  useEffect(() => setCards(rows), [rows]);

  // for when we implement PD-8525
  // const initCards = rows?.length > 0 ?
  //   rows :
  //   [{
  //     id: 'new-1',
  //     name: t('forms:default_status_pending'),
  //     color: '#FF9500',
  //   }, {
  //     id: 'new-2',
  //     name: t('forms:default_status_processed'),
  //     color: '#34D399',
  //   }, {
  //     id: 'new-3',
  //     name: t('forms:default_status_rejected'),
  //     color: '#FF3B30',
  //   }];

  const footer = useCallback((handleHide: any) => {
    const onClick = async (...args: any[]) => {
      setLoading(true);
      try {
        const payload = {
          response_statuses: cards
        };
        const url = `/v1/organisations/${organisationId}/forms/${formId}`;
        const { data } = await Api.put(url, payload);
        dispatch({
          type: 'forms/RECEIVE_FORM',
          item: formatFormWithScreens(data)
        });
        AlertService.success(t('core:changes_saved'));
        handleHide(...args);
      } catch (error) {
        AlertService.error(t('core:changes_could_not_be_saved'));
        throw error;
      }
      setLoading(false);
    };
    return (
      <Button type="primary" onClick={onClick} isLoading={loading}>
        {t('forms:save_and_close')}
      </Button>
    );
  }, [t, cards, formId, organisationId, dispatch, setLoading, loading]);

  return (
    <Modal
      list
      title={t('forms:edit_statuses')}
      content={(
        <Content
          cards={cards}
          rows={rows}
          setCards={setCards}
          canAddOrChangeStatus={canAddOrChangeStatus}
          showUpgradeModal={showUpgradeModal}
        />
      )}
      footer={footer}
    >
      <Button icon="edit__filled" isLoading={loading}>
        {t('forms:edit_statuses')}
      </Button>
    </Modal>
  );
};
