import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

const Rows = ({
  items, render: Component, itemsPerRow, rowProps, rowClassName, placeholder,
}) => {
  if (items.length === 0 && placeholder) return placeholder;

  const rows = R.pipe(
    R.addIndex(R.map)((item, i) => {
      const props = typeof rowProps === 'function' ? rowProps(item) : rowProps;
      return <Component key={item.id} index={i} item={item} {...props} />;
    }),
    R.splitEvery(itemsPerRow),
  )(items);

  const elements = R.addIndex(R.map)((row, i) => (
    <div key={i} className={rowClassName}>{row}</div>
  ), rows);

  return <div className="Rows">{elements}</div>;
};

Rows.propTypes = {
  items: PropTypes.array.isRequired,
  itemsPerRow: PropTypes.number.isRequired,
  render: PropTypes.func.isRequired,
  rowClassName: PropTypes.string,
  rowProps: PropTypes.any,
};

Rows.defaultProps = {
  rowProps: {},
};

export default Rows;
