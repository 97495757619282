import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { Trans, useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import * as Alert from '@common/services/alert';
import Bar from '@common/components/bar';
import { Button } from '@common/components/button';
import Placeholder from '@common/components/placeholder';
import AddModuleForm from '../../forms/add-module';
import Section from '../section';
import ScrollArea from './scroll-area';
import Confirm from '@common/components/confirm-button';

import dndManager from '@common/utils/dnd-manager';
import { Api } from '@common/services/api';
import { useAppSelector } from '@common/hooks';
import createTempId from '@common/utils/create-temp-id';
import { CourseWithSections, Screen, Section as SectionType } from '@modules/learning/types/objects';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';

import updateCourse from '@modules/learning/actions/update-course';
import updateModule from '@modules/learning/actions/update-module';
import removeModule from '@modules/learning/actions/remove-module';
import { duplicateModule } from '@modules/learning/actions';

type CourseContentProps = {
  course: CourseWithSections;
};

type OrderType = Omit<SectionType, 'modules'>;

const updateOrder = (id: string, index: number, order: string[]) => {
  return R.insert(index, id, R.reject(R.equals(id), order));
};

const getOrder = (sections: SectionType[]) => {
  return sections.map(R.omit(['modules']));
};

const CourseContent = ({ course }: CourseContentProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const organisationId = useAppSelector(getCurrentOrgId);
  const [order, setOrder] = useState<OrderType[]>([]);
  const [isCreatingSection, setIsCreatingSection] = useState(false);
  const [moduleCascadePublishId, setModuleCascadePublishId] = useState<string | null>(null);

  useEffect(() => {
    if (course && order.length === 0) setOrder(getOrder(course.sections));
  }, [course, order, setOrder]);

  const lookUp = {
    sections: R.indexBy(R.prop('id'), R.map(R.omit(['modules']), course ? order : [])),
    modules: R.indexBy(R.prop('id'), R.flatten(R.pluck('modules', course ? course.sections : []))),
  };

  const items = order.map((values) => {
    const { module_ids: ids } = values;
    const section = lookUp.sections[values.id] || values;
    return R.assoc(
      'modules',
      ids.map((moduleId) => {
        return R.assoc('section_id', values.id, lookUp.modules[moduleId]);
      }),
      section
    );
  });

  const handleOpenModule = (id: string) => {
    history.push(`/admin/learning/courses/${course.id}/modules/${id}`);
  };

  if (items.length === 0) {
    return (
      <Placeholder
        image="/static/images/modules-placeholder.svg"
        title={t('learning:course_placeholder_title')}
        description={t('learning:course_placeholder_description')}
        action={(
          <AddModuleForm
            course={course}
            onOpenCourse={handleOpenModule}
          >
            <Button type="primary" iconRight="add">
              {t('learning:course_new_module')}
            </Button>
          </AddModuleForm>
        )}
      />
    );
  }

  const save = async (newOrderToSave: OrderType[] | null = null) => {
    const newOrder = (newOrderToSave || order).map((section, index) => ({
      id: section.local || section.transparent ? undefined : section.id,
      index,
      name: section.name || null,
      transparent: section.transparent || false,
      modules: section.module_ids,
    }));
    const { item } = await dispatch(updateCourse(course.id, {
      sections: newOrder,
    })) as unknown as { item: CourseWithSections };
    setOrder(getOrder(item.sections));
  };

  const saveOrder = async (newOrderToSave: OrderType[] | null = null) => {
    try {
      await save(newOrderToSave);
      Alert.success(t('learning:course_changes_saved'));
    } catch (error) {
      Alert.error(t('learning:course_cant_save_changes'));
      throw error;
    }
  };

  const onSectionNameSave = async (section: SectionType, newName: string) => {
    const newOrder = order.map((orderSection) => {
      return {
        ...orderSection,
        name: orderSection.id === section.id ? newName : orderSection.name
      };
    });
    try {
      await save(newOrder);
      Alert.success(t('learning:course_changes_saved'));
    } catch (error) {
      Alert.error(t('learning:course_cant_save_changes'));
      throw error;
    }
  };

  const handleMoveModule = (sourceId: string, targetId: string, sectionId: string) => {
    setOrder(order.map((section) => {
      if (section.id !== sectionId) return section;
      // Update order of modules inside section
      return R.assoc('module_ids', updateOrder(sourceId, R.indexOf(targetId, section.module_ids), section.module_ids), section);
    }));
  };

  const handleMoveSection = (sourceId: string, targetId: string) => {
    const currentValue = R.find(R.propEq('id', sourceId), order);
    const cleanOrder = R.reject(R.propEq('id', sourceId), order);
    const newIndex = sourceId ? R.findIndex(R.propEq('id', targetId), cleanOrder) + 1 : 0;
    const newOrder = R.insert(newIndex, currentValue, cleanOrder) as OrderType[];
    saveOrder(newOrder);
  };

  const handleCreateNewSection = (afterSection: string, id: string) => {
    const newIndex = afterSection ? R.findIndex(R.propEq('id', afterSection), order) + 1 : 0;
    const newSection: OrderType = {
      id: id || createTempId(),
      index: newIndex,
      name: null,
      local: true,
      transparent: true,
      module_ids: [],
    };
    setOrder(R.insert(newIndex, newSection, order));
    return newSection;
  };

  const handleMoveModuleToSection = (sourceId: string, targetId: string, currentSectionId: string) => {
    setOrder((prev) => {
      const newOrder = prev.map((section) => {
        if (section.id !== targetId && section.id !== currentSectionId) return section;
        // Remove module from old section
        if (section.id === currentSectionId) {
          return R.assoc('module_ids', R.reject(R.equals(sourceId), section.module_ids), section);
        }
        return R.assoc('module_ids', R.append(sourceId, section.module_ids), section);
      }).filter((section) => (!section.transparent && !section.local) || section.module_ids.length > 0);
      saveOrder(newOrder);
      return newOrder;
    });
  };

  const handleAddNewSection = async () => {
    const section: SectionType = {
      id: createTempId(),
      index: order.length,
      name: t('learning:course_new_section_name'),
      local: true,
      transparent: false,
      module_ids: [],
      modules: [],
    };
    const newOrder = R.append(section, order);

    try {
      setIsCreatingSection(true);
      await save(newOrder);
      Alert.success(t('learning:course_changes_saved'));
    } catch (error) {
      Alert.error(t('learning:course_cant_save_changes'));
      throw error;
    } finally {
      setIsCreatingSection(false);
    }
  };

  const handleRemoveSection = async (id: string) => {
    const section = R.find(R.propEq('id', id), order);
    if (!section) return;

    const newSections: OrderType[] = !section.transparent ? section.module_ids.map((moduleId) => ({
      id: createTempId(),
      index: order.length,
      name: null,
      local: true,
      transparent: true,
      module_ids: [moduleId],
    })) : [];

    const currentIndex = R.findIndex(R.propEq('id', id), order);
    const cleanOrder = R.reject(R.propEq('id', id), order);
    const newOrder = R.insertAll(currentIndex, newSections)(cleanOrder);

    try {
      await save(newOrder);
      Alert.success(t('learning:course_changes_saved'));
    } catch (err) {
      Alert.error(t('learning:course_cant_save_changes'));
      throw err;
    }
  };

  const handleChangeModuleStatus = async (id: string, published: boolean) => {
    if (!course) return;
    if (published) {
      const res = await Api.get<{ data: { screens: Screen[] } }>(`/v2/organisations/${organisationId}/modules/${id}`);
      const unpublishedScreen = res.data.screens.find((scr) => !scr.published);
      if (unpublishedScreen) {
        return setModuleCascadePublishId(id);
      }
    }
    await dispatch(updateModule(id, { published }, course.id));
    Alert.success(published
      ? t('learning:course_has_been_published')
      : t('learning:course_has_been_unpublished'));
  };

  const handleDuplicateModule = async (id: string) => {
    try {
      // TODO: no section data available so can't add to state
      const { section } = await dispatch(duplicateModule(id, course.id)) as unknown as { section: SectionType };
      if (section.transparent) { // in the UI the module is not within a section collapsible
        setOrder(R.append(R.omit(['modules'], {
          ...section,
          module_ids: R.pluck('id', section.modules),
        }), order));
      } else {
        const newOrder = order.map((orderSection) => {
          if (orderSection.id === section.id) {
            return {
              ...section,
              module_ids: section.modules.map((mod) => mod.id),
            };
          }
          return orderSection;
        });
        setOrder(newOrder);
      }
      Alert.success(t('learning:module_duplicated'));
    } catch (err) {
      Alert.warning(t('learning:warning_duplicate_module'));
      throw err;
    }
  };

  const handleRemoveModule = async (id: string, sectionId: string) => {
    try {
      await dispatch(removeModule(id));
      setOrder(order.map((section) => {
        if (section.id !== sectionId) return section;
        return {
          ...section,
          module_ids: R.reject(R.equals(id), section.module_ids),
        };
      }).filter((section) => section.module_ids.length > 0),
      );
      Alert.success(t('learning:module_removed'));
    } catch (err) {
      Alert.warning(t('learning:warning_remove_module'));
      throw err;
    }
  };

  const onCascadeModulePublishConfirm = async (publishScreens: boolean) => {
    if (!course) return;
    try {
      await dispatch(updateModule(moduleCascadePublishId, { published: true }, course.id, publishScreens));
      Alert.success(t('learning:course_has_been_published'));
    } catch (err: any) {
      if (err.status_code === 422) {
        Alert.warning(t('learning:module_active_question_required'));
      } else {
        Alert.error(t('learning:module_update_error'));
      }
      throw err;
    }
    setModuleCascadePublishId(null);
  };

  return (
    <>
      <Bar>
        <h2 className="pull-left">
          <Trans
            i18nKey="learning:course_modules_count"
            values={{ count: Object.keys(lookUp.modules).length }}
          />
        </h2>
        <div className="pull-right">
          <AddModuleForm
            course={course}
            onOpenCourse={handleOpenModule}
          >
            <Button type="primary" iconRight="add" size="large">
              <Trans i18nKey="learning:course_new_module" />
            </Button>
          </AddModuleForm>
        </div>
        <Button
          iconRight="add"
          onClick={handleAddNewSection}
          type="primary"
          size="large"
          disabled={isCreatingSection}
        >
          { t('learning:course_new_section') }
        </Button>
      </Bar>
      <DndProvider manager={dndManager}>
        <ScrollArea position="top" />
        {items.map((section, i) => (
          <Section
            key={section.id}
            item={section}
            previous={items[i - 1]}
            next={items[i + 1]}
            openModule={handleOpenModule}
            moveModule={handleMoveModule}
            onDrop={saveOrder}
            moveModuleToSection={handleMoveModuleToSection}
            moveSection={handleMoveSection}
            changeModuleStatus={handleChangeModuleStatus}
            createNewSection={handleCreateNewSection}
            removeSection={handleRemoveSection}
            removeModule={handleRemoveModule}
            duplicateModule={handleDuplicateModule}
            onNameSave={onSectionNameSave}
          />
        ))}
        <ScrollArea position="bottom" />
      </DndProvider>
      {moduleCascadePublishId && (
        <Confirm
          title={t('learning:form_module_confirm_publish_title')}
          description={t('learning:form_module_confirm_publish_description')}
          check={t('learning:form_module_confirm_publish_check')}
          onConfirm={onCascadeModulePublishConfirm}
          onCancel={() => setModuleCascadePublishId(null)}
        />
      )}
    </>
  );
};

export default CourseContent;
