import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@common/components/button';
import Modal from '@common/components/modal';
import * as Alert from '@common/services/alert';
import Phasing from '@modules/learning/components/course-phasing';

import updateCourse from '../../actions/update-course';
import { Course } from '@modules/learning/types/objects';
import { useFormValues } from '@common/hooks';

type CoursePhasingProps = PropsWithChildren<{
  course: Course;
}>;

type FormData = {
  phased: boolean;
  settings: {
    visible_after_days_invited: number | null;
  };
};

type Payload = {
  visible_after_days_invited: number | null;
};

type Props = InjectedFormProps<FormData, CoursePhasingProps, boolean> & CoursePhasingProps;

const CoursePhasing = ({
  course, children, form, submitting, initialize, reset, handleSubmit,
}: Props) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const formValues = useFormValues<FormData>(form);

  useEffect(() => {
    initialize({
      phased: !!course.settings.visible_after_days_invited && course.settings.visible_after_days_invited > 0,
      settings: {
        visible_after_days_invited: course.settings.visible_after_days_invited,
      },
    });
  }, [isModalVisible]);

  const onSubmit = useCallback(async (values, dispatch) => {
    try {
      const settings: Payload = {
        visible_after_days_invited: values.phased ? values.settings.visible_after_days_invited : null,
      };
      await dispatch(updateCourse(course.id, { settings }));
      Alert.success(t('learning:form_onboarding_saved'));
      setIsModalVisible(false);
    } catch (response: any) {
      return Alert.forStatus(response.status_code, {
        warning: t('learning:form_onboarding_warning_saving'),
        error: t('learning:form_onboarding_error_saving'),
      });
    }
  }, [course, updateCourse, setIsModalVisible, t]);

  return (
    <Modal
      list
      title={t('learning:form_onboarding_phasing_modal_title')}
      show={isModalVisible}
      onShow={() => setIsModalVisible(true)}
      onClose={() => reset()}
      wrapper={Modal.FormWrapper}
      wrapperProps={{
        onSubmit: handleSubmit(onSubmit),
      }}
      content={(
        <Phasing phased={formValues?.phased} />
      )}
      footer={(
        <Button buttonType="submit" type="primary" isLoading={submitting}>
          {t('learning:form_onboarding_save')}
        </Button>
      )}
    >
      {children}
    </Modal>
  );
};

export default reduxForm<FormData, CoursePhasingProps, boolean>({
  initialValues: {
    phased: false,
    settings: {
      visible_after_days_invited: 0,
    },
  },
})(CoursePhasing);
