import * as React from 'react';
import { useMemo } from 'react';
import { Trans } from 'react-i18next';
import Icon from '../../../../common/components/icon';

const ModuleChildCount = ({ module: moduleItem }) => {

  const count = useMemo(() => {
    if (!moduleItem) return 0;
    if (moduleItem.type === 'quiz') {
      return moduleItem.screens.reduce((acc, screen) => {
        return acc + (screen?.question ? 1 : 0);
      }, 0);
    }
    return moduleItem.screen_count;
  }, [moduleItem]);

  switch (moduleItem.type) {
    case 'quiz':
      return (
        <div className="ModuleChildCount">
          <Icon type="help" />
          <Trans i18nKey="learning:module_child_count_quiz" values={{ count }} />
        </div>
      );
    case 'content':
      return (
        <div className="ModuleChildCount">
          <Icon type="filter_none" />
          <Trans i18nKey="learning:module_child_count_content" values={{ count }} />
        </div>
      );
    default:
      return null;
  }
};

export default ModuleChildCount;
