import React from 'react';
import { useTranslation } from 'react-i18next';

import Permission from '@common/components/permission';
import { EPermissions } from '@common/definitions';
import { Button } from '@common/components/button';
import { FolderModalForm } from '../../forms/folder-modal-form';
import { FileUploadForm } from '../../forms/file-upload';

import type { PartialBy } from '@common/types/util-types';
import type { Folder, DocumentsRouteParams } from '../../types';

type HeaderActionButtonsProps = {
  params: PartialBy<DocumentsRouteParams, 'filter' | 'networkId'>;
  personalFolder?: boolean;
  currentFolder?: Folder;
  userId?: string;
};

export const DocumentsHeaderActionButtons = ({
  params,
  personalFolder = false,
  userId,
  currentFolder,
}: HeaderActionButtonsProps) => {
  const { t } = useTranslation();
  return (
    <Permission name={[EPermissions.NETWORK_DOCUMENTS_CREATE, EPermissions.ORGANISATION_DOCUMENTS_CREATE]}>
      <FolderModalForm currentFolder={currentFolder} userId={userId}>
        <Button size="large" iconRight="add">
          {t('documents:create_folder')}
        </Button>
      </FolderModalForm>
      <FileUploadForm
        parentFolderId={params.folderId}
        userId={userId}
        personalFolder={personalFolder || (!!currentFolder?.personal_root_user_id || params.folderId === 'personal')}
      />
    </Permission>
  );
};
