import React from 'react';
import { useTranslation } from 'react-i18next';

import { EPermissions, EStatus } from '@common/definitions';
import Permission from '@common/components/permission';
import { SubNavigationBar } from '@common/components/navigation-bar';

const LearningSideNavigation = () => {
  const { t } = useTranslation();
  return (
    <SubNavigationBar title={t('core:tab_elearning')}>
      <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_VIEW}>
        <SubNavigationBar.Subtitle>
          {t('learning:template_sub_nav_onboarding')}
        </SubNavigationBar.Subtitle>
        <SubNavigationBar.Item
          exact
          icon="work"
          path="/admin/learning/onboarding"
          title={t('common:filter_all')}
        />
        <SubNavigationBar.Item
          icon="draft"
          path={`/admin/learning/onboarding/filter/${EStatus.DRAFT}`}
          title={t('common:status_draft')}
        />
        <SubNavigationBar.Item
          icon="play_arrow"
          path={`/admin/learning/onboarding/filter/${EStatus.LIVE}`}
          title={t('common:status_live')}
        />
        <SubNavigationBar.Item
          icon="inventory"
          path={`/admin/learning/onboarding/filter/${EStatus.ARCHIVED}`}
          title={t('common:status_archived')}
        />
        <SubNavigationBar.Item
          icon="analytics"
          path="/admin/learning/onboarding/statistics"
          title={t('learning:onboarding_tabs_statistics')}
        />
      </Permission>
      <Permission name={EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW}>
        <SubNavigationBar.Subtitle>
          {t('learning:template_sub_nav_academy')}
        </SubNavigationBar.Subtitle>
        <SubNavigationBar.Item
          exact
          icon="school"
          path="/admin/learning/academy"
          title={t('common:filter_all')}
        />
        <SubNavigationBar.Item
          icon="draft"
          path={`/admin/learning/academy/filter/${EStatus.DRAFT}`}
          title={t('common:status_draft')}
        />
        <SubNavigationBar.Item
          icon="play_arrow"
          path={`/admin/learning/academy/filter/${EStatus.LIVE}`}
          title={t('common:status_live')}
        />
        <SubNavigationBar.Item
          icon="inventory"
          path={`/admin/learning/academy/filter/${EStatus.ARCHIVED}`}
          title={t('common:status_archived')}
        />
        <SubNavigationBar.Item
          exact
          icon="playlist_add"
          path="/admin/learning/academy/paths/list"
          title={t('learning:learning_paths')}
        />
      </Permission>
    </SubNavigationBar>
  );
};

export default LearningSideNavigation;
