import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Container from '@common/components/container';
import Card from '@common/components/card';
import Permission from '@common/components/permission';
import { ProfileImage } from '@common/components/profile-image';
import { Icon } from '@common/components/icon';
import { IntegrationRedeemCode } from '../../../components/integration-redeem-code';
import { ProfileMemberships } from './profile-memberships';
import { PrivacyIndicator } from '../../../components/privacy-indicator';
import { EPermissions, EPrivacyLevels, WhiteLabelInvitationMode } from '@common/definitions';
import { useUserPermissions, useAppSelector } from '@common/hooks';

// @ts-expect-error
import SvgBackgroundCover from '../../../../../../assets/illustrations/background-cover.svg';

import type { ExtendedUser, LoggedUser } from '@common/types/objects';
import type { IconTypes } from '@common/components/icon';
import { useFormattedUserStatus } from '@modules/core/hooks';
import UserStatusPreview from '@common/components/user-status/user-status-preview';
import { SET_USER_STATUS, UNSET_USER_STATUS } from '@modules/core/actions';
import useUserStatusRemoteUpdateListener from '@common/hooks/use-user-status-remote-update-listener';
import { getWhiteLabelConfig } from '@modules/organisation/selectors/organisation';

type PersonalInfoRowType = {
  value: string | number | null | undefined;
  icon: IconTypes;
  name: string;
  privacyLevel?: EPrivacyLevels;
};

const PersonalInfoRow = ({ name, value, icon, privacyLevel }: PersonalInfoRowType) => (
  <div className="AboutProfile__PersonalInfoRowWrapper">
    <div className="AboutProfile__PersonalInfoRow">
      <Icon type={icon} size={20} />
      <div>
        <span>{name}</span>
        <span>{value || '-'}</span>
      </div>
    </div>
    {typeof privacyLevel === 'number' && <PrivacyIndicator privacyLevel={privacyLevel} />}
  </div>
);

type AboutProfileProps = {
  user: ExtendedUser | LoggedUser;
  onEdit: () => void;
};

export const AboutProfile = ({ user, onEdit }: AboutProfileProps) => {
  const { t } = useTranslation();
  const { hasPermissions } = useUserPermissions();
  const shouldShowRedeemCode = useAppSelector(getWhiteLabelConfig)?.invitation_mode === WhiteLabelInvitationMode.CODE;

  const hasViewPermission = hasPermissions([
    EPermissions.ORGANISATION_USERS_VIEW,
    EPermissions.NETWORK_USERS_VIEW
  ], true);

  // console.log("debug user", user);
  const status = useFormattedUserStatus(user?.scopes?.organisation?.status);
  // console.log("debug status", status);

  useUserStatusRemoteUpdateListener(
    user.id,
    (data: Record<string, any>, dispatch: any, userId: string) => {
      if (data.status) {
        dispatch({
          type: SET_USER_STATUS,
          userId,
          emoji: data.status.emoji,
          text: data.status.text,
          expires_at: data.status.expires_at
        });
      } else {
        dispatch({ type: UNSET_USER_STATUS, userId });
      }
    }
  );

  return (
    <Container name="AboutProfile">
      <Container.Content horizontal>
        <Container.Column>
          <Card containerClassName="AboutProfile__Header">
            <SvgBackgroundCover className="AboutProfile__BackgroundCover" />
            <ProfileImage
              className="AboutProfile__Avatar"
              onEdit={hasViewPermission ? onEdit : undefined}
              user={user}
              size={64}
            />

            <div className="AboutProfile__GeneralInfo">
              <span className="AboutProfile__FullName">{user.full_name}</span>
              {
                status ?
                  <UserStatusPreview
                    status={status} className="AboutProfile__Subsection"
                  /> :
                  null
              }
              <Permission
                name={[
                  EPermissions.ORGANISATION_USERS_VIEW,
                  EPermissions.NETWORK_USERS_VIEW
                ]}
                and={!!user.last_active}
              >
                <span className="AboutProfile__Subsection">
                  {t('core:active_count_ago')}{' '}
                  {moment(user.last_active).fromNow()}
                </span>
              </Permission>
            </div>

            <div className="AboutProfile__Stats">
              <span>
                <b>{user.statistics?.likes_count || 0}</b>
                {t('core:user_detail_likes_count')}
              </span>
              <span>
                <b>{user.statistics?.messages_count || 0}</b>
                {t('core:user_detail_messages_count')}
              </span>
            </div>
          </Card>

          <Card containerClassName="AboutProfile__PersonalInfo">
            <Card.Content>
              <div className="AboutProfile__PersonalInfoHeader">
                <span>{t('core:profile_personal_information')}</span>
                <Permission name={[EPermissions.ORGANISATION_USERS_UPDATE, EPermissions.NETWORK_USERS_UPDATE]}>
                  <span onClick={onEdit} role="button">{t('core:user_detail_change_button')}</span>
                </Permission>
              </div>

              <PersonalInfoRow value={user.bio} icon="chat__filled" name={t('core:user_detail_biography')} />
              <PersonalInfoRow value={user.full_name} icon="person__filled" name={t('core:user_detail_name')} />

              <Permission name={[EPermissions.ORGANISATION_USERS_VIEW, EPermissions.NETWORK_USERS_VIEW]}>
                <PersonalInfoRow value={user.email} icon="email__filled" name={t('core:user_detail_email')} />
              </Permission>

              <PersonalInfoRow
                value={user.date_of_birth && moment(user.date_of_birth).format('DD-MM-YYYY')}
                icon="calendar_today__filled"
                name={t('core:user_detail_date_of_birth')}
                // @ts-expect-error
                privacyLevel={user?.settings?.privacy.show_date_of_birth}
              />

              <PersonalInfoRow
                value={user.phone_num}
                icon="phone__filled"
                name={t('core:user_detail_phone_number')}
                // @ts-expect-error
                privacyLevel={user?.settings?.privacy.show_phone_num}
              />

              <Permission name={[EPermissions.ORGANISATION_USERS_VIEW, EPermissions.NETWORK_USERS_VIEW]}>
                <PersonalInfoRow
                  value={
                    user.scopes.organisation.active_from &&
                    moment(user.scopes.organisation.active_from).format('DD-MM-YYYY')
                  }
                  icon="calendar_today__filled"
                  name={t('core:profile_form_date_in_service')}
                />
                <PersonalInfoRow
                  value={
                    user.scopes.organisation.active_until &&
                    moment(user.scopes.organisation.active_until).format('DD-MM-YYYY')
                  }
                  icon="calendar_today__filled"
                  name={t('core:profile_form_date_out_of_service')}
                />
                <PersonalInfoRow
                  value={user.scopes.organisation.external_id}
                  icon="person__filled"
                  name={t('core:profile_form_unique_id')}
                />
              </Permission>
            </Card.Content>
          </Card>
        </Container.Column>

        <Container.SideBar className="AboutProfile__Sidebar">
          {shouldShowRedeemCode && (
            <Card>
              <Card.Content>
                <IntegrationRedeemCode user={user} />
              </Card.Content>
            </Card>
          )}
          <ProfileMemberships user={user} />
        </Container.SideBar>
      </Container.Content>
    </Container>
  );
};
