import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import Api from '@common/services/api';
import { useAppSelector } from '@common/hooks';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import { combineClassNames } from '../../../../common/utils/combineClassNames';
import { ResponsePage } from '@modules/documents/types';

import { Spinner } from '@common/components/spinner';
import { Button } from '@common/components/button';
import { Container } from '@common/components/container';

import '../../../../common/components/form/inputs/editor/editor.scss';

const PagePrint = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const organisationId = useAppSelector(getCurrentOrgId);
  const { pageId } = useParams<{ pageId: string, networkId: string }>();
  const [page, setPage] = useState<ResponsePage | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await Api.post<{
        data: ResponsePage;
      }>(`/v1/organisations/${organisationId}/documents/${pageId}/open?page_format=html`);
      setPage(res.data);
      setLoading(false);
    };
    fetchData();
  }, [pageId, organisationId]);


  const handlePrint = useCallback(() => {
    const initialTitle = document.title;
    // Title of the pdf file
    document.title = page?.name || '';

    window.print();
    document.title = initialTitle;
  }, [page]);

  return (
    <>
      <div className={combineClassNames(
          'print:tw-hidden tw-fixed tw-top-0 tw-z-5 tw-w-[calc(100%-40px)]',
          'tw-flex tw-py-4 tw-px-5 tw-bg-white',
        )}
      >
        <Button
          size="large"
          icon="chevron_left"
          isLoading={loading}
          onClick={() => history.goBack()}
        >
          {t('common:back')}
        </Button>
        <Button
          size="large"
          type="primary"
          icon="print"
          className="tw-ml-auto"
          onClick={handlePrint}
        >
          {t('core:print_or_save_as_pdf')}
        </Button>
      </div>
      <Container className={combineClassNames(
        'tw-mx-auto tw-pt-[72px]',
        '!tw-max-w-[var(--container-max-width-print)]',
        'print:tw-max-w-none print:tw-w-full print:!tw-p-0 print:tw-bg-white',
      )}
      >
        <Container.Content className="tiptap !tw-my-8">
          {loading ? <Spinner centered /> : (
            <div
              className="tw-bg-white tw-rounded-lg tw-p-16 print:tw-p-0"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: page?.page.html || '' }}
            />
          )}
        </Container.Content>
      </Container>
    </>
  );
};

export default PagePrint;
