import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { DndProvider } from 'react-dnd';
import dndManager from '@common/utils/dnd-manager';

import { QuestionScreen } from '@modules/learning/types/objects';
import type { StoreState } from '@common/types/store';
import Overview from '@common/components/overview';
import createScreenDraft from '@modules/learning/actions/create-screen-draft';
import { QUESTION_FORM_PREFIX } from '@modules/learning/utils/get-component-list';

import ComponentsList from '../../../learning/components/components-list';
import AddComponentForm from '../../../learning/forms/component/add';
import QuestionForm from '../../forms/question';
import { QuestionDropdown } from '../../components/question-dropdown';
import * as draftSelector from '../../../learning/selectors/draft';
import removeComponent from '@modules/learning/actions/remove-component';
import updateComponentsOrder from '@modules/learning/actions/update-components-order';
import { sortBy } from 'lodash';
import swap from '@common/utils/swap';


type EnabledComponentsArray = React.ComponentProps<typeof QuestionDropdown>['enabledComponents'];

type OwnProps = {
  screen?: QuestionScreen;
  match: {
    params: {
      surveyId?: string;
      id: string;
    };
  };
  disableComponents: boolean;
  enabledComponents: EnabledComponentsArray;
  enableDescription?: boolean;
  addComponent?: any;
  deleteComponent?: any;
  updateComponent?: any;
  updateOrderComponent?: any;
};

type Props = OwnProps & ConnectedProps<typeof reduxConnector>;

class QuestionContainer extends React.Component<Props> {
  static props: Props;

  handleDeleteComponent: (id: string) => void;

  constructor(props: Props) {
    super(props);

    this.handleDeleteComponent = (id) => {
      if (this.props.deleteComponent) {
        this.props.deleteComponent(this.props.match.params.id, id);
      } else {
        // Fallback for when no relying handler is provided
        // Should be removed in scope of ONE-805
        props.removeComponentAction(id, this.props.match.params.id);
      }
    };
  }

  componentDidMount() {
    const { match: { params }, screen, createDraft } = this.props;
    if (!screen) {
      // Fallback for when there is no screen
      // Should be removed in scope of ONE-805
      createDraft(params.id, params.surveyId);
    }
  }

  handleDrag = (componentId: string, index: number) => {
    const { screen, updateOrderComponent } = this.props;

    if (!screen) return;

    const order = sortBy(screen.components, ['index']).map(({ id }) => id);
    const items = swap(order, index, order.indexOf(componentId));

    if (updateOrderComponent) {
      updateOrderComponent(screen.id, items);
    } else {
      // Fallback for when no updateOrderComponent handler is provided
      // Should be removed in scope of ONE-805
      this.props.updateComponentsOrderAction(screen.id, items);
    }
  };

  render() {
    const {
      screen, disableComponents, enableDescription,
      enabledComponents, addComponent, updateComponent,
    } = this.props;

    if (!screen) return null;

    return (
      <Overview>
        <Overview.Content>
          <div className="Editor__Components">
            {(disableComponents !== true || !screen.question) && (
              <>
                <DndProvider manager={dndManager}>
                  <ComponentsList
                    id={screen && screen.id}
                    items={screen.components || []}
                    onDrag={this.handleDrag}
                    onDelete={this.handleDeleteComponent}
                    updateComponent={updateComponent}
                  />
                </DndProvider>
                <AddComponentForm
                  id={screen && screen.id}
                  type="screen"
                  components={(screen && screen.components) || []}
                  addComponent={addComponent}
                />
              </>
            )}
            {screen.question && (
              <QuestionForm
                key={screen.id}
                form={`${QUESTION_FORM_PREFIX}${screen.question.id}`}
                screen={screen}
                updateQuestion={updateComponent}
                enabledComponents={enabledComponents}
                enableDescription={enableDescription}
              />
            )}
          </div>
        </Overview.Content>
      </Overview>
    );
  }
}

const mapStateToProps = (state: StoreState, { match, screen }: OwnProps) => {
  if (screen) return screen;

  // Fallback to get the screen from the draft redux state.
  // Should be removed in scope of ONE-805
  return ({
    screen: draftSelector.screen(state, match.params.id),
  });
};

const mapDispatchToProps = {
  createDraft: createScreenDraft,
  removeComponentAction: removeComponent,
  updateComponentsOrderAction: updateComponentsOrder,
};

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

export default reduxConnector(QuestionContainer);
