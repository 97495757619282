import React from 'react';
import { Field, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import { Control, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { pick } from 'lodash';

import TextareaInput from './textarea-input';
import TextareaEmojiInput, { TextareaInputOwnProps } from './textarea-emoji-input';

const ALLOWED_PROPS = [
  'placeholder', 'rows', 'minRows', 'disableNewLine',
  'disabled', 'onEnter', 'onPaste', 'disableValidationFeedback'
];

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export function TextareaInputHookForm<T extends FieldValues>(
  props: Omit<TextareaInputOwnProps, 'ref'> & UseControllerProps<T> & { label?: string, control: Control<T> },
) {
  const { field, fieldState } = useController(props);
  return (
    <TextareaInput
      {...props}
      {...field}
      error={fieldState.error?.message || fieldState.error?.message === ''}
    />
  );
}

const ReduxFormField = (fieldProps: WrappedFieldProps & TextareaInputOwnProps) => {
  const props = {
    className: fieldProps.className,
    error: fieldProps.meta.touched && fieldProps.meta.error,
    warning: fieldProps.meta.touched && fieldProps.meta.warning,
    ...fieldProps.input,
    ...pick(fieldProps, ALLOWED_PROPS),
  };

  return fieldProps.enableEmojis ?
    <TextareaEmojiInput {...props} /> :
    <TextareaInput {...props} />;
};

const TextareaInputField = ({ name, ...props }: BaseFieldProps & TextareaInputOwnProps) => (
  <Field
    id={name}
    name={name}
    component={ReduxFormField}
    props={props}
  />
);

export default TextareaInputField;
