import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

import * as AlertMessage from '@common/services/alert';
import Alert from '@common/components/alert';
import { Button } from '@common/components/button';
import CourseDeadline from '../../components/course-deadline';
import Modal from '@common/components/modal';
import { Row, CheckboxInput } from '@common/components/form';

import updateCourse from '../../actions/update-course';
import { dateAndTimeToStr } from '@common/utils/date';
import { Course } from '@modules/learning/types/objects';
import { useFormValues } from '@common/hooks';

type CourseDeadlineFormmProps = PropsWithChildren<{
  course: Course;
}>;

type FormData = {
  has_deadline?: boolean;
  deadline_after_days_invited?: string;
  deadline?: {
    date: Moment;
    time: string;
  };
};

type Payload = {
  deadline: string | null;
  deadline_after_days_invited: string | null;
};

type Props = InjectedFormProps<FormData, CourseDeadlineFormmProps, boolean> & CourseDeadlineFormmProps;

const CourseDeadlineForm = ({
  course, form, submitting, children, initialize, reset, handleSubmit,
}: Props) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deadlineDynamic, setDeadlineDynamic] = useState(false);
  const formValues = useFormValues<FormData>(form);

  useEffect(() => {
    const deadline = moment(course.settings.deadline || undefined);
    const deadline_after_days_invited = course.settings.deadline_after_days_invited || '30';
    if (!course.settings.deadline) deadline.set({ hour: 9, minute: 0 });
    setDeadlineDynamic(!!course.settings.deadline_after_days_invited);

    initialize({
      has_deadline: !!course.settings.deadline || !!course.settings.deadline_after_days_invited,
      deadline: {
        date: deadline,
        time: deadline.format('HH:mm'),
      },
      deadline_after_days_invited,
    });
  }, []);

  const onSubmit = useCallback(async (values, dispatch) => {
    try {
      const settings: Payload = {
        deadline: null,
        deadline_after_days_invited: null,
      };
      if (values.has_deadline) {
        if (deadlineDynamic) {
          settings.deadline_after_days_invited = values.deadline_after_days_invited;
        } else {
          settings.deadline = dateAndTimeToStr(values.deadline.time, values.deadline.date);
        }
      }
      await dispatch(updateCourse(course.id, { settings }));
      AlertMessage.success(t('learning:form_academy_deadline_has_been_saved'));
      setIsModalVisible(false);
    } catch (response: any) {
      return AlertMessage.forStatus(response.status_code, {
        warning: t('learning:form_academy_warning_saving_deadline'),
        error: t('learning:form_academy_error_saving_deadline'),
      });
    }
  }, [course, deadlineDynamic, setIsModalVisible, t]);

  return (
    <Modal
      list
      show={isModalVisible}
      onShow={() => setIsModalVisible(true)}
      onClose={() => reset()}
      title={t('learning:form_academy_deadline_modal_title')}
      wrapper={Modal.FormWrapper}
      wrapperProps={{
        onSubmit: handleSubmit(onSubmit),
      }}
      content={(
        <>
          <Alert type="info">
            {t('learning:form_academy_deadline_description')}
          </Alert>
          <Row>
            <CheckboxInput
              name="has_deadline"
              label={t('learning:form_academy_deadline_label')}
            />
          </Row>
          {formValues && formValues.has_deadline && (
            <CourseDeadline
              deadlineDynamic={deadlineDynamic}
              setDeadlineDynamic={(value) => setDeadlineDynamic(value)}
            />
          )}
        </>
      )}
      footer={(
        <Button buttonType="submit" type="primary" isLoading={submitting}>
          {t('learning:form_academy_save')}
        </Button>
      )}
    >
      {children}
    </Modal>
  );
};

export default reduxForm<FormData, CourseDeadlineFormmProps, boolean>({})(CourseDeadlineForm);
