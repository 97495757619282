import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import Badge from '@common/components/badge';
import { IconTypes } from '@common/components/icon';

import { useAppSelector } from '@common/hooks';
import mergeNameText from '@common/utils/merged-names-text';
import { Filters, Language, Predicate } from '@common/types/objects';
import { EPredicateFields, ESearchPredicateTypes } from '@common/definitions';
import { StoreState } from '@common/types/store';

import './audience-badge.scss';

type PredicateType = Predicate & {
  context?: Filters['networks'] | Filters['functions'] | Filters['users'] | Language[];
};

type AudienceBadgeProps = {
  predicates: PredicateType[];
  type: `${ESearchPredicateTypes}`;
  filters?: Filters;
};

const ICONS: Record<EPredicateFields | 'answers.value', IconTypes> = {
  [EPredicateFields.NETWORK]: 'storefront',
  [EPredicateFields.FUNCTION]: 'work',
  [EPredicateFields.USER]: 'person',
  [EPredicateFields.LANGUAGE]: 'language',
  [EPredicateFields.DAYS_IN_SERVICE]: 'event',
  [EPredicateFields.DAYS_BEFORE_OUT_OF_SERVICE]: 'event_busy',
  [EPredicateFields.DATE]: 'info',
  'answers.value': 'info',
};

const showPredicate = (
  { attribute, comparison, context, value }: PredicateType,
  translations: Language[],
  t: TFunction,
  filters?: Filters,
) => {
  switch (attribute) {
    case EPredicateFields.NETWORK:
      return mergeNameText(context || filters?.networks || [], 2, 'name');
    case EPredicateFields.FUNCTION:
      return mergeNameText(context || filters?.functions || [], 2, 'name');
    case EPredicateFields.USER:
      return mergeNameText(context || filters?.users || [], 2, 'full_name');
    case EPredicateFields.LANGUAGE:
      const languages = context || translations.filter(({ locale }: Language) => value.includes(locale));
      return mergeNameText(languages, 2, 'name_eng');
    case EPredicateFields.DAYS_IN_SERVICE:
      return t('survey:days_in_service', {
        context: comparison,
        count: Array.isArray(value) ? value[0] : value,
        count2: Array.isArray(value) ? value[1] : null
      });
    case EPredicateFields.DAYS_BEFORE_OUT_OF_SERVICE:
      return t('survey:predicates_filter_predicate_days_before_out_of_service');
    default:
      return null;
  }
};

const AudienceBadge = ({ predicates, type, filters }: AudienceBadgeProps) => {
  const { t } = useTranslation();
  const translations = useAppSelector((state: StoreState) => state.language.translations);
  return (
    <span>
      {(predicates.length && predicates.map((predicate, i: number) => (
        <>
          <Badge icon={ICONS[predicate.attribute]}>
            {showPredicate(predicate, translations, t, filters)}
          </Badge>
          {i < (predicates.length - 1) && (
            <span className="AudienceBadge__Separator">
              {
                type === ESearchPredicateTypes.MATCH_ALL ?
                  t('survey:form_audience_and') :
                  t('survey:form_audience_or')
              }
            </span>
          )}
        </>
      ))) || (
        <small className="">
          {t('survey:form_no_audience')}
        </small>
      )}
    </span>
  );
};

export default AudienceBadge;
