import React, { useState, useMemo } from 'react';
import { Popup } from 'semantic-ui-react';
import { Picker, Emoji, BaseEmoji } from 'emoji-mart';
import { useTranslation } from 'react-i18next';

import Icon, { IconTypes } from '@common/components/icon';
import { combineClassNames } from '@common/utils/combineClassNames';

export type EmojiPickerProps = {
  onSelect?: (...args: any[]) => any
  triggerIcon?: string
  emoji?: BaseEmoji;
  className?: string;
  size?: number;
  error?: string | boolean;
};

export const EmojiPicker = ({
  triggerIcon = 'insert_emoticon', emoji, className, onSelect, size = 20, error,
}: EmojiPickerProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const triggerClassName = combineClassNames('EmojiPicker__triggerIcon triggerIcon', className, {
    'EmojiPicker__triggerIcon--invalid': !!error,
  });
  const i18n = useMemo(() => {
    return {
      search: t('core:emoji_search'),
      clear: t('core:emoji_clear'), // Accessible label on "clear" button
      notfound: t('core:emoji_not_found'),
      skintext: t('core:emoji_skin_text'),
      categories: {
        search: t('core:emoji_categories_search'),
        recent: t('core:emoji_categories_recent'),
        smileys: t('core:emoji_categories_smileys'),
        people: t('core:emoji_categories_people'),
        nature: t('core:emoji_categories_nature'),
        foods: t('core:emoji_categories_foods'),
        activity: t('core:emoji_categories_activity'),
        places: t('core:emoji_categories_places'),
        objects: t('core:emoji_categories_objects'),
        symbols: t('core:emoji_categories_symbols'),
        flags: t('core:emoji_categories_flags'),
        custom: t('core:emoji_categories_custom'),
      },
      categorieslabel: t('core:emoji_categories_label'),
      skintones: {
        '1': t('core:emoji_skintone_default'),
        '2': t('core:emoji_skintone_light'),
        '3': t('core:emoji_skintone_medium-light'),
        '4': t('core:emoji_skintone_medium'),
        '5': t('core:emoji_skintone_medium-dark'),
        '6': t('core:emoji_skintone_dark'),
      },
    };
  }, [t]);

  return (
    <Popup
      disabled={!onSelect}
      open={open}
      className="EmojiPicker__Popup EmojiPicker"
      content={(
        <>
          <Picker i18n={i18n} showPreview={false} onSelect={onSelect} />
          <div className="EmojiPicker__Popup__Close">
            <Icon type="close" onClick={() => setOpen(false)} />
          </div>
        </>
      )}
      on="click"
      position="top left"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        emoji ?
          <span className={triggerClassName}>
            <Emoji emoji={emoji.colons || emoji} size={size} />
          </span> :
          <Icon
            className={triggerClassName}
            type={triggerIcon as IconTypes}
            size={size}
          />
      }
    />
  );
};
