import React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';

import i18n from '../../../i18n';
import mergeNamesText from '../merged-names-text';
import { EPredicateFields, ESearchPredicateTypes } from '@common/definitions';
import type {
  Predicate,
  NetworkPredicate,
  FunctionPredicate,
  UserPredicate,
  Filters,
  Audience,
  Language,
} from '@common/types/objects';
import { LooseObject } from '@common/types/util-types';

const findFilters = <Y, T extends Record<string, Y>>(ids: string | string[] | undefined, storedItems: T): Y[] => {
  if (!ids) return [];

  return (Array.isArray(ids) ? ids : [ids])
    .map((id) => storedItems[id])
    .filter((item) => !!item);
};

export type AudienceDictionary = {
  functions?: LooseObject<{ name: string; }>[];
  networks?: LooseObject<{ name: string; }>[];
  users?: LooseObject<{ full_name: string }>[];
  translations?: Language[];
};


export const useSimplePredicatesList = (
  audienceMeta?: AudienceDictionary,
  audience?: Audience
) => {
  const { t } = useTranslation();
  if (!audienceMeta) return null;

  const { functions, networks, users, translations } = audienceMeta;
  const hasDaysInService = !!audience?.predicates?.find((pr) => {
    return pr.attribute === EPredicateFields.DAYS_IN_SERVICE;
  });
  const languages = (translations && audience?.predicates?.filter((pr) => {
    return pr.attribute === EPredicateFields.LANGUAGE && Array.isArray(pr.value);
  }).map((pr) => {
    return (pr.value as string[]).map((locale) => {
      return translations.find((tr) => tr.locale === locale)?.name;
    });
  }).flat().filter((name) => !!name)) || [];

  return [
    (functions || []).map((f) => f.name).join(', '),
    (networks || []).map((f) => f.name).join(', '),
    (users || []).map((f) => f.full_name).join(', '),
    hasDaysInService && t('common:predicates_filter_predicate_days_in_service'),
    languages.length ? `${t('common:predicates_filter_predicate_language')}: ${languages.join(', ')}` : null,
  ].filter((value) => !!value).join('; ');
};

export const predicatesToFilters = (
  predicates: Predicate[] = [],
  // TODO any for now, this whole function needs to be changed drastically
  // or deleted, see https://flex-appeal.atlassian.net/browse/PD-8853
  state: any
): Filters => {
  const networkFilter = predicates.find(
    (predicate): predicate is NetworkPredicate => predicate.attribute === EPredicateFields.NETWORK,
  );
  const functionFilter = predicates.find(
    (predicate): predicate is FunctionPredicate => predicate.attribute === EPredicateFields.FUNCTION,
  );
  const userFilter = predicates.find(
    (predicate): predicate is UserPredicate => predicate.attribute === EPredicateFields.USER,
  );

  return {
    networks: findFilters(networkFilter?.value, state.networks.items),
    functions: findFilters(functionFilter?.value, state.organisation.functions.items),
    users: findFilters(userFilter?.value, state.users.items),
  };
};

export const filtersToText = (filters: Filters, predicateType: ESearchPredicateTypes) => {
  if (!filters) return i18n.t('common:utils_predicates_all_employees_in_organisation');

  if (
    predicateType !== ESearchPredicateTypes.MATCH_ALL
    && filters.networks.length === 0 && filters.functions.length === 0 && filters.users.length > 0
  ) {
    return [
      `${i18n.t('common:utils_predicates_just')} `,
      mergeNamesText(filters.users, -1, 'full_name', (name) => <b>{name}</b>, i18n.t('common:utils_predicates_or')),
    ];
  }

  let parts: React.ReactNode[] = [];

  if (filters.networks.length > 0) {
    parts = R.append([
      `${i18n.t('common:utils_predicates_selected_in')} `,
      mergeNamesText(
        filters.networks,
        -1,
        'name',
        (name) => <b key={`n-${name}`}>{name}</b>,
        i18n.t('common:utils_predicates_or'),
      ),
    ], parts);
  }

  if (filters.functions.length > 0) {
    parts = R.append([
      `${i18n.t('common:utils_predicates_who_have_function')} `,
      mergeNamesText(
        filters.functions,
        -1,
        'name',
        (name) => <b key={`f-${name}`}>{name}</b>,
        i18n.t('common:utils_predicates_or'),
      ),
      `${i18n.t('common:utils_predicates_have')} `,
    ], parts);
  }

  if (predicateType !== ESearchPredicateTypes.MATCH_ALL && filters.users.length > 0) {
    parts = R.append([
      `${i18n.t('common:utils_predicates_is_employee')} `,
      mergeNamesText(
        filters.users,
        -1,
        'full_name',
        (name) => <b key={`u-${name}`}>{name}</b>,
        i18n.t('common:utils_predicates_or'),
      ),
    ], parts);
  }

  if (parts.length === 0) return i18n.t('common:utils_predicates_all_employees_in_organisation');

  return R.pipe(
    R.intersperse(
      predicateType === ESearchPredicateTypes.MATCH_ALL
        ? ` ${i18n.t('common:utils_predicates_and')} `
        : ` ${i18n.t('common:utils_predicates_or')} `,
    ),
    R.flatten,
    R.prepend(i18n.t('common:utils_predicates_all_employees')),
    R.intersperse(' '),
    // @ts-expect-error
  )(parts);
};

export const predicatesToPayload = (predicates: Predicate[]) => predicates
  .map((predicate) => ({
    attribute: predicate.attribute,
    comparison: predicate.comparison,
    value: predicate.value,
  }))
  .filter((predicate) => {
    if (Array.isArray(predicate.value)) return predicate.value.length > 0;

    return !!predicate.value;
  });


type AudienceToPayload = Pick<Audience, 'predicate_type' | 'predicates'>;

export const audienceToPayload = (audience: AudienceToPayload) => {
  return {
    ...audience,
    predicates: predicatesToPayload(audience.predicates)
  };
};
