/* eslint-disable react/no-array-index-key */
import React from 'react';

interface StackProps {
  direction?: 'row' | 'column';
  gap?: number; // Gap in pixels
  divider?: React.ReactNode; // Divider element
  children: React.ReactNode;
  align?: 'start' | 'center' | 'end';
  justify?: 'start' | 'center' | 'end';
}

const Stack: React.FC<StackProps> = ({
  direction = 'column',
  gap = 0,
  divider,
  children,
  align,
  justify
}) => {

  const getAlign = (value: 'start' | 'center' | 'end') => {
    if (value === 'start') return 'flex-start';
    if (value === 'center') return 'center';
    if (value === 'end') return 'flex-end';
    return 'flex-start';
  };

  const getJustify = (value: 'start' | 'center' | 'end') => {
    if (value === 'start') return 'flex-start';
    if (value === 'center') return 'center';
    if (value === 'end') return 'flex-end';
    return 'flex-start';
  };

  const styles: React.CSSProperties = {
    ...(gap ? { gap: `${gap * 4}px` } : {}),
    ...(direction === 'row' ? { flexDirection: 'row' } : { flexDirection: 'column' }),
    ...(align ? { alignItems: getAlign(align) } : {}),
    ...(justify ? { justifyContent: getJustify(justify) } : {})
  };

  return (
    <div style={{ display: 'flex', ...styles }}>
      {React.Children.map(children, (child, index) => (
        <React.Fragment key={index}>
          {child}
          {divider && index < React.Children.count(children) - 1 && (
            <div className="flex items-center">{divider}</div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Stack;
