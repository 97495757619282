import { TFunction } from 'react-i18next';
import { Course } from '../types/objects';

const getCoursePhasing = (course: Course, t: TFunction) => {
  if (course.settings.visible_after_days_invited) {
    return t('learning:form_onboarding_days_after_invitation', {
      count: course.settings.visible_after_days_invited,
    });
  }
  return null;
};

export default getCoursePhasing;
