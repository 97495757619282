import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InjectedFormProps, reduxForm } from 'redux-form';
import * as R from 'ramda';

import Modal from '@common/components/modal';
import { AudienceInput } from '@common/components/form/audience';
import { Button } from '@common/components/button';
import { Row, Group } from '@common/components/form';
import * as Alert from '@common/services/alert';

import { EPredicateFields, EPredicateOperators, ESearchPredicateTypes, predicatesToPayload } from '@common/utils/predicates';
import updateCourse from '../../actions/update-course';
import { usePlanPackageAudienceHandlers } from '@common/hooks/use-plan-package-audience-handlers';
import { Course } from '@modules/learning/types/objects';
import { EPlanPackageConfig } from '@common/definitions';
import { ECourseTypes } from '@modules/learning/definitions';

type CourseAudienceFormProps = PropsWithChildren<{
  course: Course;
}>;

type FormData = {
  settings: {
    visibility: {
      predicate_type: string;
      predicates: Object[];
    };
  }
};

type Payload = {
  visibility: {
    predicate_type: string;
    predicates: {
      attribute: EPredicateFields | 'answers.value';
      comparison: EPredicateOperators;
      value: unknown;
    }[];
  }
};

type Props = InjectedFormProps<FormData, CourseAudienceFormProps, boolean> & CourseAudienceFormProps;

const CourseAudienceForm = ({
  course, dirty, submitting, children, initialize, reset, handleSubmit,
}: Props) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const planPackageAudienceHandlers = usePlanPackageAudienceHandlers({
    packageIdAddFilters: EPlanPackageConfig.ACADEMY_AUDIENCE_SELECTOR,
  });

  useEffect(() => {
    initialize({
      settings: {
        visibility: {
          predicate_type: course.settings.visibility.predicate_type,
          predicates: course.settings.visibility.predicates.map((predicate, i) => R.assoc('id', i, predicate)),
        },
      },
    });
  }, [isModalVisible]);

  const onSubmit = useCallback(async (values, dispatch) => {
    try {
      const settings: Payload = {
        visibility: {
          predicate_type: values.settings.visibility.predicate_type,
          predicates: predicatesToPayload(values.settings.visibility.predicates),
        },
      };
      await dispatch(updateCourse(course.id, { settings }));
      Alert.success(t('learning:form_academy_audience_has_been_saved'));
      setIsModalVisible(false);
    } catch (response: any) {
      return Alert.forStatus(response.status_code, {
        warning: t('learning:form_academy_warning_saving_audience'),
        error: t('learning:form_academy_error_saving_audience'),
      });
    }
  }, [course, updateCourse, setIsModalVisible, t]);

  return (
    <Modal
      list
      size="large"
      show={isModalVisible}
      onShow={() => setIsModalVisible(true)}
      onClose={() => reset()}
      title={t('learning:form_academy_target_audience_modal_title')}
      wrapper={Modal.FormWrapper}
      wrapperProps={{
        onSubmit: handleSubmit(onSubmit),
      }}
      content={(
        <Row>
          <Group>
            <AudienceInput
              name="settings.visibility"
              {...planPackageAudienceHandlers}
              enabledFilters={[
                EPredicateFields.USER,
                EPredicateFields.LANGUAGE,
                EPredicateFields.NETWORK,
                EPredicateFields.FUNCTION,
                course.type === ECourseTypes.ACADEMY ? EPredicateFields.DAYS_IN_SERVICE : undefined,
              ].filter(Boolean)}
            />
          </Group>
        </Row>
      )}
      footer={(
        <Button buttonType="submit" type="primary" disabled={!dirty} isLoading={submitting}>
          {t('learning:form_academy_save')}
        </Button>
      )}
    >
      {children}
    </Modal>
  );
};

export default reduxForm<FormData, CourseAudienceFormProps, boolean>({
  form: 'course-audience',
  initialValues: {
    settings: {
      visibility: {
        predicate_type: ESearchPredicateTypes.MATCH_ALL,
        predicates: [],
      },
    },
  },
})(CourseAudienceForm);
