import React, {
  ReactNode, memo, useCallback, useEffect, useState
} from 'react';
import { Action, Location } from 'history';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import ModalContent from '@common/components/modal/modal-content';
import ModalWindow from '@common/components/modal/modal-window';
import { Button } from '@common/components/button';
import { Actions } from '@common/utils/history';

type PageLeaveCheckPopupProps = {
  title?: string;
  message: ReactNode;
  shouldRunOnRoute: (location: Location<unknown>, action: Action) => boolean;
  onSave?: () => Promise<void>;
};

type RouteChangeConfig = {
  action: Action;
  location: Location<unknown>;
};

const PageLeaveCheckPopup = memo(({
  message,
  title,
  shouldRunOnRoute,
  onSave,
}: PageLeaveCheckPopupProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [routeUpdateConfig, setRouteUpdateConfig] = useState<null | RouteChangeConfig>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const modalOpen = !!routeUpdateConfig;

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (
        !modalOpen &&
        shouldRunOnRoute(location, action) &&
        !(location.state as any)?.dontAskForPageLeaveConfirmation
      ) {
        setRouteUpdateConfig({ location, action });
        // Make sure we show the correct url when using the POP action
        if (action === Actions.POP) window.history.forward();
        return false;
      }
      // Prevent hiding the modal when calling window.history.forward
      // to show the correct url
      if (action !== Actions.POP) {
        setRouteUpdateConfig(null); // close the modal
      }
    });

    return () => unblock();
  }, [history, setRouteUpdateConfig, modalOpen, shouldRunOnRoute]);

  const onHide = useCallback(() => setRouteUpdateConfig(null), [setRouteUpdateConfig]);

  const onConfirm = useCallback(() => {
    if (!routeUpdateConfig) return;

    const { action, location } = routeUpdateConfig;
    const path = `${location.pathname}${location.search}`;

    if (action === Actions.PUSH || action === Actions.POP) return history.push(path);
    if (action === Actions.REPLACE) return history.replace(path);
  }, [routeUpdateConfig, history]);

  const onSaveAndClose = useCallback(async () => {
    setIsSaving(true);
    await onSave?.();
    setIsSaving(false);
    onConfirm();
  }, [onSave, onConfirm, setIsSaving]);

  return (
    <ModalWindow
      show={!!modalOpen}
      onHide={onHide}
      className="ConfirmButton PageLeaveCheckPopup"
    >
      <ModalContent
        hideHeader
        onHide={onHide}
        onConfirm={onConfirm}
        confirmButtonDisabled={isSaving}
        footerChildren={onSave && (
          <Button onClick={onSaveAndClose} disabled={isSaving}>
            {t('common:save_and_confirm')}
          </Button>
        )}
      >
        {title && <h3>{ title }</h3>}
        <div className="ConfirmButton__description">
          {message}
        </div>
      </ModalContent>
    </ModalWindow>
  );
});

export default PageLeaveCheckPopup;
