import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import * as predicateUtil from '../../../../common/utils/predicates';
import { ECourseTypes } from '../../definitions';

const { NETWORK, FUNCTION, USER } = predicateUtil.EPredicateFields;

const getIdsList = (formValues, predicateField) => {
  const predicates = formValues?.settings?.visibility?.predicates || [];
  return predicates.find((p) => p.attribute === predicateField)?.value || [];
};

const MAX_ENTITIES = 3;
const getVisibilityLabels = (ids, entities, t) => {
  const chunks = ids.slice(0, MAX_ENTITIES).map((id) => {
    const name = entities.find((e) => e.id === id)?.name;
    return name || t('learning:course_preview_visibility_unknown');
  });
  const excess = ids.length - MAX_ENTITIES;
  if (excess > 0) {
    chunks.push(`+${excess}`);
  }
  return chunks;
};

const getDeadlineLabel = (formValues, deadlineDynamic, t) => {
  if (deadlineDynamic) return getPhasingLabel(formValues.deadline_after_days_invited, t);
  const time = formValues.deadline.time.split(':');
  return moment(formValues.deadline.date)
    .set({ hour: time[0], minute: time[1], second: 0 })
    .format(`DD MMMM YYYY [${t('common:utils_date_at')}] HH:mm`);
};

const getPhasingLabel = (days, t) => t(
  'learning:course_preview_filters_phasing_visible_after_days_invited',
  { count: +days },
);

function renderVisibilityLabels(ids, entities, t) {
  return getVisibilityLabels(ids, entities, t).join(', ');
}

function Audience({ networks, functions, formValues }) {
  const { t } = useTranslation();

  const communityIds = getIdsList(formValues, NETWORK);
  const functionIds = getIdsList(formValues, FUNCTION);
  const userIds = getIdsList(formValues, USER);

  const hasFilters = (
    communityIds.length > 0 || functionIds.length > 0 || userIds.length > 0
  );
  return (
    <>
      <tr>
        <td>
          <b>{ t('learning:course_preview_filters_label_visibility') }</b>
        </td>
        {
          hasFilters
            ? <td />
            : <td>{ t('learning:course_preview_visibility_everyone') }</td>
        }
      </tr>
      {
        userIds.length > 0
          ? (
            <tr>
              <td>{ t('common:predicates_filter_column_name_label') }:</td>
              <td>
                {
                  t('learning:users_predicate_filter_label', {
                    count: userIds.length
                  })
                }
              </td>
            </tr>
          )
          : null
      }
      {
        communityIds.length > 0
          ? (
            <tr>
              <td>
                { t('common:predicates_filter_column_communities_label') }:
              </td>
              <td>
                { renderVisibilityLabels(communityIds, networks, t) }
              </td>
            </tr>
          )
          : null
      }
      {
        functionIds.length > 0
          ? (
            <tr>
              <td>
                { t('common:predicates_filter_column_functions_label') }:
              </td>
              <td>
                { renderVisibilityLabels(functionIds, functions, t) }
              </td>
            </tr>
          )
          : null
      }
    </>
  );
}

function Onboarding({ formValues }) {
  const { t } = useTranslation();
  return (
    <tr>
      <td>
        <b><Trans i18nKey="learning:course_preview_filters_label_phasing" />:</b>
      </td>
      <td>
        {
          formValues.phased ?
            getPhasingLabel(formValues.settings.visible_after_days_invited, t) :
            t('learning:course_preview_filters_phasing_no')
        }
      </td>
    </tr>
  );
}

function Academy({ formValues, deadlineDynamic }) {
  const { t } = useTranslation();
  return (
    <>
      <tr>
        <td>
          <b><Trans i18nKey="learning:course_preview_filters_label_deadline" />:</b>
        </td>
        <td>
          {
            formValues.has_deadline ?
              getDeadlineLabel(formValues, deadlineDynamic, t) :
              t('learning:course_preview_filters_phasing_no')
          }
        </td>
      </tr>
      <tr>
        <td>
          <b><Trans i18nKey="learning:course_preview_filters_label_notifications" />:</b>
        </td>
        <td>
          {
            formValues.should_notify ?
              t('learning:course_preview_notifications_should_send') :
              t('learning:course_preview_notifications_should_not_send')
          }
        </td>
      </tr>
    </>
  );
}

const LearningCoursePreviewComponent = ({
  course, formValues, networks, functions, deadlineDynamic = false,
}) => {
  return (
    <div className="CoursePreview">
      {
        course.header_image_url
        && (
        <img
          className="CoursePreview__Header"
          src={course.header_image_url}
          alt={course.name}
        />
        )
      }
      <h2 className="CoursePreview__Name">{course.name}</h2>
      {
        course.description
        && <div className="CoursePreview__Description">{course.description}</div>
      }
      <div className="CoursePreview__Filters">
        <table>
          <tbody>
            <Audience
              networks={networks}
              functions={functions}
              formValues={formValues}
            />
            {course.type === ECourseTypes.ONBOARDING && <Onboarding formValues={formValues} />}
            {course.type === ECourseTypes.ACADEMY && <Academy formValues={formValues} deadlineDynamic={deadlineDynamic} />}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LearningCoursePreviewComponent;
