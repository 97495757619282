import React from 'react';
import { useParams, generatePath, RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';

import Container from '@common/components/container';
import Bar from '@common/components/bar';
import { SearchBar } from '@common/components/search-bar';
import { useSearchParams, usePageTracker } from '@common/hooks';
import { EEventNames } from '@services/analytics';
import { DocumentsBrowser } from '../../../../documents/components/documents-browser';
import { DocumentsHeaderActionButtons } from '../../../../documents/components/documents-header-action-buttons';
import { DocumentsRouteParams } from '../../../../documents/types';
import { StoreState } from '@common/types/store';
import { getDocument } from '../../../../documents/selectors';
import { connect, ConnectedProps } from 'react-redux';
import { ExtendedUser, LoggedUser } from '@common/types/objects';

import './personal-documents.scss';

type PersonalDocumentsRouteParams = Pick<DocumentsRouteParams, 'folderId' | 'filter'>;
type PersonalDocumentsRouteProps = RouteComponentProps<PersonalDocumentsRouteParams>;

const mapStateToProps = (state: StoreState, { match: { params } }: PersonalDocumentsRouteProps) => {
  const currentDocument = params.folderId ? getDocument(state, params.folderId) : undefined;

  return {
    currentFolder: currentDocument?.is_folder ? currentDocument : undefined,
  };
};

const connector = connect(mapStateToProps);

type PersonalDocumentsReduxProps = ConnectedProps<typeof connector>;

type PersonalDocumentsOwnProps = {
  profilePath: string;
  user: ExtendedUser | LoggedUser | undefined;
};

type PersonalDocumentsProps = PersonalDocumentsOwnProps & PersonalDocumentsReduxProps & PersonalDocumentsRouteProps;

export const UnconnectedPersonalDocuments = ({ profilePath, user, currentFolder }: PersonalDocumentsProps) => {
  usePageTracker(EEventNames.VIEWED_ALL_PERSONAL_DOCUMENTS);

  const params = useParams<PersonalDocumentsRouteParams>();
  const { searchParams, setSearchParam } = useSearchParams<{ search?: string }>();
  const { t } = useTranslation();

  const getNextPath = (folderId: string) => generatePath(`${profilePath}/personal-documents/${folderId}`, params);

  const handleSetSearchTerm = (newSearchTerm: Parameters<typeof setSearchParam>[1]) => setSearchParam(
    'search',
    newSearchTerm,
    `${profilePath}/personal-documents`,
  );

  return (
    <Container.Content>
      <Bar>
        <h2>{t('core:personal_documents_header', { firstName: user?.first_name })}</h2>
        <SearchBar
          placeholder={t('documents:search_placeholder')}
          onSearch={handleSetSearchTerm}
          defaultValue={searchParams.search}
          key={`${params.filter}${params.folderId}`} // remounting when those change in order to clear the input
        />
        <DocumentsHeaderActionButtons
          personalFolder
          params={params}
          currentFolder={currentFolder}
          userId={user?.id}
        />
      </Bar>
      <DocumentsBrowser
        getNextFolderPath={getNextPath}
        searchTerm={searchParams.search}
        userId={user?.id}
      />
    </Container.Content>
  );
};

export const PersonalDocuments = connector(UnconnectedPersonalDocuments);
