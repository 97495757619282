import { PropsWithChildren } from 'react';
import { WrappedFieldArrayProps } from 'redux-form';
import { omit } from 'lodash';

import * as fileUtil from '@common/utils/file';
import createTempId from '@common/utils/create-temp-id';

import { Attachment, FileInProgress, FileInputValue } from '@common/types/objects';
import { ErrorType } from '../../message';

// list of image mime types that are deemed important by this article
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
// minus 'webp', 'svg' and 'vnd.microsoft.icon' formats
export const IMAGES_ACCEPT = [
  'avif', 'bmp', 'gif', 'jpeg', 'jpg', 'png', 'tiff',
].map((format: string) => `image/${format}`).join(', ');

export const VIDEO_ACCEPT = 'video/*';

export const ALL_ACCEPT = [
  'application/pdf',
  '.mp3', '.wav', '.aac',
  '.doc', '.docx', '.docm', '.dotx', '.dotm', '.pages', '.txt',
  '.csv', '.xls', '.xlsx', '.xlsm', '.xltm', '.numbers',
  '.ppt', '.pptx', '.pps', '.ppsx', '.pptm', '.potx', '.potm', '.key', '.odp',
].join(',');


export type FileInputOwnProps = PropsWithChildren<{
  unsplash?: boolean;
  accept?: string;
  acceptedExtLabel?: string;
  className?: string;
  placeholder?: string;
  maxFileSize?: number;
  disabled?: boolean;
  processFile?: boolean;
  onChange: (value?: FileInputValue, index?: number) => void;
  onError?: (rejectedFiles: ErrorType[]) => void;
  multiple?: boolean | number;
  value?: FileInputValue | FileInputValue[] | null;
}>;

export type FileInputProps = Omit<FileInputOwnProps, 'value' | 'onChange'>;

export type FileInputHookFormProps = FileInputProps & {
  fields: FileInputValue[];
  insert: (index:number, data?: FileInputValue) => void;
  update: (index: number, data?: FileInputValue) => void;
};

export const getFileInputHookFormProps = ({
  fields, multiple, insert, update, ...otherProps
}: FileInputHookFormProps) => ({
  ...otherProps,
  value: fields,
  multiple,
  onChange: (file?: FileInputValue, index?: number) => {
    if (multiple && multiple !== 1) {
      if (index !== undefined) update(index, file);
    } else if (file) {
      update(0, file);
    }
  },
});

export type FileInpuFormProps = WrappedFieldArrayProps & FileInputProps;

export const getMultipleFileInputFormProps = ({
  fields, multiple, ...otherProps
}: FileInpuFormProps) => ({
  ...omit(otherProps, ['meta', 'fields']),
  multiple,
  value: fields.getAll(),
  onChange: (file?: FileInputValue, index?: number) => {
    if (index !== undefined) {
      if (file?.processing || file?.is_remote_file) {
        fields.insert(index, file);
      } else if (file) {
        fields.splice(index, 1, file);
      }
    }
  },
});

export enum FileErrorType {
  INVALID = 'invalid',
  MAX_FILE_SIZE = 'maxFileSize',
  MAX_AMOUNT_OF_FILES = 'maxAmountOfFiles',
}

export const RejectedFileTypes = {
  [FileErrorType.MAX_AMOUNT_OF_FILES]: 'common:too_many_files',
  [FileErrorType.MAX_FILE_SIZE]: 'common:file_is_too_large',
  [FileErrorType.INVALID]: 'common:file_is_invalid',
};

type Options = {
  maxFileSize?: number;
  processFile?: boolean;
  multiple?: number | boolean;
};

type SetFormFileAttachmentsParams = {
  files: File[];
  value?: FileInputValue | FileInputValue[] | null;
  organisationId?: string;
  onChange?: ((value?: FileInputValue, index?: number) => void);
  onError?: (errors: ErrorType[]) => void;
  options: Options;
};

export const createFileInProgress = (file: File): FileInProgress => ({
  id: createTempId(),
  file_name: file.name,
  file_type: file.type,
  processing: false,
  preview: null,
  file,
});

export const setFormFileAttachments = ({
  files,
  value,
  organisationId,
  onChange,
  onError,
  options: { maxFileSize = 30, processFile, multiple },
}: SetFormFileAttachmentsParams) => {
  if (files.length === 0) return;

  const [filesToUpload, excessFiles] = typeof multiple === 'number' && Array.isArray(value)
    ? [files.slice(0, multiple - (value?.length || 0)), files.slice(multiple - (value?.length || 0))]
    : [files];

  if (onError && excessFiles && excessFiles.length > 0) {
    onError([{
      translationKey: RejectedFileTypes[FileErrorType.MAX_AMOUNT_OF_FILES],
      props: { amount: excessFiles.length, maxAmountOfFiles: `${multiple}` },
    }]);
    return;
  }

  let rejectedFiles: ErrorType[] = [];
  filesToUpload.forEach(async (f, index) => {
    const file: FileInProgress = createFileInProgress(f);

    if (maxFileSize && !fileUtil.isValidFile(file.file, maxFileSize)) {
      if (onError) {
        rejectedFiles = [...rejectedFiles, {
          translationKey: RejectedFileTypes[FileErrorType.MAX_FILE_SIZE],
          props: { fileName: file.file.name, maxFileSize },
        }];
      }
      return;
    }

    const fileIndex = (Array.isArray(value) || typeof multiple === 'number')
      ? (Array.isArray(value) ? value.length : 0) + index - rejectedFiles.length
      : undefined;

    file.preview = await fileUtil.getImageBase64(f);

    if (processFile) {
      let isCancelled = false;
      file.processing = true;

      const payload = new FormData();
      payload.append('file', f);

      const { onProgress, promise } = await fileUtil.upload(
        `/v2/organisations/${organisationId}/files`,
        payload,
      );

      file.progress = onProgress;
      file.promise = promise;
      file.cancelPromise = () => (isCancelled = true);

      if (onChange) onChange(file, fileIndex);

      promise.catch((error) => {
        isCancelled = true;
        if (onChange) onChange(undefined, fileIndex);
        if (onError) onError([{
          translationKey: RejectedFileTypes[FileErrorType.INVALID],
          props: { fileName: file.file.name, error: `${error.status_code} - ${error.type}: ${error.detail}` },
        }]);
      });

      const attachment: Attachment = await promise;

      if (!isCancelled) {
        attachment.preview = file.preview;
        if (onChange) onChange(attachment, fileIndex);
      }
    } else if (onChange) {
      onChange(file);
    }
  });

  if (onError) onError(rejectedFiles);
};

