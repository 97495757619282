import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from '@common/components/modal';
import { TextInputHookForm as TextInput } from '@common/components/form/inputs/text';
import { Api } from '@common/services/api';
import { AlertService } from '@common/services/alert';
import { Button } from '@common/components/button';
import { getMetaFromState } from '@modules/forms/selectors';
import { formatFormWithScreens } from '@modules/forms/actions';
import { regex } from '@common/utils/validation';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { EPlanPackageConfig } from '@common/definitions';

const ModalContent = ({
  form, rows, canForwardEmail, showUpgradeModal,
}: any) => {
  const { t } = useTranslation();

  const { control, setValue } = form;
  const { fields, append, remove } = useFieldArray({ control, name: 'emails' });

  // cancel button press support, we should reset the state when that happens
  // this whole file needs to be refactored heavily (and so does moderators.tsx)
  // we need a different/new Modal that is easy to use with local state
  useEffect(() => {
    return () => {
      setValue('emails', rows);
    };
  }, [rows, setValue]);

  return (
    <>
      {
        fields.map((field, index) => (
          <span key={field.id} className="emailSlot">
            <TextInput
              type="email"
              name={`emails.${index}.value`}
              control={control}
              onChange={canForwardEmail ? undefined : showUpgradeModal}
              rules={{ required: true, pattern: regex.EMAIL }}
            />
            <Button
              icon="delete__filled"
              size="large"
              onClick={() => remove(index)}
            />
          </span>
        ))
      }
      <Button
        iconRight="add"
        className="addExtraEmail"
        onClick={canForwardEmail ? () => append({ value: '' }) : showUpgradeModal}
      >
        { t('forms:add_extra_email') }
      </Button>
    </>
  );
};

export const ConnectToEmailModalButton = ({ organisationId, formId, rows }: any) => {
  const { t } = useTranslation();
  const form = useForm({
    mode: 'all',
    defaultValues: {
      emails: rows?.length > 0 ? rows : [{ value: '' }]
    }
  });
  const {
    isAvailable: canForwardEmail, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.FORMS_EMAIL_FORWARDING);

  // when forms/RECEIVE_FORM is dispatched in onSave we need to listen for that
  // change and update the form. This part needs to be refactored once we have
  // a modal that works properly with local state
  const { setValue } = form;
  useEffect(() => {
    setValue('emails', rows);
  }, [setValue, rows]);

  const meta = useSelector((state: any) => getMetaFromState(state, formId));
  const dispatch = useDispatch();

  const footer = (handleHide: Function) => {
    const onSave = form.handleSubmit(async (data: any) => {
      const update = data.emails.filter((email: any) => {
        const row = rows.find((_row: any) => _row.id === email.id);
        return !!row && row.value !== email.value; // get only changed values
      }).map((email: any) => { // format update payload
        return { id: email.id, value: email.value };
      });

      const add = data.emails.filter((e: any) => {
        return typeof e.id !== 'string';
      }).map((row: any) => ({ value: row.value }));

      const remove = rows.filter((row: any) => {
        const email = data.emails.find((e: any) => e.id === row.id);
        return !email;
      }).map((row: any) => ({ id: row.id }));

      if (update.length <= 0 && add.length <= 0 && remove.length <= 0) {
        return handleHide();
      }

      try {
        const res = await Api.post(
          `/v1/organisations/${organisationId}/forms/${formId}/connections`,
          {
            emails: { update, add, remove }
          }
        );
        dispatch({
          type: 'forms/RECEIVE_FORM',
          item: formatFormWithScreens(res.data),
          meta
        });

        const count = update.length;
        const msg = t('forms:email_address_saved', { count });
        AlertService.success(msg);
        handleHide();
      } catch (error: any) {
        AlertService.forStatus(error.status_code, {
          warning: t('forms:email_address_save_warning'),
          error: t('forms:email_address_save_error'),
        });
      }
    });

    return (
      <Button type="primary" disabled={!form.formState.isValid} onClick={onSave}>
        { t('forms:save_and_close') }
      </Button>
    );
  };

  return (
    <Modal
      list
      className="Form ConnectToEmailModal"
      title={t('forms:connect_submissions_to_email')}
      content={(
        <ModalContent
          form={form}
          rows={rows}
          canForwardEmail={canForwardEmail}
          showUpgradeModal={showUpgradeModal}
        />
      )}
      footer={footer}
    >
      <div className="formSettingsModal pointer">
        <span>
          <Button icon="email__filled" size="large">
            {t('forms:connect_to_email')}
          </Button>
        </span>
        <p className="underline counter">
          {t('forms:connections_count', { count: rows.length })}
        </p>
      </div>
    </Modal>
  );
};
