import { createSelector } from 'reselect';
import { EComponentTypes } from '@common/definitions';
import { hasEnabledComponent } from '@common/utils/has-enabled-component';
import * as orgSelectors from '@modules/organisation/selectors/organisation';
import * as networkSelectors from '@modules/network/selectors/network';
import { getOrganisationMembership } from './logged-user';

export const isEmployeeOnboardingEnabled = createSelector(
  [orgSelectors.selected, networkSelectors.selected, getOrganisationMembership],
  (org, net, memberships) => {
    return (
      hasEnabledComponent(EComponentTypes.ONBOARDING, net, org) &&
      !!memberships?.onboarding
    );
  }
);
