import { FormPayload } from '@modules/forms/actions';
import { ESearchPredicateTypes } from '@common/utils/predicates';

export const createInitialValues = (form?: FormPayload) => {
  return {
    title: form?.title || '',
    status: form?.status || 'draft',
    icon: form?.icon.split('attachments/')[1] || 'default/forms/account-circle.png',
    color: form?.color || '#007AFF',
    activate_status: !!(form && (form.response_statuses?.length || 0) > 0),
    audience: {
      predicate_type: form?.audience.predicate_type || ESearchPredicateTypes.MATCH_ANY,
      predicates: form?.audience.predicates || [],
    },
    settings: {
      show_as_shortcut: form?.settings.show_as_shortcut || false,
      is_anonymous: form?.settings.is_anonymous || false,
      show_status_to_users: form?.settings.show_status_to_users || false,
      notify_user_of_status_updates: form?.settings.notify_user_of_status_updates || false,
    }
  };
};
