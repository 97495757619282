import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Trans, useTranslation } from 'react-i18next';

import ToggleContent from '@common/components/toggle-content';
import Image from '@common/components/image';
import Permission from '@common/components/permission';
import Icon from '@common/components/icon';
import Dropdown from '@common/components/dropdown';
import Confirm from '@common/components/confirm-button';
import FilterItem from '../filter-item';
import CourseAudience from '@modules/learning/forms/course-audience';
import CoursePhasing from '@modules/learning/forms/course-phasing';

import { EPermissions } from '@common/definitions';
import getStatus from '../../utils/get-status';
import { AudienceDictionary, useSimplePredicatesList } from '@common/utils/predicates';
import getCoursePhasing from '@modules/learning/utils/get-course-phasing';
import type { Course } from '../../types/objects';

import './styles.scss';

export type Props = {
  index: number;
  item: Course,
  showStatus: boolean;
  onChangeOrder: (sourceId: string, targetId: string) => void,
  onDrop: () => Promise<void>,
  onOpen: (id: string) => void,
  onPublish: (id: string, archive: boolean) => void,
  onArchive: (id: string, publish: boolean) => void,
  onDelete: (id: string) => Promise<void>,
  onDuplicate: (id: string) => Promise<void>,
  onChangeCourseType: (id: string) => Promise<void>,
};

type Source = {
  id: string;
};

const LearningOnboardingItem = ({
  index,
  item,
  showStatus,
  onChangeOrder,
  onDrop,
  onOpen,
  onPublish,
  onArchive,
  onDelete,
  onDuplicate,
  onChangeCourseType,
}: Props) => {
  const { t } = useTranslation();
  const audienceFilter = useSimplePredicatesList(item.filters as AudienceDictionary);

  const [, dropRef] = useDrop({
    accept: ['course'],
    hover: (source: Source) => {
      if (source.id === item.id) return;

      onChangeOrder(source.id, item.id);
    },
    drop: () => {
      if (index === item.index) return;
      onDrop();
    },
  });

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'course',
    item: () => ({ id: item.id }),
    isDragging: (monitor) => item.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleChangeStatus = (publish: boolean) => setTimeout(() => onPublish(item.id, publish), 0);

  return (
    <div ref={dropRef} style={{ opacity: isDragging ? 0 : 1, marginBottom: 12 }}>
      <ToggleContent
        disabled
        image={item.header_image_url
          ? <Image size={32} alt={item.name} src={item.header_image_url} />
          : <div className="TopNavigationBar__Row__Info__Image"><Icon type="image" /></div>}
        title={(
          <a onClick={() => onOpen(item.id)} role="link">{item.name}</a>
        )}
        handleRef={dragRef}
        previewRef={previewRef}
        className="OnboardingToggle"
        badge={(showStatus && getStatus(item)) || undefined}
        action={(
          <>
            <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_UPDATE}>
              <>
                <CoursePhasing course={item} form={`academy-course/${item.id}/phasing`}>
                  <FilterItem
                    icon="calendar_today"
                    placeholder={t('learning:form_onboarding_phasing')}
                    className="OnboardingFilter__Item"
                  >
                    {getCoursePhasing(item, t)}
                  </FilterItem>
                </CoursePhasing>
                <CourseAudience course={item} form={`academy-course/${item.id}/audience`}>
                  <FilterItem
                    icon="visibility_off"
                    placeholder={t('learning:course_preview_visibility_everyone')}
                    className="OnboardingFilter__Item"
                  >
                    {audienceFilter}
                  </FilterItem>
                </CourseAudience>
              </>
            </Permission>
            <Permission
              name={[
                EPermissions.ORGANISATION_ONBOARDING_COURSES_CREATE,
                EPermissions.ORGANISATION_ONBOARDING_COURSES_REMOVE,
              ]}
            >
              <>
                <div className="spacer" />
                <Dropdown
                  alignRight
                  id={`onboarding-item-${item.id}`}
                  toggle={<Icon type="more_vert" />}
                >
                  {!item.archived && (
                    <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_UPDATE}>
                      <Dropdown.Item onClick={() => handleChangeStatus(!item.published)}>
                        {item.published ? t('common:unpublish') : t('common:publish')}
                      </Dropdown.Item>
                    </Permission>
                  )}
                  <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_CREATE}>
                    <Dropdown.Item onClick={() => onDuplicate(item.id)}>
                      <Trans i18nKey="learning:onboarding_item_duplicate" />
                    </Dropdown.Item>
                  </Permission>
                  <Dropdown.Item onClick={() => void onChangeCourseType(item.id)}>
                    {t('learning:move_to_elearning')}
                  </Dropdown.Item>
                  <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_UPDATE}>
                    <Dropdown.Item onClick={() => onArchive(item.id, !item.archived)}>
                      {item.archived ? t('common:unarchive') : t('common:archive')}
                    </Dropdown.Item>
                  </Permission>
                  <Permission name={EPermissions.ORGANISATION_ONBOARDING_COURSES_REMOVE}>
                    <Confirm
                      title={t('learning:onboarding_item_confirm_remove_title')}
                      description={t('learning:onboarding_item_confirm_remove_description')}
                      onConfirm={() => onDelete(item.id)}
                    >
                      <Dropdown.Item danger><Trans i18nKey="learning:onboarding_item_remove" /></Dropdown.Item>
                    </Confirm>
                  </Permission>
                </Dropdown>
              </>
            </Permission>
          </>
        )}
      />
    </div>
  );
};

export default LearningOnboardingItem;
