import moment from 'moment';
import { TFunction } from 'react-i18next';

import { Course } from '../types/objects';

const getCourseDeadline = (
  { settings: { deadline, deadline_after_days_invited } }: Course,
  t: TFunction,
) => {
  if (deadline) {
    return moment(deadline).format(`DD MMM YYYY [${t('learning:form_academy_at')}] HH:mm`);
  }
  if (deadline_after_days_invited) {
    return `${deadline_after_days_invited} ${t('common:days')}`;
  }
  return null;
};

export default getCourseDeadline;
