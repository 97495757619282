import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { TableDropdown } from '@common/components/table';
import Permission from '@common/components/permission';
import { useAppSelector } from '@common/hooks';
import { openInNewTab } from '@utils/open-in-new-tab';
import { EPermissions } from '@common/definitions';
import { FileUploadForm } from '../../forms/file-upload';
import { Filters } from '../../constants';

import type {
  Document,
} from '../../types';

type Props = {
  item: Document;
  filter: Filters;
  onShowDetails: () => void;
  setFolderForEdit: (documentId?: string) => void;
  setDocumentToDelete: (documentId: string) => void;
  setDocumentToMove: (documentId: string) => void;
  setDocumentToRename: (documentId: string) => void;
  restoreDocument: (documentId: string) => void;
  onToggleDocumentFavoriteState: (item: Document) => void;
};

export const DocumentActions = ({
  item,
  filter,
  onShowDetails,
  setFolderForEdit,
  setDocumentToDelete,
  setDocumentToMove,
  setDocumentToRename,
  restoreDocument,
  onToggleDocumentFavoriteState,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { networkId } = useParams<{ networkId: string }>();
  // @ts-expect-error
  const loggedUserId = useAppSelector((store) => store.loggedUser.selected);
  const isOwnDocument = item.personal_root_user_id === loggedUserId;

  if (item.type === 'personal_folder') return null;

  return (
    <TableDropdown id={`document-${item.id}`}>
      {filter === Filters.TRASH ? (
        <Permission name={[EPermissions.NETWORK_DOCUMENTS_CREATE, EPermissions.ORGANISATION_DOCUMENTS_CREATE]}>
          <TableDropdown.Item
            icon="restore"
            onClick={() => restoreDocument(item.id)}
          >
            {t('documents:restore')}
          </TableDropdown.Item>
        </Permission>
      ) : (
        <>
          {!item.is_folder && (
            <>
              <TableDropdown.Item
                icon="download"
                onClick={() => {
                  if (item.page) {
                    history.push(`/networks/${networkId}/documents/page/${item.id}/print`);
                  } else {
                    openInNewTab(item.attachment.download_path!, true);
                  }
                }}
              >
                {t('documents:download')}
              </TableDropdown.Item>
              {!isOwnDocument && (
                <Permission name={[EPermissions.NETWORK_DOCUMENTS_UPDATE, EPermissions.ORGANISATION_DOCUMENTS_UPDATE]}>
                  <>
                    {!item.personal_root_user_id && (
                      <TableDropdown.Item
                        icon="folder"
                        onClick={() => setDocumentToMove(item.id)}
                      >
                        {t('documents:move_to')}
                      </TableDropdown.Item>
                    )}
                    <TableDropdown.Item
                      icon="edit"
                      onClick={() => setDocumentToRename(item.id)}
                    >
                      {t('documents:rename')}
                    </TableDropdown.Item>
                    {!item.page && (
                      <FileUploadForm
                        parentFolderId={item.parent_folder_id || undefined}
                        documentId={item.id}
                        personalFolder={!!item.personal_root_user_id}
                      >
                        <TableDropdown.Item icon="sync">
                          {t('documents:replace_file')}
                        </TableDropdown.Item>
                      </FileUploadForm>
                    )}
                  </>
                </Permission>
              )}
            </>
          )}
          {item.is_folder && !isOwnDocument && (
            <Permission name={[EPermissions.NETWORK_DOCUMENTS_UPDATE, EPermissions.ORGANISATION_DOCUMENTS_UPDATE]}>
              <TableDropdown.Item
                icon="edit"
                onClick={item.is_folder ? () => setFolderForEdit(item.id) : undefined}
              >
                {t('documents:edit_folder')}
              </TableDropdown.Item>
            </Permission>
          )}
          <TableDropdown.Item icon="info" onClick={onShowDetails}>{t('documents:details_and_activity')}</TableDropdown.Item>
          {!item.personal_root_user_id || isOwnDocument && (
            <TableDropdown.Item icon="star" onClick={() => onToggleDocumentFavoriteState(item)}>
              {t(item.is_favorited ? 'documents:remove_from_favorites' : 'documents:add_to_favorites')}
            </TableDropdown.Item>
          )}
        </>
      )}
      {!isOwnDocument && (
        <Permission name={[EPermissions.NETWORK_DOCUMENTS_REMOVE, EPermissions.ORGANISATION_DOCUMENTS_REMOVE]}>
          <TableDropdown.Item danger icon="delete" onClick={() => setDocumentToDelete(item.id)}>
            {t('common:delete')}
          </TableDropdown.Item>
        </Permission>
      )}
    </TableDropdown>
  );
};
