import React, { ComponentProps } from 'react';
import moment from 'moment';
import * as R from 'ramda';
import { connect, ConnectedProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import { ConfirmButton } from '@common/components/confirm-button';
import Stack from '@common/components/stack';
import Table from '@common/components/table';
import { EEventNames, getExtension, track } from '@services/analytics';
import { DocumentIcon } from '../document-icon';
import { Icon } from '@common/components/icon';
import { useStateWithIdMapping } from '@utils/use-state-with-id-mapping';
import { bytesToSize } from '@utils/file';
import { Filters } from '../../constants';
import { sortDocuments } from '../../containers/documents-container/sort-documents';
import { DocumentActions } from '../document-actions';
import { DocumentsPlaceholder } from '../documents-placeholder';
import * as documentsActions from '../../actions';
import { resolveFolderKey } from '../../utils';
import { getDocument, getDocuments, getNextCursor, getRawDocumentItems } from '../../selectors';
import { useOnKeyDown } from '@common/hooks';
import { DeleteDocumentModal } from '../delete-document-modal';
import { FolderModalForm } from '../../forms/folder-modal-form';
import { PickFolderModal } from '../pick-folder-modal';
import { MoveDocumentModal } from '../move-document-modal';
import { DocumentsFileViewer } from '../documents-file-viewer';
import { AlertService } from '@services/alert';
import { DocumentDetail } from '../document-detail';
import { RenameDocumentModal } from '../../forms/rename-document-modal';

import type { StoreState } from '@common/types/store';
import type { Document, Folder, File, DocumentsRouteProps } from '../../types';
import { combineClassNames } from '@common/utils/combineClassNames';

import './documents-browser.scss';

const mapStateToProps = (
  state: StoreState,
  { match: { params }, location: { search }, userId }: DocumentsRouteProps & DocumentsBrowserOwnProps,
) => {
  const searchParams = new URLSearchParams(search);
  const folderKey = resolveFolderKey({
    ...params,
    searchTerm: searchParams.get('search') || undefined,
  }, params.folderId === 'personal' ? state.loggedUser.user.id : userId);

  const currentDocument = params.folderId ? getDocument(state, params.folderId) : undefined;

  return {
    documents: getDocuments(state, folderKey),
    rawDocumentItems: getRawDocumentItems(state),
    currentFolder: currentDocument?.is_folder ? currentDocument : undefined,
    nextCursor: getNextCursor(state, folderKey),
  };
};

const mapDispatchToProps = {
  fetchDocuments: documentsActions.fetchDocuments,
  toggleDocumentFavoriteStatus: documentsActions.toggleDocumentFavoriteStatus,
  markDocumentAsViewed: documentsActions.markDocumentAsViewed,
  restoreDocument: documentsActions.restoreDocument,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type DocumentsBrowserReduxProps = ConnectedProps<typeof connector>;

type DocumentsBrowserOwnProps = {
  getNextFolderPath: (folderId: string) => string;
  searchTerm?: string;
  userId?: string;
};

type DocumentsBrowserProps = DocumentsBrowserReduxProps & DocumentsRouteProps & DocumentsBrowserOwnProps;

export const UnconnectedDocumentsBrowser = ({
  searchTerm,
  getNextFolderPath,
  documents,
  currentFolder,
  rawDocumentItems,
  fetchDocuments,
  markDocumentAsViewed,
  toggleDocumentFavoriteStatus,
  restoreDocument,
  nextCursor,
  userId,
  match: { params },
  history,
}: DocumentsBrowserProps) => {
  const { t } = useTranslation();
  const [documentItem, setDocument] = useStateWithIdMapping(rawDocumentItems);
  const [documentToDelete, setDocumentToDelete] = useStateWithIdMapping(rawDocumentItems);
  const [documentToMove, setDocumentToMove] = useStateWithIdMapping(rawDocumentItems);
  const [documentToRestore, setDocumentToRestore] = useStateWithIdMapping(rawDocumentItems);
  const [documentToRename, setDocumentToRename] = useStateWithIdMapping(rawDocumentItems);
  const [folderToOpenInTrash, setFolderToOpenInTrash] = useStateWithIdMapping(rawDocumentItems as Record<string, Folder>);
  const [folderForEdit, setFolderForEdit] = useStateWithIdMapping(rawDocumentItems as Record<string, Folder>);
  const [fileInPreview, setFileInPreview] = useStateWithIdMapping(rawDocumentItems as Record<string, File>);

  React.useEffect(() => {
    setDocument(undefined);
  }, [params]);

  useOnKeyDown(({ key }) => {
    if (!documentItem || fileInPreview) return;

    const index = documents.findIndex((d) => d.id === documentItem.id);

    switch (key) {
      case 'ArrowUp':
        if (index === 0) return;

        return setDocument(documents[index - 1].id);
      case 'ArrowDown':
        if (index === documents.length - 1) return;

        return setDocument(documents[index + 1].id);
      default:
        break;
    }
  }, [documents, documentItem]);

  const handleToggleDocumentFavoriteState = async (item: Document) => {
    const { is_favorited: isFavorited } = item;

    try {
      await toggleDocumentFavoriteStatus(item.id);
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t(isFavorited
            ? 'documents:warning_while_removing_from_favorites'
            : 'documents:warning_while_adding_to_favorites'),
          error: t(isFavorited
            ? 'documents:error_while_removing_from_favorites'
            : 'documents:error_while_adding_to_favorites'),
        });
      }
    }
  };

  const today = moment();
  const columns = [
    {
      size: '40%',
      label: t('documents:label_name'),
      className: 'Document__Name',
      sort_key: 'name',
    }, {
      size: '25%',
      label: params.filter === 'trash' ? t('documents:label_deleted') : t('documents:label_modified'),
      sort_key: 'updated_at',
    }, {
      size: '25%',
      label: t('documents:label_file_size'),
    },
  ];

  const handleItemOnClick = (item: Document): React.MouseEventHandler => () => {
    if (item.page) {
      return history.push(
        `/networks/${params.networkId}/documents/${params.folderId ? `${params.folderId}/` : ''}page/${item.id}`,
      );
    }

    markDocumentAsViewed(item.id);

    if (item.is_folder) {
      if (params.filter === Filters.TRASH) {
        return setFolderToOpenInTrash(item.id);
      }

      return history.push(getNextFolderPath(item.id));
    }

    setFileInPreview(item.id);
  };

  const handleRestoreDocument = async (documentId: string, newParentId?: string | null) => {
    const translationContext = rawDocumentItems[documentId].is_folder ? 'folder' : 'file';

    try {
      await restoreDocument(documentId, newParentId); // First call to check if it can be restored without moving
      AlertService.success(t('documents:restore_success', { context: translationContext }));
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        if (e.status_code === 422 && !documentToRestore) {
          return setDocumentToRestore(documentId); // If we couldn't restore it normally, open move document modal
        }

        AlertService.forStatus(e.status_code, {
          warning: t('documents:restore_warning', { context: translationContext }),
          error: t('documents:restore_error', { context: translationContext }),
        });
      }
    }
  };

  const handleOnPickRestore: ComponentProps<typeof PickFolderModal>['onPick'] = async ({ selectedFolder, handleClose }) => {
    if (documentToRestore) {
      await handleRestoreDocument(documentToRestore.id, selectedFolder?.id || null);
    }

    handleClose();
  };

  const handleTrackDocumentViewer = (eventName: string) => ({ is_folder, attachment }: Partial<File>) => track(eventName, {
    isFolder: is_folder,
    fileType: attachment?.file_type,
    fileExtension: attachment?.path ? getExtension(attachment.path) : undefined,
  });

  return (
    <>
      <Table
        key={`${params.folderId}-${params.filter}`}
        columns={columns}
        items={documents}
        initialSortState={params.filter !== Filters.RECENT ? { key: 'name', direction: 'asc' } : undefined}
        selectedRow={documentItem && documentItem.id}
        disableInitialFetch={documents?.length > 0}
        sortingDisabled={params.filter === Filters.RECENT}
        // @ts-expect-error
        sortingHandler={params.filter !== Filters.RECENT ? sortDocuments(params.filter) : undefined}
        onChangeSorting={(name, direction) => track(EEventNames.ORDERED_DOCUMENTS, { name, direction })}
        data={{
          cache: {
            pagination: {
              offset: documents.length,
            },
            nextCursor,
          },
          useCursor: true,
          filter: {
            searchTerm,
            userId,
            filter: params.filter,
            folderId: params.folderId,
          },
          onFetch: fetchDocuments,
        }}
        rowProps={(item: Document) => ({
          userId,
          filter: params.filter,
          setDocumentToDelete,
          setFolderForEdit,
          setDocumentToMove,
          setDocumentToRename,
          restoreDocument: handleRestoreDocument,
          onShowDetails: () => setDocument(item.id),
          onToggleDocumentFavoriteState: handleToggleDocumentFavoriteState,
        })}
        renderRow={(props) => {
          const item = props.item as Document; // Fixes issue with renderRow

          // If currently on the trash filter
          const deletedAt = params.filter === 'trash' && moment(item.deleted_at);

          if (item.type === 'personal_folder') {
            return [
              <>
                <div className="Document__Icon">
                  <DocumentIcon item={item} />
                </div>
                <div>
                  <div className="Table__Cell__Title" onClick={handleItemOnClick(item)} role="link">
                    <a>{t('documents:personal_documents')}</a>
                  </div>
                </div>
              </>,
              null,
              null,
            ];
          }

          return [
            <>
              <div className="Document__Icon">
                <DocumentIcon item={item} />
              </div>
              <Stack direction="row" gap={2}>
                <div className="Table__Cell__Title" onClick={handleItemOnClick(item)} role="link">
                  <a className={combineClassNames('Document__Title', {
                      '!tw-text-gray-400': !item.name,
                    })}
                  >
                    {item.name || t('knowledge_base:title_empty')}
                  </a>
                  {item.is_favorited && <Icon type="star__filled" />}
                </div>
                {/* @ts-expect-error */}
                {item.status === 'draft' && (
                  <div className="Badge BadgeStatus BadgeStatus--draft">
                    {t('common:status', { context: 'draft' })}
                  </div>
                )}
              </Stack>
            </>,
            deletedAt
              ? (
                <>
                  {deletedAt.format('D MMM YYYY')}
                  &nbsp;
                  <span className="Document__DeletedIn">
                    (
                    {t('documents:days_left', { count: 14 - today.diff(deletedAt, 'days') })}
                    )
                  </span>
                </>
              )
              : moment(item.updated_at).format('D MMM YYYY'),
            !item.is_folder ? bytesToSize(item.attachment?.file_size) : '-',
          ];
        }}
        // @ts-expect-error
        ActionComponent={DocumentActions}
        placeholder={(
          <DocumentsPlaceholder filter={params.filter} />
        )}
      />

      {documentToDelete && (
        <DeleteDocumentModal
          isPermanent={params.filter === Filters.TRASH}
          documentItem={documentToDelete}
          onClose={() => setDocumentToDelete(undefined)}
          userId={userId}
        />
      )}

      {folderForEdit && (
        <FolderModalForm
          initialIsVisible
          folder={folderForEdit}
          currentFolder={currentFolder}
          initialValues={folderForEdit}
          onClose={() => setFolderForEdit(undefined)}
          userId={userId}
        />
      )}

      {documentToRestore && (
        <PickFolderModal
          initialIsVisible
          onPick={handleOnPickRestore}
          onClose={() => setDocumentToRestore(undefined)}
        />
      )}

      {documentToMove && (
        <MoveDocumentModal
          initialIsVisible
          documentItem={documentToMove}
          onClose={() => setDocumentToMove(undefined)}
        />
      )}

      {folderToOpenInTrash && (
        <ConfirmButton
          title={t('documents:folder_is_deleted')}
          confirmText={t('documents:restore')}
          description={t('documents:restore_if_you_want_open')}
          onConfirm={R.pipe(() => handleRestoreDocument(folderToOpenInTrash.id), () => setFolderToOpenInTrash(undefined))}
          onCancel={() => setFolderToOpenInTrash(undefined)}
        />
      )}

      <DocumentsFileViewer
        documents={documents}
        fileInPreview={fileInPreview}
        setFileInPreview={setFileInPreview}
        handleToggleDocumentFavoriteState={handleToggleDocumentFavoriteState}
        onShow={handleTrackDocumentViewer(EEventNames.VIEWED_DOCUMENT)}
        onPageChange={handleTrackDocumentViewer(EEventNames.VIEWED_DOCUMENT)}
        onDownloadFile={handleTrackDocumentViewer(EEventNames.DOWNLOADED_DOCUMENT)}
      />

      {documentItem && (
        <DocumentDetail
          item={documentItem}
          onClose={() => setDocument(undefined)}
        />
      )}

      {documentToRename && (
        <RenameDocumentModal
          document={documentToRename}
          onClose={() => setDocumentToRename(undefined)}
        />
      )}
    </>
  );
};

export const DocumentsBrowser = withRouter(connector(UnconnectedDocumentsBrowser));
