import React, {
  memo, useCallback, useState, useEffect, useMemo
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Api from '@common/services/api';
import { ApiResponse } from '@common/services/api/types';
import { APIAcademyCourse } from '@modules/learning/types/objects';
import Spinner from '@common/components/spinner';
import SearchBar from '@common/components/search-bar';
import { ApiLearningPathResponse } from '@modules/learning/types/learning-paths';
import ModalContent from '@common/components/modal/modal-content';
import ModalWindow from '@common/components/modal/modal-window';
import { AlertService } from '@common/services/alert';
import SelectableCourseCard from './selectable-course-card';
import './add-courses-modal.scss';

type AddCoursesModalProps = {
  orgId: string;
  path: ApiLearningPathResponse;
  updateCourses: (courses: APIAcademyCourse[]) => void;
  onHide: () => void;
};

const AddCoursesModal = memo(({
  orgId,
  path,
  updateCourses,
  onHide
}: AddCoursesModalProps) => {

  const [selectedCourses, setSelectedCourses] = useState(() => {
    return path.data.courses.map((course) => course.id);
  });

  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [courses, setCourses] = useState<null | ApiResponse<APIAcademyCourse[]>>(null);

  useEffect(() => {
    setLoading(true);
    const coursesUrl = `/v2/organisations/${orgId}/courses?type=academy`;
    Api.get<ApiResponse<APIAcademyCourse[]>>(coursesUrl).then((response) => {
      setLoading(false);
      setCourses(response);
    });
  }, [setLoading, setCourses, orgId]);

  const pathId = path.data.id;
  const onConfirm = useCallback(async () => {
    if (!courses) return;
    try {
      setSaving(true);
      await Api.patch(
        `/v2/organisations/${orgId}/learning-paths/${pathId}/courses`,
        { courses: selectedCourses }
      );
      AlertService.success(t('core:changes_saved'));

      // let's update the courses list in the parent component with the updated
      // selected objects
      const selectedCoursesObjects = selectedCourses.map((selectedCourseId) => {
        return courses.data.find((course) => course.id === selectedCourseId);
      }).filter((value) => !!value);
      updateCourses(selectedCoursesObjects as APIAcademyCourse[]);

      onHide();
    } catch (error) {
      AlertService.error(t('core:changes_could_not_be_saved'));
      throw error;
    } finally {
      setSaving(false);
    }
  }, [setSaving, t, orgId, selectedCourses, pathId, onHide, updateCourses, courses]);

  const [search, setSearch] = useState<string | null>(null);

  const filteredCourses = useMemo(() => {
    if (!courses?.data) return [];
    if (!search) return courses.data;

    const lowerCaseSearch = search.toLowerCase();
    return courses.data.filter((course) => {
      const name = (course.name || '').toLowerCase();
      const description = (course.description || '').toLowerCase();
      return (
        name.indexOf(lowerCaseSearch) > -1 ||
        description.indexOf(lowerCaseSearch) > -1
      );
    });
  }, [courses, search]);

  return (
    <ModalWindow
      show
      size="large"
      onHide={onHide}
      className="AddCoursesModal"
    >
      <ModalContent
        title={t('learning:select_courses_to_add')}
        onHide={onHide}
        confirmButtonText={t('common:save')}
        onConfirm={onConfirm}
        confirmButtonLoading={loading || saving}
      >
        {
          loading || !courses ?
            <Spinner size="large" centered /> :
            <>
              <div className="topbar">
                <SearchBar
                  onSearch={setSearch}
                  placeholder={t('learning:search_course')}
                />
                <h4>
                  <Trans
                    i18nKey="learning:academy_action_bar_selected_courses"
                    values={{ count: selectedCourses.length }}
                  />
                </h4>
              </div>
              <div className="cards">
                {
                  filteredCourses.map((course) => {
                    const selected = selectedCourses.indexOf(course.id) > -1;
                    return (
                      <SelectableCourseCard
                        id={course.id}
                        backgroundImage={course.header_image_url || ''}
                        name={course.name}
                        description={course.description || ''}
                        key={course.id}
                        selected={selected}
                        setSelectedCourses={setSelectedCourses}
                      />
                    );
                  })
                }
              </div>
            </>
        }
      </ModalContent>
    </ModalWindow>
  );
});

export default AddCoursesModal;
