import PropTypes from 'prop-types';
import React from 'react';
import ProfileImage from '../profile-image';

const UserInput = ({ user, size, children }) => (
  <div className="UserInput">
    <div className="UserInput__image">
      <ProfileImage size={size} user={user} />
    </div>

    <div className="UserInput__input">
      {children}
    </div>
  </div>
);

UserInput.propTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.number,
};

UserInput.defaultProps = {
  user: {},
  size: 40,
};

export default UserInput;
