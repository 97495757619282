import * as React from 'react';
import { withTranslation } from 'react-i18next';

import { Select } from '@common/components/form/inputs/select';
import * as Alert from '../../../../common/services/alert';
import Spinner from '../../../../common/components/spinner';
import Status from '../status';

class StatusSelector extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.renderValue = this.renderValue.bind(this);
  }

  static props;

  async handleChange({ value }) {
    const { onChange, validate, t } = this.props;

    if (value === this.props.value) return;

    this.setState({ loading: true });

    try {
      if (validate) await validate();

      await onChange(value);
      // do not add more logic here or you will break logic in places like
      // learning-path-status-selector.tsx
    } catch (err) {
      if (err.status_code === 422) {
        Alert.warning(t('learning:module_active_question_required'));
        throw err;
      }

      const errorText = err.detail || (err.text && t(err.text));
      if (errorText) return Alert.warning(errorText);

      Alert.warning(t('learning:could_not_to_publish'));
      throw err;
    } finally {
      this.setState({ loading: false });
    }
  }

  renderValue({ value, label }) {
    const { loading } = this.state;

    if (loading) return <Spinner centered />;

    return (
      <>
        <Status small published={value} />
        {label}
      </>
    );
  }

  render() {
    const { value, disabled, customOptions, t } = this.props;

    const baseOptions = [
      { value: true, label: t('common:status_live') },
      { value: false, label: t('common:status_draft') },
    ];

    const options = baseOptions.map((option, index) => {
      const customOptionData = customOptions?.[index];
      if (customOptionData) {
        return { ...option, ...customOptionData };
      }
      return option;
    });

    return (
      <Select
        clearable={false}
        className="StatusSelector"
        options={options}
        value={value}
        disabled={disabled}
        onChange={this.handleChange}
        valueRenderer={this.renderValue}
      />
    );
  }
}

export default withTranslation()(StatusSelector);
