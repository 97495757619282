import React, { useMemo } from 'react';
import { FormSettings } from '@modules/forms/containers/edit-form/form-settings';
import { InjectedFormProps } from 'redux-form';
import { FormPayload } from '@modules/forms/actions';
import { useFormValues } from '@common/hooks/form-hook';
import Spinner from '@common/components/spinner';

type FormData = FormPayload & {
  activate_status: boolean;
};

type Props = InjectedFormProps<FormData> & {
  selectedForm: any;
  meta: any;
  loading: boolean;
  onModeratorsSave?: () => void;
};

const ModeratorFormSettings = ({
  selectedForm,
  meta,
  loading,
  onModeratorsSave,
  ...props
}: Props) => {
  const values = useFormValues(props.form);

  const moderators = useMemo(() => {
    return selectedForm?.settings?.moderators?.map((moderator: any) => {
      const user = meta?.related?.users?.find((item: any) => item.id === moderator.user_id);
      return {
        ...user,
        ...moderator
      };
    }) || [];
  }, [meta, selectedForm]);

  if (loading) {
    return <Spinner centered />;
  }

  return (
    <FormSettings
      values={values}
      selectedForm={selectedForm}
      moderators={moderators}
      onModeratorsSave={onModeratorsSave}
    />
  );
};

export default ModeratorFormSettings;
