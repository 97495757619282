import React, { memo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import Container from '@common/components/container';
import { TopNavigationBar }
  from '@common/components/navigation-bar/top-navigation-bar/top-navigation-bar';
import LearningPaths
  from '@modules/learning/components/learning-paths/learning-paths';
import Courses from './courses';

const Academy = memo(() => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  return (
    <Container name="Academy">
      <TopNavigationBar
        title={t('learning:breadcrumb_academy')}
        learnMoreHref={t('learning:academy_learn_more')}
      />
      <Switch>
        <Route path={`${path}/paths/list`} exact>
          <LearningPaths />
        </Route>
        <Route path={`${path}/filter/:status`}>
          <Courses />
        </Route>
        <Route>
          <Courses />
        </Route>
      </Switch>
    </Container>
  );
});

export default Academy;
