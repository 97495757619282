import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Icon from '@common/components/icon';
import { bytesToSize } from '@utils/file';
import { usePageTracker } from '@hooks/usePageTracker';
import { DocumentIcon } from '../document-icon';
import { EEventNames, getExtension } from '@services/analytics';

import type { Document } from '../../types';

type Props = {
  item: Document;
  onClose: () => void;
};

const DocumentThumbnail = ({ item }: { item: Document }) => {
  if (item.is_folder) return <DocumentIcon item={item} />;

  if (item.attachment?.file_type === 'image') {
    return <img src={item.attachment.path!} alt={item.attachment.file_name!} />;
  }
  if (item.attachment?.file_type === 'video') {
    return <img src={item.attachment.thumb_path!} alt={item.attachment.file_name!} />;
  }

  return <DocumentIcon item={item} />;
};

export const DocumentDetail = ({ item, onClose }: Props) => {
  usePageTracker(EEventNames.VIEWED_DOCUMENT_DETAILS, {
    isFolder: item.is_folder,
    ...(!item.is_folder ? {
      fileType: item.attachment?.file_type || '',
      fileExtension: getExtension(item.attachment?.path || ''),
    } : {}),
  });

  const { t } = useTranslation();

  return (
    <div className="DocumentDetail">
      <div className="DocumentDetail__Header">
        {t('documents:details_and_activity')}
        <Icon
          type="close"
          onClick={onClose}
        />
      </div>
      <div className="DocumentDetail__Thumbnail">
        <DocumentThumbnail item={item} />
      </div>
      <div className="DocumentDetail__Content">
        <h4>{t('documents:details')}</h4>
        <label>{t('documents:label_name')}</label>
        <div className="DocumentDetail__ContentValue">
          <span>{item.name}</span>
          {item.is_favorited && <Icon type="star__filled" />}
        </div>
        {!item.is_folder && (
          <>
            <label>{t('documents:label_filesize')}</label>
            <div className="DocumentDetail__ContentValue">
              {item.attachment?.file_size ? bytesToSize(item.attachment.file_size) : '-'}
            </div>
          </>
        )}
        <hr />

        <h4>{t('documents:activity')}</h4>
        <label>{t('documents:label_owner')}</label>
        {/* @ts-expect-error */}
        <div className="DocumentDetail__ContentValue">{item.owner?.full_name}</div>
        <label>{t('documents:label_last_modified')}</label>
        <div className="DocumentDetail__ContentValue">{moment(item.updated_at).format('D MMM YYYY HH:mm')}</div>
        <label>{t('documents:label_created')}</label>
        <div className="DocumentDetail__ContentValue">{moment(item.created_at).format('D MMM YYYY HH:mm')}</div>

        {item.personal_root_user_id === null && (
          <>
            <hr />
            <h4>{t('documents:access')}</h4>
            <label>{t('documents:label_communities')}</label>
            <div className="DocumentDetail__AccessSettings">
              {item.access_settings?.networks?.length
                ? item.access_settings.networks.map(({ id, name }) => <span key={id}>{name}</span>)
                : <span>{t('documents:access_all_networks')}</span>}
            </div>
            <label>{t('documents:label_functions')}</label>
            <div className="DocumentDetail__AccessSettings">
              {item.access_settings?.functions?.length
                ? item.access_settings.functions.map(({ id, name }) => <span key={id}>{name}</span>)
                : <span>{t('documents:access_all_functions')}</span>}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
