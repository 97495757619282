import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';

let container: Element | null = null;
let lastValue = 0;
let pending = false;

function scroll(value: number) {
  lastValue += value;

  if (pending) return;

  pending = true;
  window.requestAnimationFrame(() => {
    if (!container) return;

    container.scrollTop += lastValue;

    pending = false;
    lastValue = 0;
  });
}

const ScrollArea = ({ position }: any) => {
  let lastUpdate = 0;

  useEffect(() => {
    container = document.getElementsByClassName('Content__Wrapper')?.[0];
  }, []);

  const [{ isDragging }, dropRef] = useDrop({
    accept: ['section', 'module_item'],
    hover: () => {
      const now = new Date().getTime();

      if (lastUpdate > 0) {
        const diff = (now - lastUpdate) / 3;

        scroll(position === 'top' ? diff * -1 : diff);
      }

      lastUpdate = now;
    },
    collect: (monitor) => ({
      isDragging: !!monitor.getItem(),
    }),
  });

  if (!isDragging) return null;

  return (
    <div
      ref={(ref) => {
        dropRef(ref);

        if (ref) {
          ref.addEventListener('dragleave', () => {
            lastUpdate = 0;
          });
        }
      }}
      className="DragnDrop__ScrollArea"
      style={{
        [position]: 0,
      }}
    />
  );
};

export default ScrollArea;
