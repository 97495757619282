import React, { LegacyRef, PropsWithChildren, useState } from 'react';

import Icon from '../icon';
import Badge, { BadgeProps } from '../badge';

type ToggleContentProps = PropsWithChildren<{
  title: JSX.Element;
  image?: JSX.Element;
  badge?: BadgeProps['status'];
  disabled?: boolean;
  action?: JSX.Element;
  handleRef?: LegacyRef<HTMLDivElement>;
  previewRef?: LegacyRef<HTMLDivElement>;
  className?: string;
}>;

// TODO: better name
// toggle is only used for course sections
// dragging is always used
const ToggleContent = ({
  children, title, image, badge,
  disabled, action, handleRef, previewRef, className,
}: ToggleContentProps) => {
  const [extended, setExtended] = useState(!disabled);

  const classNames = ['ToggleContent'];
  if (extended) classNames.push('ToggleContent--extended');
  if (className) classNames.push(className);

  return (
    <div className={classNames.join(' ')}>
      <div className="ToggleContent__Header" ref={previewRef}>
        {!disabled && (
          <div
            className="ToggleContent__Header__Toggle"
            onClick={() => setExtended(!extended)}
          >
            <Icon type={extended ? 'expand_less' : 'expand_more'} />
          </div>
        )}
        {handleRef && (
          <div className="ToggleContent__Header__Drag" ref={handleRef}>
            <Icon type="drag_handle" />
          </div>
        )}
        {badge && <Badge status={badge} />}
        <div className="ToggleContent__Header__Info">
          {image}
          {title}
        </div>
        {action}
      </div>
      {extended && (
        <div className="ToggleContent__Container">
          {children}
        </div>
      )}
    </div>
  );
};

export default ToggleContent;
