import * as R from 'ramda';
import { combineReducers } from 'redux';
import {
  RECEIVE_PROFILE, UPDATE_PROFILE, CORE_ACCEPT_DOCUMENT, SET_USER_STATUS, UNSET_USER_STATUS
} from '../actions';
import {
  ORGANISATION_CREATE_CHANNEL,
  ORGANISATION_DELETE_CHANNEL,
  ORGANISATION_UPDATE_CHANNEL,
  ORGANISATION_INITIALISE,
} from '../../organisation/actions';
import { ORGANISATION_ADD_NETWORKS } from '../../admin/actions';
import { NETWORK_INITIALISE } from '../../network/actions';
import { AUTHENTICATION_LOGIN, LOGOUT_USER } from '../../authentication/actions';
import { SOCIAL_HIDE_CHANNEL, SOCIAL_MUTE_CHANNEL } from '../../social/actions';
import { ETimelineTypes } from '../../social/definitions';

const indexById = R.indexBy(R.prop('id'));

const user = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
    case RECEIVE_PROFILE:
    case UPDATE_PROFILE:
      const computedUser = R.omit(['membership', 'permissions'], {
        ...action.user,
        time_zone: action.userTimeZoneToSwitch || user.time_zone
      });
      return computedUser;
    default:
      return state;
  }
};

const isLoggedIn = (state = null, action) => {
  switch (action.type) {
    case AUTHENTICATION_LOGIN:
      return true;
    case LOGOUT_USER:
      return false;
    default:
      return state;
  }
};

// Store what organisations the user is part of (used during authentication)
const organisations = (state = [], action) => {
  switch (action.type) {
    case 'auth/RECEIVE_ORGANISATIONS':
      return action.items;
    default:
      return state;
  }
};

const channels = (state = [], action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return R.pluck('id', action.channels);
    default: return state;
  }
};

const lastUpdated = (state = null, action) => {
  switch (action.type) {
    case RECEIVE_PROFILE:
    case UPDATE_PROFILE:
      return new Date().getTime();
    default:
      return state;
  }
};

const initialised = (state = false, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return true;
    default:
      return state;
  }
};

const accepted = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return R.pick(['accepted_privacy', 'accepted_terms'], action.user);
    case CORE_ACCEPT_DOCUMENT:
      return { ...state, ...action.accepted };
    default:
      return state;
  }
};

// Memberships

const organisationMembership = (state = null, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return action.user.membership;
    case SET_USER_STATUS:
      return {
        ...state,
        status: {
          emoji: action.emoji,
          text: action.text,
          expires_at: action.expires_at
        }
      };
    case UNSET_USER_STATUS:
      return { ...state, status: null };
    case UPDATE_PROFILE:
      return {
        ...state,
        ...action.user?.membership
      };
    default:
      return state;
  }
};

const addMemberships = (items) => items
  .filter((item) => !!item.membership)
  .reduce((acc, item) => ({
    ...acc,
    [item.id]: item.membership,
  }), {});

const networkMemberships = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return addMemberships(action.networks);
    case ORGANISATION_ADD_NETWORKS:
      return indexById(action.user.scopes.networks);
    case SOCIAL_MUTE_CHANNEL:
      if (action.channelType !== ETimelineTypes.NETWORK) return state;

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          enabled_notifications: action.enabledNotifications,
        },
      };
    case 'admin/DELETE_NETWORK':
      return R.omit([action.id], state);
    default:
      return state;
  }
};

const teamMembershipsReducer = (state = {}, action) => {
  switch (action.type) {
    case NETWORK_INITIALISE:
      return addMemberships(action.teams);
    case SOCIAL_MUTE_CHANNEL:
      if (action.channelType !== ETimelineTypes.TEAM) return state;

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          enabled_notifications: action.enabledNotifications,
        },
      };
    default:
      return state;
  }
};

const functionMembershipsReducer = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return addMemberships(action.functions);
    default:
      return state;
  }
};

const channelMembershipsReducer = (state = {}, action) => {
  switch (action.type) {
    case ORGANISATION_INITIALISE:
      return addMemberships(action.channels);
    case SOCIAL_HIDE_CHANNEL:
      if (action.channelType !== ETimelineTypes.CHANNEL) return state;

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          hidden_from_feed: action.hidden,
        },
      };
    case SOCIAL_MUTE_CHANNEL:
      if (action.channelType !== ETimelineTypes.CHANNEL) return state;

      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          enabled_notifications: action.enabledNotifications,
        },
      };
    case ORGANISATION_DELETE_CHANNEL:
      return R.omit([action.id], state);
    case ORGANISATION_CREATE_CHANNEL:
    case ORGANISATION_UPDATE_CHANNEL:
      return {
        ...state,
        [action.channel.id]: action.channel,
      };
    default:
      return state;
  }
};

const memberships = combineReducers({
  organisation: organisationMembership,
  networks: networkMemberships,
  teams: teamMembershipsReducer,
  functions: functionMembershipsReducer,
  channels: channelMembershipsReducer,
});

const membershipsReducer = (state, action) => {
  switch (action.type) {
    case 'auth/SELECT_ORGANISATION':
      return memberships(undefined, action);
    default:
      return memberships(state, action);
  }
};

export default combineReducers({
  initialised,
  user,
  isLoggedIn,
  accepted,
  organisations,
  channels,
  lastUpdated,
  memberships: membershipsReducer,
});
