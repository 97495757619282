import React, { useEffect, useState } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';

import Tabs from '@common/components/tabs';
import { Modal } from '@common/components/modal';
import { Button } from '@common/components/button';
import * as AlertService from '@common/services/alert';
import { useThunkDispatch } from '@common/hooks';
import { ProfileGeneralSettings } from './profile-general-settings';
import { ProfileSecuritySettings } from './profile-security-settings';
import { ProfilePreferencesSettings } from './profile-preferences-settings';
import { ProfileNotificationSettings } from './profile-notification-settings';
import { UserProfileTypes } from '../../definitions';
import { isEqualWithFlatDiff } from '@utils/isEqualWithFlatDiff';
import { PhoneNumberInput } from '@common/components/form';
import updateProfile from '../../actions/update-profile';
import { updateUser as updateOrganisationUser } from '../../../admin/actions';
import updateNetworkUser from '../../../network/actions/update-user';

import type { ExtendedUser, LoggedUser } from '@common/types/objects';
import { useHistory, useRouteMatch } from 'react-router';

import validateProfileSecurity
  from '../validators/validate-profile-security';

type FormState = ExtendedUser & Partial<LoggedUser> & {
  password_new?: string;
  password_repeat?: string;
  active_until: Moment;
  active_from: Moment;
  notifications: {
    enabled_flexchange_notifications: boolean;
    enabled_weekly_update: boolean;
    enabled_access_request_emails: boolean;
  };
};

export const parseStateToPayload = ({
  language,
  translation_language,
  password_new,
  password_repeat,
  scopes: { organisation },
  profile_img,
  ...state
}: FormState) => {
  const payload = {
    ...state,
    password: password_new,
    language_locale: language?.locale,
    translation_language_locale: translation_language?.locale,
    active_until: organisation?.active_until,
    active_from: organisation?.active_from,
    external_id: organisation?.external_id,
  };
  if (typeof profile_img === 'string') {
    // @ts-expect-error
    payload.profile_img = profile_img?.split('?')[0]; // split fixes PD-8165
  }
  return payload;
};

const profileFormConnector = reduxForm<FormState, ProfileFormOwnProps, boolean | string>({
  form: 'profile-general',
  validate: validateProfileSecurity,
});

type ProfileFormOwnProps = {
  user: ExtendedUser;
  type: UserProfileTypes;
  onClose: () => void;
};

type ProfileReduxFormProps = InjectedFormProps<FormState, ProfileFormOwnProps, boolean | string>;
type ProfileFormProps = ProfileReduxFormProps & ProfileFormOwnProps;

const userToFormState = (user: ExtendedUser & LoggedUser) => ({
  ...user,
  // @ts-expect-error
  phone: PhoneNumberInput.initialize(user),
  settings: {
    show_phone_num: user.settings?.privacy.show_phone_num,
    show_date_of_birth: user.settings?.privacy.show_date_of_birth,
    show_last_active: user.settings?.privacy.show_last_active,
    allow_chat_invites: user.settings?.privacy.allow_chat_invites,
  },
  notifications: {
    enabled_flexchange_notifications: user.settings?.notifications.enabled_flexchange_notifications,
    enabled_weekly_update: user.settings?.notifications.enabled_weekly_update,
    enabled_access_request_emails: user.settings?.notifications.enabled_access_request_emails,
  },
});

export const UnconnectedProfileForm = ({
  user,
  type,
  handleSubmit,
  initialize,
  submitting,
  valid,
  onClose,
}: ProfileFormProps) => {
  const { t } = useTranslation();
  const thunkDispatch = useThunkDispatch();
  const match = useRouteMatch<{ tab?: string, networkId?: string }>();
  const history = useHistory();

  useEffect(() => {
    // @ts-expect-error
    initialize(userToFormState(user));
  }, []);

  const [updatedProfilePicture, setUpdatedProfilePicture] = useState(null);

  const submitFunction = async (values: FormState) => {
    try {
      const [isEqual, diff] = isEqualWithFlatDiff(
        parseStateToPayload(values),
        // @ts-expect-error
        parseStateToPayload(userToFormState(user))
      );

      // @ts-expect-error
      const payload: Record<string, unknown> = diff;

      if (diff?.notifications) {
        payload.settings = {
          ...diff.settings,
          notifications: diff.notifications
        };
      }

      payload.active_until = typeof diff?.active_until !== 'string' ?
        // @ts-expect-error
        diff?.active_until?.format('YYYY-MM-DD') :
        null;

      payload.active_from = typeof diff?.active_from !== 'string' ?
        // @ts-expect-error
        diff?.active_from?.format('YYYY-MM-DD') :
        null;



      if ((!isEqual && diff) || updatedProfilePicture) {
        payload.profile_img = updatedProfilePicture;
        if (type === UserProfileTypes.OWN) {
          await thunkDispatch(updateProfile(payload));
        } else {
          await thunkDispatch(match.params.networkId
            ? updateNetworkUser(user.id, payload)
            : updateOrganisationUser(user.id, payload));
        }
        AlertService.success(t('core:profile_form_success'));
      }
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('core:profile_form_warning'),
          error: t('core:profile_form_error'),
        });
      } else {
        throw e;
      }
    }
  };

  const tabs = ['', 'security', 'preferences', 'notifications'];

  return (
    <Modal
      list
      show
      title={type === UserProfileTypes.OWN ? t('core:profile_settings') : user.full_name}
      size="large"
      className="ProfileFormModal"
      onExited={onClose}
      wrapper={Modal.FormWrapper}
      wrapperProps={{ onSubmit: handleSubmit(submitFunction) }}
      content={(
        <Tabs
          vertical
          initialTabIndex={match.params.tab ? tabs.indexOf(match.params.tab) : 0}
          onChange={(index) => history.replace(`${match.path.replace('/:tab', '')}/${tabs[index]}`)}
          className="ProfileFormModal__Tabs"
          variant="background"
        >
          <Tabs.Item icon="account_circle" title={t('core:profile_form_tab_general')}>
            <ProfileGeneralSettings
              user={user}
              type={type}
              setProfilePicture={setUpdatedProfilePicture}
              updatedProfilePicture={updatedProfilePicture}
            />
          </Tabs.Item>
          {type === UserProfileTypes.OWN && (
            <Tabs.Item icon="shield" title={t('core:profile_form_tab_security')}>
              <ProfileSecuritySettings user={user} />
            </Tabs.Item>
          )}
          {type === UserProfileTypes.OWN && (
            <Tabs.Item icon="settings" title={t('core:profile_form_tab_preferences')}>
              <ProfilePreferencesSettings />
            </Tabs.Item>
          )}
          {type === UserProfileTypes.OWN && (
            <Tabs.Item icon="notifications" title={t('core:profile_form_tab_notifications')}>
              <ProfileNotificationSettings />
            </Tabs.Item>
          )}
        </Tabs>
      )}
      footer={(
        <Button
          type="primary"
          buttonType="submit"
          isLoading={submitting}
          disabled={!valid}
        >
          { t('common:save_changes') }
        </Button>
      )}
    />
  );
};

export const ProfileForm = profileFormConnector(UnconnectedProfileForm);
