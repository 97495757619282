import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { FormState, InjectedFormProps, reduxForm } from 'redux-form';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import moment, { Moment } from 'moment';
import * as R from 'ramda';

import { TopNavigationBar } from '@common/components/navigation-bar/top-navigation-bar';
import * as alert from '@common/services/alert';
import Container from '@common/components/container';
import { Button } from '@common/components/button';
import Modal from '@common/components/modal';
import Alert from '@common/components/alert';
import { AudienceInput } from '@common/components/form/audience';
import { Row, Group, CheckboxInput } from '@common/components/form';
import Collapsible from '@common/components/collapsible';
import CoursePreview from '../../components/course-preview';
import CourseDeadline from '../../components/course-deadline';
import ActivateCourseButton from './activate-course-button';

import updateCourse from '../../actions/update-course';
import fetchCourseAction from '../../actions/fetch-course';
import * as coursesSelector from '../../selectors/courses';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import { dateAndTimeToStr } from '@common/utils/date';
import {
  EPredicateFields, EPredicateOperators, ESearchPredicateTypes,
  Predicate, predicatesToPayload,
} from '@common/utils/predicates';

import { Course } from '@modules/learning/types/objects';
import { Network, OrganisationFunction } from '@common/types/objects';
import { Dispatch, StoreState } from '@common/types/store';

type FormData = {
  has_deadline?: boolean;
  deadline_after_days_invited?: string;
  should_notify: boolean;
  deadline?: {
    date: Moment;
    time: string;
  };
  settings: {
    visibility: {
      predicate_type: string;
      predicates: Object[];
    };
  }
};

type Payload = {
  deadline: string | null;
  deadline_after_days_invited?: string | null;
  notification_type: number;
  visibility: {
    predicate_type: string;
    predicates: {
      attribute: EPredicateFields | 'answers.value';
      comparison: EPredicateOperators;
      value: unknown;
    }[];
  }
};

type Props = {
  course: Course;
  data: FormState;
  networks: Network[];
  functions: OrganisationFunction[];
};

type OwnProps = InjectedFormProps<FormData> & ConnectedProps<typeof reduxConnector> & Props;

const LearningPublishAcademyContainer = ({
  course, networks, functions, data,
  submitting, initialize, handleSubmit, fetchCourse,
}: OwnProps) => {
  const history = useHistory();
  const params = useParams<{ courseId: string }>();
  const { t } = useTranslation();
  const [hasPublished, setHasPublished] = useState(false);
  const [deadlineDynamic, setDeadlineDynamic] = useState(false);

  useEffect(() => {
    if (!course) fetchCourse(params.courseId);
  }, []);

  useEffect(() => {
    if (!course) return;
    const deadline = moment(course.settings.deadline || undefined);
    const deadline_after_days_invited = course.settings.deadline_after_days_invited || '30';
    setDeadlineDynamic(!!course.settings.deadline_after_days_invited);
    if (!course.settings.deadline) deadline.set({ hour: 9, minute: 0 });
    initialize({
      settings: {
        visibility: {
          predicate_type: course.settings.visibility.predicate_type,
          predicates: course.settings.visibility.predicates.map((predicate, i) => R.assoc('id', i, predicate)),
        },
      },
      has_deadline: !!course.settings.deadline || !!course.settings.deadline_after_days_invited,
      deadline: deadline && {
        date: deadline,
        time: deadline.format('HH:mm'),
      },
      deadline_after_days_invited,
      should_notify: course.notification_type === 1,
    });
  }, [course]);

  const onHandleSubmit = async (values: FormData, dispatch: Dispatch, publishModules = false) => {
    try {
      const settings: Payload = {
        deadline: null,
        deadline_after_days_invited: null,
        notification_type: values.should_notify ? 1 : 0,
        visibility: {
          predicate_type: values.settings.visibility.predicate_type,
          predicates: predicatesToPayload(values.settings.visibility.predicates as Predicate[]),
        },
      };

      if (values.has_deadline) {
        if (deadlineDynamic) {
          settings.deadline_after_days_invited = values.deadline_after_days_invited;
        } else {
          settings.deadline = dateAndTimeToStr(values.deadline?.time, values.deadline?.date);
        }
      }

      const payload = {
        published: true,
        publish_all_modules: !!publishModules,
        settings,
      };

      await dispatch(updateCourse(course.id, payload));
      setHasPublished(true);
    } catch (error: any) {
      if (error.detail) {
        return alert.error(error.detail);
      }
      return alert.forStatus(error.status_code, {
        warning: t('learning:publish_academy_warning_publishing'),
        error: t('learning:publish_academy_error_publishing'),
        [422]: t('learning:publish_academy_warning_publishing_check_content'), // eslint-disable-line no-useless-computed-key
      });
    }
  };

  const onPopupConfirm = (publishModules: boolean) => {
    return handleSubmit((values, dispatch) => {
      return onHandleSubmit(values, dispatch, publishModules);
    })();
  };

  const formValues = (data && data.values) || { settings: { visiblity: { predicates: [] } } };

  return (
    <Container name="Category">
      {hasPublished && (
        <Modal
          disableRootClose
          className="CoursePublished"
          content={(
            <>
              <img src="/static/images/course-active.svg" alt="Actief" />
              <h2><Trans i18nKey="learning:publish_academy_published_title" /></h2>
              <Trans i18nKey="learning:publish_academy_published_description" />
              <br />
              <br />
              <Button type="primary" size="large" onClick={() => history.replace('/admin/learning/academy')}>
                <Trans i18nKey="learning:publish_academy_back_to_academy" />
              </Button>
            </>
          )}
        />
      )}
      <TopNavigationBar
        breadcrumbs={[
          { name: t('learning:breadcrumb_learning_environment') },
          { name: t('learning:breadcrumb_academy'), path: '/admin/learning/academy' },
          course && { name: course.name, path: `/admin/learning/courses/${course.id}` },
        ]}
        title={t('learning:publish_academy_publish_for_academy')}
        action={(
          <Button onClick={() => history.goBack()} size="large"><Trans i18nKey="learning:publish_academy_back" /></Button>
        )}
      />
      <Container.Content>
        <form onSubmit={handleSubmit((values, dispatch) => onHandleSubmit(values, dispatch))}>
          <Collapsible.Group>
            <Collapsible
              title={t('learning:publish_academy_select_target_audience')}
              completed={formValues.settings.visibility.predicates.length > 0 ? true : undefined}
            >
              <Collapsible.Column>
                <Row>
                  <Group>
                    <AudienceInput
                      name="settings.visibility"
                      enabledFilters={[
                        EPredicateFields.LANGUAGE,
                        EPredicateFields.NETWORK,
                        EPredicateFields.USER,
                        EPredicateFields.FUNCTION,
                      ]}
                    />
                  </Group>
                </Row>
              </Collapsible.Column>
            </Collapsible>
            <Collapsible
              title={t('learning:publish_academy_select_deadline')}
              completed={formValues.has_deadline ? true : undefined}
            >
              <Collapsible.Column flex={0.25} />
              <Collapsible.Column flex={1}>
                <Alert type="info">
                  <Trans i18nKey="learning:publish_academy_deadline_description" />
                </Alert>
                <Row>
                  <CheckboxInput
                    name="has_deadline"
                    label={t('learning:publish_academy_label_deadline')}
                  />
                </Row>
                {formValues.has_deadline && (
                  <CourseDeadline
                    deadlineDynamic={deadlineDynamic}
                    setDeadlineDynamic={(value) => setDeadlineDynamic(value)}
                  />
                )}
              </Collapsible.Column>
              <Collapsible.Column flex={0.25} />
            </Collapsible>
            <Collapsible
              title={t('learning:publish_academy_select_notifications')}
              completed={formValues.should_notify ? true : undefined}
            >
              <Collapsible.Column>
                <Alert type="info">
                  <Trans i18nKey="learning:publish_academy_notifications_description" />
                </Alert>
                <Row>
                  <CheckboxInput
                    name="should_notify"
                    label={t('learning:publish_academy_send_notifications')}
                  />
                </Row>
              </Collapsible.Column>
            </Collapsible>
            <Collapsible title={t('learning:publish_academy_check_and_publish')} completed={false}>
              <Collapsible.Column flex={1} />
              <Collapsible.Column flex={1}>
                <>
                  {
                    course
                    && (
                      <CoursePreview
                        course={course}
                        formValues={formValues}
                        networks={networks}
                        functions={functions}
                        deadlineDynamic={deadlineDynamic}
                      />
                    )
                  }
                  <hr />
                  <ActivateCourseButton
                    isLoading={submitting}
                    courseId={course?.id}
                    orgId={course?.organisation_id}
                    onPopupConfirm={onPopupConfirm}
                  >
                    <Trans i18nKey="learning:publish_academy_activate" />
                  </ActivateCourseButton>
                </>
              </Collapsible.Column>
              <Collapsible.Column flex={1} />
            </Collapsible>
          </Collapsible.Group>
        </form>
      </Container.Content>
    </Container>
  );
};

const mapStateToProps = (state: StoreState, props: any): Props => ({
  course: coursesSelector.item(state, props.match.params.courseId),
  data: state.form[props.form],
  networks: organisationSelector.networks(state),
  functions: organisationSelector.functions(state),
});
const mapDispatchToProps = {
  fetchCourse: fetchCourseAction,
};
const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

export default withTranslation()(reduxForm({
  form: 'course-publish-academy',
  initialValues: {
    settings: {
      visibility: {
        predicate_type: ESearchPredicateTypes.MATCH_ANY,
        predicates: [],
      },
    },
    has_deadline: false,
    deadline: {
      date: null,
      time: null,
    },
    should_notify: false,
  },
})(reduxConnector(LearningPublishAcademyContainer)));
