import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@common/components/button';
import Modal from '@common/components/modal';
import { MultiInput } from '@common/components/predicates-filter';
import { ProfileImagesGroup } from '@common/components/profile-image';
import SelectToggle from '@common/components/select-toggle';
import { useIsAvailableInPlanPackage } from '@common/hooks/use-is-available-in-plan-package';
import { AlertService } from '@common/services/alert';
import { Api } from '@common/services/api';
import ModeratorRow from '@modules/admin/forms/channel/moderator-row';

import Spinner from '@common/components/spinner';
import { EPermissions, EPlanPackageConfig } from '@common/definitions';
import { ApiResponse } from '@common/services/api/types';
import { AdminListItem } from '@common/types/objects';
import { Form } from '@modules/forms/types';

// TODO this function is not clear, we need to simplify this logic, maybe solve the
// fact that the moderator permissions are expressed outside of the permissions framework
const canUpdateFormContent = (admins: AdminListItem[], userId: string, form: Form) => {
  const moderatorAdminRoles = admins.find((admin: AdminListItem) => {
    return admin.id === userId;
  });

  if (!moderatorAdminRoles) return false; // the moderator is not an admin

  const permissions = moderatorAdminRoles.roles.map((role) => role.permissions).flat();
  return (
    permissions.includes(EPermissions.ORGANISATION_FORMS_UPDATE) ||
    (
      permissions.includes(EPermissions.ORGANISATION_CREATOR_FORMS_UPDATE) &&
      typeof userId === 'string' && form?.created_by?.id === userId
    )
  );
};

const ModalModeratorRow = (props: any) => {
  const {
    setUsers, item, admins, canSetEditContent, showUpgradeModal, form
  } = props;
  const { t } = useTranslation();
  const userId = item?.user_id;

  const onChange = useCallback((flag: boolean, field?: string) => {
    setUsers((users: any[]) => {
      return users.map((user) => {
        if (user.user_id === userId) {
          return {
            ...user,
            [field!]: flag,
          };
        }
        return user;
      });
    });
  }, [userId]);

  const editContentDisabled = useMemo(() => {
    if (!userId || !admins) return true;
    return canUpdateFormContent(admins, userId, form);
  }, [userId, admins, form]);

  return (
    <ModeratorRow {...props}>
      {
        admins ?
          (
            <SelectToggle
              name="can_edit_content"
              label={t('forms:edit_content')}
              textOn={t('forms:yes')}
              textOff={t('forms:no')}
              onChange={canSetEditContent ? onChange : showUpgradeModal}
              value={item?.can_edit_content}
              disabled={editContentDisabled}
            />
          ) :
          (
            <Spinner size="large" />
          )
      }

      <SelectToggle
        name="enabled_notifications"
        label={t('forms:notifications')}
        textOn={t('forms:on')}
        textOff={t('forms:off')}
        iconOn={t('notifications')}
        iconOff={t('notifications_off')}
        onChange={onChange}
        value={item?.enabled_notifications}
      />
    </ModeratorRow>
  );
};

const ModalContent = ({
  organisationId,
  users,
  setUsers,
  moderators,
  form,
  canSetEditContent,
  showUpgradeModal,
}: any) => {
  const { t } = useTranslation();

  const getOptions = async (search: string) => {
    if (!search) return [];

    const query = Api.utils.toQuery({ q: search, limit: 5 });
    const url = `/v2/organisations/${organisationId}/users?${query}`;
    const res = await Api.get(url);
    return res.data;
  };

  const [admins, setAdmins] = useState<null | AdminListItem[]>(null);
  useEffect(() => {
    const url = `/v3/organisations/${organisationId}/admins`;
    Api.get<ApiResponse<AdminListItem[]>>(url).then((response) => {
      setAdmins(response.data);
    });
  }, [organisationId, setAdmins]);

  // cancel button press support, we should reset the state when that happens
  // this whole file needs to be refactored heavily (and so does connect-submissions.tsx)
  // we need a different/new Modal that is easy to use with local state
  useEffect(() => {
    return () => {
      setUsers(moderators);
    };
  }, [setUsers, moderators]);

  const onChange = useCallback((updatedUsers: any) => {
    if (!admins) return;

    const formattedUsers = updatedUsers.map((user: any) => {
      const alreadyFormatted = (
        typeof user.enabled_notifications === 'boolean' &&
        typeof user.user_id === 'string'
      );
      if (alreadyFormatted) {
        return user;
      }
      return { // initial user values
        ...user,
        user_id: user.id,
        enabled_notifications: false,
        can_edit_content: canUpdateFormContent(admins, user.id, form)
      };
    });
    setUsers(formattedUsers);
  }, [setUsers, admins, form]);

  return (
    <MultiInput
      className="ModeratorsModal"
      // @ts-expect-error
      getOptions={getOptions}
      valueKey="id"
      labelKey="full_name"
      searchPlaceholder={t('forms:enter_moderator_name')}
      renderRow={ModalModeratorRow}
      form={form}
      onChange={onChange}
      value={users}
      setUsers={setUsers}
      admins={admins}
      canSetEditContent={canSetEditContent}
      showUpgradeModal={showUpgradeModal}
      disabled={!admins}
    />
  );
};

const formatUser = (user: any) => {
  return {
    userId: user.user_id,
    enabledNotifications: user.enabled_notifications,
    canEditContent: user.can_edit_content,
  };
};

export const ModeratorsModalButton = ({
  organisationId, formId, moderators, onBeforeSaveEnd, form
}: any) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState(moderators);

  const {
    isAvailable: canSetEditContent, showUpgradeModal,
  } = useIsAvailableInPlanPackage(EPlanPackageConfig.FORMS_ADVANCED_PERMISSIONS);

  // when forms/MODERATORS_UPDATE is dispatched in onSave (onBeforeSaveEnd)
  // we need to listen for that
  // change and update the form. This part needs to be refactored once we have
  // a modal that works properly with local state
  useEffect(() => {
    setUsers(moderators);
  }, [moderators, setUsers]);

  const footer = (handleHide: Function) => {
    const onSave = async () => {
      const add = users.filter((user: any) => {
        const exists = moderators.find((mod: any) => mod.user_id === user.user_id);
        return !exists;
      }).map(formatUser);
      const remove = moderators.filter((mod: any) => {
        const exists = users.find((user: any) => mod.user_id === user.user_id);
        return !exists;
      }).map((mod: any) => mod.user_id);

      const update = users.filter((user: any) => {
        const mod = moderators.find((moderator: any) => {
          return moderator.user_id === user.user_id;
        });
        return !!mod && (
          mod.enabled_notifications !== user.enabled_notifications ||
          mod.can_edit_content !== user.can_edit_content
        );
      }).map(formatUser);

      if (add.length <= 0 && remove.length <= 0 && update.length <= 0) {
        return handleHide();
      }

      try {
        await Api.post(
          `/v1/organisations/${organisationId}/forms/${formId}/moderators`,
          { moderators: { add, remove, update } }
        );
        await onBeforeSaveEnd();
        const count = add.length + remove.length;
        const msg = t('forms:moderator_saved', { count });
        AlertService.success(msg);
        handleHide();
      } catch (error: any) {
        console.log('moderators update error', error);
        AlertService.forStatus(error.status_code, {
          warning: t('forms:moderator_save_warning'),
          error: t('forms:moderator_save_error'),
        });
      }
    };

    return (
      <Button type="primary" onClick={onSave}>
        {t('forms:save_and_close')}
      </Button>
    );
  };

  return (
    <Modal
      list
      className="Form"
      title={t('forms:add_moderators')}
      size="large"
      content={(
        <ModalContent
          organisationId={organisationId}
          formId={formId}
          form={form}
          users={users}
          setUsers={setUsers}
          moderators={moderators}
          canSetEditContent={canSetEditContent}
          showUpgradeModal={showUpgradeModal}
        />
      )}
      footer={footer}
    >
      <div className="formSettingsModal pointer">
        <span>
          <Button icon="add">
            {t('forms:add_moderators')}
          </Button>
        </span>
        <ProfileImagesGroup users={moderators} size={34} />
        <p className="underline counter">
          {t('forms:moderators_count', { count: moderators.length })}
        </p>
      </div>
    </Modal>
  );
};
