import React from 'react';
import { SelectInput } from '@common/components/form/inputs/select';
import { useTranslation } from 'react-i18next';

export const FormStatusInput = () => {
  const { t } = useTranslation();

  return (
    <SelectInput
      clearable={false}
      name="status"
      className="FormStatusSelector"
      options={[{
        value: 'draft',
        label: t('common:status_draft'),
      }, {
        value: 'live',
        label: t('common:status_published'),
      }]}
    />
  );
};
