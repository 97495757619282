import { EStatus } from '@common/definitions';
import { Course } from '../types/objects';

const getStatus = (item: Course) => {
  if (!item) return EStatus.DRAFT;
  if (item.archived) return EStatus.ARCHIVED;
  return item.published ? EStatus.LIVE : EStatus.DRAFT;
};

export default getStatus;
