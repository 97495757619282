import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Overview, OverviewContent, OverviewSection } from '@common/components/overview';
import { Container } from '@common/components/container';
import { Group, Row } from '@common/components/form';
import { ToggleInput } from '@common/components/form/inputs/toggle';
import Checkbox from '@common/components/form/inputs/checkbox';
import { AudienceInput } from '@common/components/form/audience';
import Alert from '@common/components/alert';
import Tooltip from '@common/components/tooltip';
import { Icon } from '@common/components/icon';
import { StatusesModal } from './statuses';
import { ModeratorsModalButton } from './moderators';
import { ConnectToEmailModalButton } from './connect-submissions';

import { EPlanPackageConfig, EPredicateFields } from '@common/definitions';
import { Api } from '@common/services/api';
import { usePlanPackageAudienceHandlers } from '@common/hooks/use-plan-package-audience-handlers';

type FormSettingsProps = any & {
  onModeratorsSave?: () => void;
};

export const FormSettings = (props: FormSettingsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const planPackageAudienceHandlers = usePlanPackageAudienceHandlers({
    packageIdAddFilters: EPlanPackageConfig.FORMS_AUDIENCE_SELECTOR,
  });

  const { values, moderators, selectedForm } = props;
  const { id, organisation_id, settings } = selectedForm;

  const onBeforeModeratorsSaveEnd = useCallback(async () => {
    const url = `/v1/organisations/${organisation_id}/forms/${id}`;
    const response = await Api.get(url);
    dispatch({
      type: 'forms/UPDATE_MODERATORS',
      response
    });
    props?.onModeratorsSave?.();
  }, [organisation_id, id, dispatch]);

  return (
    <Container.Content>
      <Overview>
        <OverviewContent>
          <OverviewSection>
            <h3>{t('forms:label_status')}</h3>
            <Row>
              <Alert>{t('forms:form_status_enable_alert')}</Alert>
            </Row>
            <Row>
              <Group>
                <ToggleInput
                  name="activate_status"
                  label={t('forms:form_label_enable_status')}
                />
              </Group>
            </Row>
            {values.activate_status && (
              <>
                <hr />
                <Row>
                  <Group>
                    <Checkbox
                      name="settings.show_status_to_users"
                      label={t('forms:form_label_show_status')}
                    />
                  </Group>
                </Row>
                {values.settings.show_status_to_users && (
                  <Row>
                    <Group>
                      <Checkbox
                        name="settings.notify_user_of_status_updates"
                        label={t('forms:form_label_notify_users')}
                      />
                    </Group>
                  </Row>
                )}
                <StatusesModal
                  rows={selectedForm.response_statuses}
                  organisationId={organisation_id}
                  formId={id}
                />
              </>
            )}
          </OverviewSection>
          <OverviewSection>
            <h3 className="inline-block">{t('forms:connect_submissions')}</h3>
            <Tooltip placement="right" title={t('forms:connections_tooltip')}>
              <Icon type="info" size="xsmall" className="Icon--titleIcon" />
            </Tooltip>

            <ConnectToEmailModalButton
              organisationId={organisation_id}
              formId={id}
              rows={settings?.connections?.email || []}
            />
          </OverviewSection>
          <OverviewSection>
            <h3 className="inline-block">
              {t('organisation:forms_channel_settings_moderators')}
            </h3>
            <Tooltip placement="right" title={t('forms:moderators_tooltip')}>
              <Icon type="info" size="xsmall" className="Icon--titleIcon" />
            </Tooltip>

            <ModeratorsModalButton
              organisationId={organisation_id}
              formId={id}
              form={selectedForm}
              onBeforeSaveEnd={onBeforeModeratorsSaveEnd}
              moderators={moderators || []}
            />
          </OverviewSection>
          <OverviewSection>
            <h3>{t('forms:label_audience')}</h3>
            <AudienceInput
              hideLabel
              name="audience"
              organisationId="180"
              enabledFilters={[
                EPredicateFields.NETWORK,
                EPredicateFields.FUNCTION,
                EPredicateFields.LANGUAGE,
                EPredicateFields.USER,
                EPredicateFields.DAYS_IN_SERVICE,
                EPredicateFields.DAYS_BEFORE_OUT_OF_SERVICE,
              ]}
              {...planPackageAudienceHandlers}
            />
          </OverviewSection>
        </OverviewContent>
      </Overview>
    </Container.Content>
  );
};
