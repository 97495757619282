import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import * as Alert from '@common/services/alert';
import { Overview, OverviewContent } from '@common/components/overview';
import { Group, Row } from '@common/components/form';
import { Icon } from '@common/components/icon';
import Label from '@common/components/form/label';
import CourseAudience from '@modules/learning/forms/course-audience';
import CourseDeadline from '@modules/learning/forms/course-deadline';
import CoursePhasing from '@modules/learning/forms/course-phasing';
import AudienceBadge from '@common/components/form/audience/audience-badge';
import Badge from '@common/components/badge';
import { Toggle } from '@common/components/form/inputs/toggle';

import updateCourse from '../../actions/update-course';
import getCourseDeadline from '@modules/learning/utils/get-course-deadline';
import getCoursePhasing from '@modules/learning/utils/get-course-phasing';
import { Course } from '@modules/learning/types/objects';
import { ECourseTypes } from '@modules/learning/definitions';

import './course-settings.scss';

type CourseSettingsProps = {
  course: Course;
};

const CourseSettings = ({ course }: CourseSettingsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { predicates, predicate_type } = course.settings.visibility;

  const onSaveNotification = async (value: boolean) => {
    setLoading(true);
    await dispatch(
      updateCourse(course.id, {
        settings: {
          notification_type: value ? 1 : 0,
        },
    }));
    if (value) Alert.success(t('learning:form_academy_notification_has_been_added'));
    else Alert.success(t('learning:form_academy_notification_has_been_removed'));
    setLoading(false);
  };
  return (
    <Overview>
      <OverviewContent>
        <Row>
          <Group>
            <Label text={t('learning:course_preview_filters_label_visibility')} />
            <CourseAudience course={course} form={`academy-course/${course.id}/audience`}>
              <div>
                <AudienceBadge
                  predicates={predicates}
                  type={predicate_type}
                  filters={course.filters}
                />
                <Icon className="CourseSettings__Edit" type="edit" />
              </div>
            </CourseAudience>
          </Group>
        </Row>
        {course?.type === ECourseTypes.ACADEMY && (
          <Row>
            <Group>
              <Label text={t('learning:course_preview_filters_label_deadline')} />
              <CourseDeadline course={course} form={`academy-course/${course.id}/deadline`}>
                <div>
                  {getCourseDeadline(course, t) || t('learning:use_row_no_deadline')}
                  <Icon className="CourseSettings__Edit" type="edit" />
                </div>
              </CourseDeadline>
            </Group>
          </Row>
        )}
        {course?.type === ECourseTypes.ONBOARDING && (
          <Row>
            <Group>
              <Label text={t('learning:course_preview_filters_label_phasing')} />
              <CoursePhasing course={course} form={`onboarding-course/${course.id}/phasing`}>
                <div>
                  {getCoursePhasing(course, t) || t('learning:no_phasing')}
                  <Icon className="CourseSettings__Edit" type="edit" />
                </div>
              </CoursePhasing>
            </Group>
          </Row>
        )}
        <Row>
          <Group>
            <Label text={t('learning:course_preview_filters_label_notifications')} />
            <Toggle
              disabled={loading}
              value={!!course.settings.notification_type}
              onChange={onSaveNotification}
            />
          </Group>
        </Row>
        <Row>
          <Group>
            <Label text={t('learning:course_label_created_at')} />
            {course.created_at && (
              <Badge text={moment(course.created_at).format('MMM D, YYYY HH:mm')} />
            )}
          </Group>
        </Row>
      </OverviewContent>
    </Overview>
  );
};

export default CourseSettings;
