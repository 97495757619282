import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Route, useHistory } from 'react-router';

import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import { TopNavigationBar } from '@common/components/navigation-bar';
import { Container } from '@common/components/container';
import Spinner from '@common/components/spinner';
import { Button } from '@common/components/button';
import { FormIcon } from '../form-icon';
import ModeratorFormSubmissions from './moderator-form-submissions';
import ModeratorFormContent from './moderator-form-content';
import ModeratorFormSettings from './moderator-form-settings';
import { FormForm } from '@modules/forms/forms/form';

import { AlertService } from '@common/services/alert';
import { Api } from '@common/services/api';

import { createDetailedForm } from '@modules/forms/selectors';
import { formatFormWithScreens, FormPayload } from '@modules/forms/actions';
import { addScreensToStore } from '@modules/learning/reducers/screens';
import { addScreenComponentsToStore } from '@modules/learning/reducers/components';
import * as organisationSelector from '../../../organisation/selectors/organisation';

import { FormDetail } from '@modules/forms/types';
import { screenToPayload } from '@modules/survey/utils';
import { useAppSelector, useFormValues, usePermission } from '@common/hooks';
import { EPermissions } from '@common/definitions';
import { predicatesToPayload } from '@utils/predicates';
import { createInitialValues } from '@modules/forms/containers/edit-form/utils';
import { FormStatusInput } from '@modules/forms/components/form-status-input';

import { omit } from 'lodash';
import '@modules/forms/components/submissions/submission.scss';

type FormData = FormPayload & {
  activate_status: boolean;
};

export function ModeratorForm({ initialize, values, ...props }: any) {
  const { match } = props;
  const { params } = match;
  const { networkId } = params;
  const { t } = useTranslation();
  const history = useHistory();

  const formValues = useFormValues(props.form) as FormData;

  const [form, setForm] = useState<FormDetail | null | undefined>(null);
  const [meta, setMeta] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);

  const data = useAppSelector((state) => {
    return {
      organisation: organisationSelector.selected(state),
      functions: organisationSelector.functions(state),
      languageLocales: state.language.available,
      userId: state.loggedUser.user.id,
    };
  });

  const organisationId = data?.organisation?.id;
  const userId = data?.userId;
  const formId = params?.id;

  const fetchForm = useCallback(async () => {
    const res = await Api.get(`/v1/organisations/${organisationId}/forms/${formId}`);
    setForm(res.data as FormDetail);
    setMeta(res.meta);
    initialize(createInitialValues(res.data as FormPayload));
    setFormLoading(false);
  }, [organisationId, formId, initialize]);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  // the Submissions component expects a heavily manipulated object as
  // selectedForm, so it's best to exploit the already existing logic for redux
  // selectors and actions via a "fake" redux state
  // note that the manipulation happens in actions too, before the payload is
  // sent to the redux store, so we need to mimic everything in this useMemo
  const reduxState = useSelector((state: any) => state);
  const selectedForm = useMemo(() => {
    if (!form) {
      return null;
    }
    const item = formatFormWithScreens(form);
    const fakeReduxState = {
      ...reduxState,
      academy: {
        ...reduxState.academy,
        screens: {
          ...reduxState.academy.screens,
          items: addScreensToStore({}, item.screens)
        },
        components: {
          ...reduxState.components,
          items: addScreenComponentsToStore({}, item.screens)
        }
      }
    };
    return createDetailedForm(item, fakeReduxState);
  }, [form, reduxState]);

  const updateForm = useCallback(async () => {
    const payload = omit(formValues, ['response_statuses', 'activate_status', 'audience', 'status']) as FormPayload;

    if (formValues.audience) {
      payload.audience = {
        predicate_type: formValues.audience.predicate_type,
        // @ts-expect-error
        predicates: predicatesToPayload(formValues.audience.predicates),
      };
    }
    if (selectedForm) {
      if (selectedForm.status === 'live') payload.settings = omit(formValues.settings, ['is_anonymous']);
      payload.status = formValues.status;
    }

    const res = await Api.put(`/v1/organisations/${organisationId}/forms/${formId}`, payload);
    setForm(res.data as FormDetail);
  }, [selectedForm, formValues, organisationId, formId]);

  const formTitle = selectedForm?.title;
  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      const calls = [updateForm()];
      calls.push(...selectedForm!.screens.map(async (s) => {
        await Api.put(
          `/v1/organisations/${organisationId}/forms/${formId}/screens/${s.id}`,
          screenToPayload(s, selectedForm!),
        );
      }));
      await Promise.all(calls);
      AlertService.success(t('forms:updated_form', { title: formTitle }));
    } catch (err) {
      AlertService.forStatus((err as any).status_code, {
        warning: t('forms:update_form_warning'),
        error: t('forms:update_form_error'),
      });
    }
    setIsSubmitting(false);
  }, [organisationId, selectedForm, formTitle]);

  const onModeratorsSave = useCallback(() => {
    fetchForm();
  }, [fetchForm]);

  const breadCrumbs = useMemo(() => {
    const base: any[] = [
      {
        name: `${t('forms:breadcrumb_forms')} - ${t('forms:managed_by_me')}`,
        path: `/networks/${networkId}/forms/managed-by-me`
      }
    ];
    if (formTitle) {
      base.push({ name: formTitle });
    }
    return base;
  }, [networkId, formTitle, t]);

  const canUpdateOwnForms = usePermission(EPermissions.ORGANISATION_CREATOR_FORMS_UPDATE);
  const canUpdateAllForms = usePermission(EPermissions.ORGANISATION_FORMS_UPDATE);
  const moderator = form?.settings?.moderators.find(({ user_id }) => user_id === userId);

  // TODO too complex, see comment on canUpdateFormContent here
  // source\shared\modules\forms\containers\edit-form\moderators.tsx
  const canEditContent = !!moderator?.can_edit_content || canUpdateAllForms || (
    canUpdateOwnForms &&
    typeof form?.created_by === 'string' &&
    moderator?.user_id === form.created_by
  );

  const tabs = useMemo(() => {
    return [
      ...(canEditContent ?
        [
          {
            name: t('forms:tab_content'),
            to: `/networks/${networkId}/forms/${formId}/edit`,
          },
          {
            name: t('forms:tab_settings'),
            to: `/networks/${networkId}/forms/${formId}/settings`,
          }
        ] :
        []),
      selectedForm?.status === 'live' ?
        {
          name: t('forms:tab_submissions'),
          to: `/networks/${networkId}/forms/${formId}/submissions`,
          count: selectedForm?.responded_count,
        } :
        undefined,
    ].filter((tab) => !!tab);
  }, [
    selectedForm?.status, selectedForm?.responded_count, networkId, formId, t, canEditContent
  ]);

  return (
    <Container name="EditForm">
      {!params.id && canEditContent && (
        <FormForm
          change={props.change}
          submitting={isSubmitting}
          values={formValues as FormData}
          onSubmit={onSubmit}
          onClose={() => history.push(`/networks/${networkId}/forms/managed-by-me`)}
        />
      )}
      <TopNavigationBar
        breadcrumbs={breadCrumbs}
        form={canEditContent ? FormForm : undefined}
        formProps={{
          onSubmit,
          change: props.change,
          values: formValues,
        }}
        title={
          (
            formValues && [
              <FormIcon form={formValues} />,
              formValues.title
            ]
          ) ||
          <span className="Survey__Title">
            <Spinner size="large" />
          </span>
        }
        action={(
          <Route
            exact
            path={[
              '/networks/:id/forms/:formId/edit',
              '/networks/:id/forms/:formId/edit/:screenId',
              '/networks/:id/forms/:formId/settings',
            ]}
          >
            {selectedForm && canEditContent && (
              <FormStatusInput />
            )}
            <Button
              size="large"
              onClick={() => history.push(`/networks/${networkId}/forms/managed-by-me`)}
            >
              {t('forms:go_back')}
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={onSubmit}
              disabled={!selectedForm}
              isLoading={isSubmitting}
            >
              {t('common:save')}
            </Button>

          </Route>
        )}
        tabs={tabs}
      />
      <Switch>
        {
          selectedForm && canEditContent &&
          <Route
            exact
            path={['/networks/:id/forms/:formId/edit', '/networks/:id/forms/:formId/edit/:screenId']}
          >
            <ModeratorFormContent
              {...props}
              organisationId={organisationId}
              setForm={setForm}
              selectedForm={selectedForm}
            />
          </Route>
        }

        {
          selectedForm && canEditContent &&
          <Route
            exact
            path="/networks/:id/forms/:formId/settings"
            render={() => (
              <ModeratorFormSettings
                {...props}
                selectedForm={selectedForm}
                setForm={setForm}
                meta={meta}
                loading={formLoading}
                onModeratorsSave={onModeratorsSave}
              />
            )}
          />
        }

        <Route
          path={['/networks/:id/forms/:formId/submissions', '/networks/:id/forms/:formId/submissions/:submissionId']}
        >
          <ModeratorFormSubmissions
            {...props}
            data={data}
            setForm={setForm}
            selectedForm={selectedForm}
          />
        </Route>
      </Switch>
    </Container>
  );
}

export default reduxForm<FormData>({
  form: 'moderator-edit-form',
  enableReinitialize: true,
  initialValues: createInitialValues(),
})(ModeratorForm);
