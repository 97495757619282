import React, { Dispatch, SetStateAction, memo, useCallback } from 'react';

import { combineClassNames } from '@common/utils/combineClassNames';
import { Checkbox } from '@common/components/form/inputs/checkbox';

import './selectable-course-card.scss';

type SelectableCourseCardProps = {
  backgroundImage: string;
  name: string;
  description: string;
  selected: boolean;
  setSelectedCourses: Dispatch<SetStateAction<string[]>>;
  id: string;
};

const SelectableCourseCard = memo(({
  backgroundImage,
  name,
  description,
  selected,
  setSelectedCourses,
  id
}: SelectableCourseCardProps) => {

  const onChange = useCallback(() => {
    setSelectedCourses((selectedIds: string[]) => {
      if (selectedIds.indexOf(id) > -1) {
        return selectedIds.filter((selectedId) => selectedId !== id);
      }
      return [...selectedIds, id];
    });
  }, [setSelectedCourses, id]);

  const className = combineClassNames('SelectableCourseCard', { selected });

  return (
    <div className={className} onClick={onChange}>
      <div
        className="preview"
        style={{ backgroundImage: `url("${backgroundImage}")` }}
      >
        <Checkbox
          size="large"
          value={selected}
          onChange={onChange}
        />
      </div>
      <div className="textContent">
        <h3>{ name }</h3>
        <p>{ description }</p>
      </div>
    </div>
  );
});

export default SelectableCourseCard;
