import { useEffect, useState } from 'react';

import { EPredicateFilters, PredicateFilterItem } from '@common/components/predicates-filter';
import { Team, Network } from '@common/types/objects';
import { EPredicateFields, EPredicateOperators } from '@common/definitions';

export const getNetworkPredicate = (networks?: Network[], predicate?: PredicateFilterItem) => {
  return {
    filter: EPredicateFilters[EPredicateFields.NETWORK],
    value: {
      attribute: EPredicateFields.NETWORK,
      comparison: EPredicateOperators.IN,
      value: predicate?.value?.value || [],
    },
    options: networks || [],
  };
};

export const getFunctionPredicate = (functions?: Team[], predicate?: PredicateFilterItem) => {
  return {
    filter: EPredicateFilters[EPredicateFields.FUNCTION],
    value: {
      attribute: EPredicateFields.FUNCTION,
      comparison: EPredicateOperators.IN,
      value: predicate?.value?.value || [],
    },
    options: functions || []
  };
};

export const usePredicateState = ({
  networks,
  functions
}: {
  networks?: Network[];
  functions?: Team[];
}): [PredicateFilterItem[], any] => {
  const [predicates, setPredicates] = useState(() => [
    ...(networks ? [getNetworkPredicate(networks)] : []),
    ...(functions ? [getFunctionPredicate(functions)] : []),
  ]);

  useEffect(() => {
    const networkPredicate = networks ?
      [
        getNetworkPredicate(
          networks,
          predicates.find((p) => p.filter === EPredicateFilters[EPredicateFields.NETWORK])
        )
      ] : [];
    const functionPredicate = functions ?
      [
        getFunctionPredicate(
          functions,
          predicates.find((p) => p.filter === EPredicateFilters[EPredicateFields.FUNCTION])
        )
      ] : [];
    setPredicates((prev) => {
      const newPredicates = [
        ...(networks ? networkPredicate : []),
        ...(functions ? functionPredicate : []),
      ];
      return JSON.stringify(prev) === JSON.stringify(newPredicates) ? prev : newPredicates;
    });
  }, [networks, functions]);

  return [predicates, setPredicates];
};
