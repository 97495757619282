import React from 'react';
import { Control, FieldValues, UseControllerProps, useController } from 'react-hook-form';

import { EmojiPicker, EmojiPickerProps } from './emoji';

/**
 * By default the form validation mode is set to `mode: 'onSubmit'`.
 * This means the validation will be shown after submitting the form.
 * You probably want to set this option to `all` to have immediate feedback when a field does not pass the added rules.
 * useForm({ mode: 'all' });
 */
export function EmojiInputHookForm<T extends FieldValues>(
  props: EmojiPickerProps & UseControllerProps<T> & { label?: string, control: Control<T> },
) {
  const { field, fieldState } = useController(props);
  return (
    <EmojiPicker
      {...props}
      {...field}
      onSelect={field.onChange}
      error={fieldState.error?.message || fieldState.error?.message === ''}
    />
  );
}
