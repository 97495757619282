import React from 'react';
import { Switch, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { EComponentTypes, EPermissions } from '@common/definitions';
import { Organisation } from '@common/types/objects';
import { StoreState } from '@common/types/store';
import Route, { PermissionRoute } from '@common/components/route';
import RouteNotFound from '@common/components/route-not-found';
import LearningSideNavigation from './components/side-navigation';
import OnboardingContainer from './containers/onboarding';
import AcademyContainer from './containers/academy';
import CourseContainer from './containers/course';
import PublishOnboardingContainer from './containers/publish/onboarding';
import PublishAcademyContainer from './containers/publish/academy';
import ModuleContainer from './containers/module';
import ScreenContainer from './containers/screen';
import LearningPathDetail from './components/learning-paths/learning-path-detail';
import * as userSelector from '../core/selectors/logged-user';
import * as organisationSelector from '../organisation/selectors/organisation';

import './styles.scss';

const LearningTemplate = ({ organisation }: { organisation: Organisation }) => {
  const rootUrl = organisation.enabled_components.includes(EComponentTypes.ONBOARDING) ?
    '/admin/learning/onboarding' :
    '/admin/learning/academy';

  return (
    <>
      {
        organisation.enabled_components.includes(EComponentTypes.ACADEMY) &&
        organisation.enabled_components.includes(EComponentTypes.ONBOARDING) && (
          <Route
            exact
            path={[
              '/admin/learning/onboarding',
              '/admin/learning/onboarding/filter/:status',
              '/admin/learning/onboarding/statistics',
              '/admin/learning/academy',
              '/admin/learning/academy/filter/:status',
              '/admin/learning/academy/paths/list'
            ]}
            component={LearningSideNavigation}
          />
        )
      }
      <Switch>
        <Route
          path="/admin/learning/courses/:courseId/publish/onboarding"
          component={PublishOnboardingContainer}
        />
        <Route
          path="/admin/learning/courses/:courseId/publish/academy"
          component={PublishAcademyContainer}
        />
        <Route
          path="/admin/learning/courses/:courseId/modules/:moduleId/screens/:screenId"
          component={ScreenContainer}
        />
        <Route
          path="/admin/learning/courses/:courseId/modules/:moduleId"
          component={ModuleContainer}
        />
        <Route
          path="/admin/learning/courses/:courseId"
          component={CourseContainer}
        />
        <PermissionRoute
          exact
          path={[
            '/admin/learning/onboarding',
            '/admin/learning/onboarding/filter/:status',
            '/admin/learning/onboarding/statistics',
          ]}
          component={OnboardingContainer}
          permission={{ name: EPermissions.ORGANISATION_ONBOARDING_COURSES_VIEW }}
        />
        { /* the LearningPathDetail route needs to precede the AcademyContainer one
        like it's specified here */}
        <PermissionRoute
          path="/admin/learning/academy/paths/list/:pathId"
          component={LearningPathDetail}
          permission={{ name: EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW }}
        />
        <Route
          exact
          path="/admin/learning/:type/:courseId"
          component={CourseContainer}
        />
        <PermissionRoute
          path="/admin/learning/academy"
          component={AcademyContainer}
          permission={{ name: EPermissions.ORGANISATION_ACADEMY_COURSES_VIEW }}
        />
        <Redirect exact from="/admin/learning" to={rootUrl} />
        <Route component={RouteNotFound} />
      </Switch>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  organisation: organisationSelector.selected(state),
  permissions: userSelector.permissions(state),
});

export default connect(mapStateToProps)(LearningTemplate);
