import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import { useAppSelector } from '@hooks/index';
import ImageItem from '@common/components/image-item/large';
import ExpirationWall from '@common/components/expiration-wall';
import AsyncList from '@common/components/list/async';
import SearchBar from '@common/components/search-bar';
import Spinner from '@common/components/spinner';
import Alert from '@common/components/alert';
import { AlertService } from '@common/services/alert';
import ErrorComponent from '../../components/error';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';
import { NetworksSelectError } from '../../../organisation/actions';

const ItemComponent = React.memo(({ item, setExpiredOrganisation, selectOrganisation }) => (
  <ImageItem
    imageSize={55}
    infoIcon="public"
    image={item.brand_icon}
    name={item.name}
    onClick={() => {
      if (item.trial && new Date(item.trial.expires_at) < new Date()) {
        return setExpiredOrganisation(item);
      }

      return selectOrganisation(item.id);
    }}
  />
));

const NetworksContainer = ({
  fetchOrganisations,
  selectNetwork,
  selectOrganisation,
  logout,
  history,
}) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [expiredOrganisation, setExpiredOrganisation] = useState(null);
  const organisations = useAppSelector((state) => state.loggedUser.organisations);
  const { t } = useTranslation();

  const selectWrapper = (fn) => async (id) => {
    try {
      setLoading(true);
      const path = await fn(id);
      if (path) history.push(path);
    } catch (error) {
      setLoading(false);
      if (error instanceof NetworksSelectError) {
        AlertService.error(t('authentication:networks_select_error'));
      } else {
        AlertService.error(t('common:something_went_wrong'));
        throw error;
      }
    }
  };

  const handleLogout = () => {
    logout();

    history.push('/auth/login');
  };

  if (loading) {
    return (
      <div id="Organisations" className="balloon">
        <Spinner size="large" centered />
      </div>
    );
  }

  return (
    <div id="Organisations" className="balloon">
      {expiredOrganisation && (
        <ExpirationWall organisation={expiredOrganisation} onSwitch={() => setExpiredOrganisation(null)} />
      )}
      <h2><Trans i18nKey="authentication:organisations_select_header" /></h2>
      <SearchBar onSearch={(value) => setSearch(value)} debounce={false} filter={false} />
      <AsyncList
        data={{
          onFetch: fetchOrganisations,
          filterFn: (organisation) => (search ? organisation.name.toLowerCase().indexOf(search.toLowerCase()) > -1 : true),
        }}
        disableInitialFetch={organisations.length > 0}
        items={organisations}
        renderRow={ItemComponent}
        rowProps={{
          setExpiredOrganisation,
          selectOrganisation: selectWrapper(selectOrganisation),
          selectNetwork: selectWrapper(selectNetwork),
        }}
        ErrorComponent={ErrorComponent}
        placeholder={search ? (
          <Trans i18nKey="authentication:no_networks_found" values={{ search }} />
        ) : (
          <Alert type="warning">
            <Trans
              i18nKey="authentication:no_organisations_available"
              components={[<a onClick={handleLogout} role="button" />]}
            />
          </Alert>
        )}
      />
    </div>
  );
};

const mapDispatchToProps = {
  fetchOrganisations: require('../../actions').fetchOrganisations,
  selectNetwork: require('../../../network/actions/select-network').default,
  selectOrganisation: require('../../../organisation/actions').selectOrganisation,
  logout: require('../../actions/logout').default,
};

export default connect(undefined, mapDispatchToProps)(pageWrapper(EEventNames.VISITED_SELECT_ORGANISATION_PAGE)(NetworksContainer));
