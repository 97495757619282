/*

/!\ DEPRECATED! /!\
use ModalWindow + ModalContent instead (this same folder)

*/

import React, { ReactNode } from 'react';
import BootstrapModal from 'react-bootstrap/Modal';
import * as R from 'ramda';
import { Trans } from 'react-i18next';

import { Icon } from '@common/components/icon';
import { Button } from '../button';
import TabHeader from './tab-header';
import FormWrapper from './form-wrapper';
import { combineClassNames } from '../../utils/combineClassNames';

const ModalTitle = ({ children }: { children: any }) => <h2 className="Modal__title">{children}</h2>;
const ModalBodyFullWidth = ({ children }: { children: any }) => <div className="modal-body--full-width">{children}</div>;

// These properties should not be passed to the root div element of the modal because they are not
// valid HTML properties
const blacklist = [
  'footer',
  'showCancel',
  'onClose',
  'content',
  'title',
  'header',
  'wrapperProps',
  'disableRootClose',
  'fullWidthContent',
];

type Props = {
  wrapper?: React.ReactNode;
  showCancel?: boolean;
  children?: ReactNode;
  wrapperProps?: Record<string, unknown>;
  title?: ReactNode;
  header?: ReactNode | ((handleHide: () => void) => (JSX.Element | void));
  content?: ((props: Record<string, unknown>) => JSX.Element) | ReactNode;
  footer?: ReactNode | ((handleHide: () => void) => (JSX.Element | void));
  footerNote?: ReactNode;
  className?: string;
  size?: 'fill' | 'large' | 'small';
  list?: boolean;
  disableRootClose?: boolean;
  hideDefaultClose?: boolean;
  borderless?: true;
  show?: boolean;
  fullWidthContent?: boolean;
  enforceFocus?: boolean;
  onEnter?: () => void;
  onClose?: () => void;
  onBack?: () => void;
  onExit?: () => void;
  onExited?: () => void;
  onShow?: () => void;
};

const ModalComponent = ({
  children,
  wrapper: Wrapper,
  show,
  footer,
  footerNote,
  title,
  header,
  content: Content,
  className,
  size,
  list,
  borderless,
  onBack,
  onClose,
  disableRootClose,
  hideDefaultClose,
  fullWidthContent,
  ...props
}: Props) => {
  const [isVisible, setVisible] = React.useState<boolean>(typeof show !== 'undefined' ? !!show : !children);

  React.useEffect(() => {
    if (typeof show !== 'undefined' && show !== isVisible) setVisible(show);
  }, [show]);

  const triggerElement = children && React.Children.only(children) as JSX.Element;
  const trigger = triggerElement && React.cloneElement(triggerElement, {
    onClick: triggerElement.props.onClick || (() => setVisible(true)),
  });

  const modalClassNames = combineClassNames(className, {
    'modal-list': !!list,
    'modal--borderless': !!borderless,
  });

  const handleHide = (override?: boolean): void => {
    if (!disableRootClose || override) {
      setVisible(false);
    }

    if (onClose) onClose();
  };

  const handleClose = (callback?: () => void): void => {
    handleHide(true);

    if (typeof callback === 'function') setTimeout(callback, 250);
  };

  const body = typeof Content === 'function' ? <Content /> : Content;
  const BodyWrapper = fullWidthContent ? ModalBodyFullWidth : BootstrapModal.Body;

  return (
    <>
      {trigger}
      <BootstrapModal
        className={modalClassNames}
        size={size as 'sm' | 'lg' | 'xl'}
        onHide={handleHide}
        {...R.omit(blacklist, props)}
        show={isVisible}
      >
        {/* @ts-expect-error */}
        <Wrapper {...props.wrapperProps}>
          {(title || header) && (
            <BootstrapModal.Header>
              {title && (
                <div className="Modal__MainHeader">
                  {title && <h2 className="Modal__title">{title}</h2>}
                  {/* @ts-expect-error */}
                  <Icon className="Modal__HeaderClose" type="close" onClick={handleHide} />
                </div>
              )}
              {typeof header === 'function' ? header(handleHide) : header}
            </BootstrapModal.Header>
          )}
          <BodyWrapper>{body}</BodyWrapper>
          {footer && (
            <BootstrapModal.Footer>
              {footerNote}
              <div className="pull-right">
                {!hideDefaultClose && (
                  onBack
                    ? <Button onClick={onBack}><Trans i18nKey="common:modal_footer_button_back" /></Button>
                    // @ts-expect-error
                    : <Button onClick={handleClose}><Trans i18nKey="common:modal_footer_button_cancel" /></Button>
                )}
                {typeof footer === 'function' ? footer(handleHide) : footer}
              </div>
            </BootstrapModal.Footer>
          )}
        </Wrapper>
      </BootstrapModal>
    </>
  );
};

ModalComponent.defaultProps = {
  showCancel: true,
  wrapper: ({ children }: { children: React.ReactNode }) => <div className="Modal__Wrapper">{children}</div>,
};

ModalComponent.FormWrapper = FormWrapper;
ModalComponent.TabHeader = TabHeader;
ModalComponent.Title = ModalTitle;
ModalComponent.BodyFullWidth = ModalBodyFullWidth;

/**
 * @deprecated The method should not be used
 */
export const Modal = ModalComponent;
