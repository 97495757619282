import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import * as R from 'ramda';
import { Trans, withTranslation } from 'react-i18next';
import { SelectInput } from '@common/components/form/inputs/select';
import * as Alert from '../../../../common/services/alert';
import { Button } from '../../../../common/components/button';
import Confirm from '../../../../common/components/confirm-button';
import ModuleStatus from '../../components/status';
import saveDraft from '../../actions/save-draft';
import * as validation from '../../utils/validation';
import * as draftSelector from '../../selectors/draft';
import { EModuleTypes, EComponentTypes } from '../../definitions';

class ScreenForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      removing: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = () => {
      this.setState({ removing: true });

      props.onDelete();
    };
  }

  componentDidMount() {
    const { screen } = this.props;

    if (screen && screen.id) this.initialize(screen);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!this.props.screen && nextProps.screen.id) this.initialize(nextProps.screen);
  }

  static props;

  initialize(screen) {
    const question = R.find(R.propEq('type', EComponentTypes.ELEARNING_QUESTION), screen.components);

    return this.props.initialize({
      published: !!screen.published,
      points: (question && question.parameters.points) || 1,
    });
  }

  async handleSubmit(values, dispatch) {
    const { module: moduleItem, screen, t, question } = this.props;

    if (!screen || !moduleItem) return;

    try {
      if (values.published) {
        await validation.screenValidator(
          { ...screen, ...values },
          moduleItem.type === EModuleTypes.QUIZ && !!question,
          // we could read question from screen, but it's not reliable
          question
        );
      }

      await dispatch(saveDraft(screen.id, moduleItem.id, values));

      Alert.success(moduleItem.type === EModuleTypes.QUIZ
        ? t('learning:form_screen_quiz_saved')
        : t('learning:form_screen_screen_saved'));
    } catch (err) {
      console.log('debug err', err);
      if (err.text) return Alert.warning(t(err.text));

      Alert.forStatus(err.status_code, {
        warning: moduleItem.type === EModuleTypes.QUIZ
          ? t('learning:form_screen_warning_saving_quiz')
          : t('learning:form_screen_warning_saving_screen'),
        error: moduleItem.type === EModuleTypes.QUIZ
          ? t('learning:form_screen_error_saving_quiz')
          : t('learning:form_screen_error_saving_screen'),
      });
    }
  }

  renderPublishedValue({ label, value }) {
    return (
      <>
        <ModuleStatus small published={value} />
        {label}
      </>
    );
  }

  render() {
    const { removing } = this.state;
    const {
      module: moduleItem,
      screen,
      question,
      submitting,
      handleSubmit,
      t,
    } = this.props;

    const questionOptions = question?.parameters?.options || [];
    const validOptions = questionOptions.filter((option) => option.text.length > 0);

    const valid = !question || (!!question.parameters && (
      question.parameters.text && question.parameters.text.length >= 10
      && validOptions.length >= 2
      && validOptions.filter((option) => option.correct).length > 0
    ));

    const createButton = (shouldSubmit = true) => (
      <Button
        size="large"
        type="primary"
        iconRight="check"
        buttonType={shouldSubmit ? 'submit' : 'button'}
        disabled={!valid}
        isLoading={submitting}
      >
        <Trans i18nKey="learning:form_screen_save" />
      </Button>
    );

    return (
      <form
        className="Form Align"
        onSubmit={handleSubmit(this.handleSubmit)}
        autoComplete="false"
      >
        <div className="pull-right">
          {moduleItem && moduleItem.type === EModuleTypes.QUIZ && (
            <SelectInput
              name="points"
              clearable={false}
              options={[
                { label: t('learning:form_screen_points_label', { count: 1 }), value: 1 },
                { label: t('learning:form_screen_points_label', { count: 2 }), value: 2 },
                { label: t('learning:form_screen_points_label', { count: 4 }), value: 4 },
                { label: t('learning:form_screen_points_label', { count: 6 }), value: 6 },
                { label: t('learning:form_screen_points_label', { count: 8 }), value: 8 },
                { label: t('learning:form_screen_points_label', { count: 10 }), value: 10 },
              ]}
              searchable={false}
            />
          )}
          <SelectInput
            name="published"
            clearable={false}
            options={[
              { label: t('common:status_live'), value: true },
              { label: t('common:status_draft'), value: false },
            ]}
            valueRenderer={this.renderPublishedValue}
            searchable={false}
          />
          <Confirm
            title={question
              ? t('learning:form_screen_confirm_remove_question')
              : t('learning:form_screen_confirm_remove_screen')}
            onConfirm={this.handleDelete}
          >
            <Button size="large" icon="delete" isLoading={removing} />
          </Confirm>
          <Button size="large" onClick={this.props.onBack}>
            <Trans i18nKey="learning:form_screen_back" />
          </Button>
          {screen && screen.published ? (
            <Confirm
              title={t('learning:form_screen_confirm_publish_title')}
              description={t('learning:form_screen_confirm_publish_description')}
              onConfirm={handleSubmit(this.handleSubmit)}
            >
              {createButton(false)}
            </Confirm>
          ) : createButton()}
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state, props) => ({
  question: props.screen?.question && draftSelector.question(state, props.screen.question),
});

export default withTranslation()(reduxForm({
  form: 'screen',
  initialValues: {
    published: false,
    points: 1,
  },
})(connect(mapStateToProps)(ScreenForm)));
